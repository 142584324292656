import type {Entity} from '@shared/EntityType';

export enum ActorType {
  Unknown = 'Unknown',
  Person = 'Person',
  App = 'App',
  Momentum = 'Momentum',
  External = 'External',
}

export interface Actor extends Entity {
  id: number;
  accountId: number;
  actorType: ActorType;
  name: string;
  email: string;
  avatarId: number;
  userId: number;
  timezone: string;
  active: boolean;
  deleted: boolean;
}
