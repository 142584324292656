import {debounce} from '@shared/lib/debounce';
import {useLayoutEffect, useMemo, useState} from 'react';

export function useMeasureRef<T extends HTMLElement>(ref: React.RefObject<T>): DOMRectReadOnly | null {
  const [bounds, set] = useState<DOMRectReadOnly | null>(null);

  const resizeHandler = useMemo(
    () =>
      debounce((entries: ResizeObserverEntry[]) => {
        if (entries.length > 0) {
          set(entries[0].contentRect);
        }
      }, 0),
    [],
  );
  const observer = useMemo(() => new ResizeObserver(resizeHandler), [resizeHandler]);

  useLayoutEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
        resizeHandler.cancel();
      };
    }
  }, [ref.current]);

  return bounds ?? ref.current?.getBoundingClientRect() ?? null;
}
