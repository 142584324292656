import React from 'react';

interface Props {
  taskList: React.ReactNode;
}

export const BacklogTaskGroup: React.FC<Props> = ({taskList}) => {
  return (
    <div className="group/sprint mt-2 origin-top-left pb-10">
      <div className="flex flex-row gap-2 px-5">
        <h3 className="font-bold text-gray-500">Backlog</h3>
        <div className="flex flex-grow items-baseline justify-end"></div>
      </div>
      {taskList}
    </div>
  );
};
