import {Button} from '@/design-system';
import {authStore} from '@/stores/auth';
import {TaskHistoryContext} from '@/stores/ui/taskHistoryUiStore';
import {ArrowDownIcon, XIcon} from 'lucide-react';
import {useContext, useEffect, useState} from 'react';
import {tv} from 'tailwind-variants';

const unreadStyles = tv({
  slots: {
    root: 'sticky bottom-2 left-0 right-0 flex h-0 justify-center text-center transition-opacity',
    bubble: 'relative -top-8 flex h-6 items-center gap-1.5 rounded-full bg-pink-500 text-white shadow-md',
  },
  variants: {
    visible: {
      false: {
        root: 'pointer-events-none opacity-0',
        bubble: 'animate-out zoom-out-50 fill-mode-forwards',
      },
      true: {
        root: 'opacity-100 delay-75',
        bubble: 'delay-75 animate-in zoom-in-50',
      },
    },
  },
});

export function UnreadCounter() {
  const store = useContext(TaskHistoryContext);
  const {history, readTo, manualReadTo, isLoaded} = store.use((s) =>
    s.getMany('history', 'readTo', 'manualReadTo', 'isLoaded'),
  );

  const r = manualReadTo ?? readTo;
  const unreadCount = r ? history.filter((h) => h.createdAt > r && h.authorId !== authStore.actorId).length : 0;

  const visible = isLoaded && unreadCount > 0;
  const {root, bubble} = unreadStyles({visible});

  const [count, setCount] = useState(unreadCount);
  useEffect(() => {
    unreadCount > 0 && setCount(unreadCount);
  }, [unreadCount]);

  return (
    <div className={root()}>
      <div className={bubble()}>
        <Button
          size="md"
          seamless
          className="mr-[-6px] gap-1 rounded-l-full px-2 py-1 text-xs focus-visible:ring-offset-1"
          onPress={() => store.scrollToFirstUnread()}
        >
          <ArrowDownIcon className="h-3 w-3" />
          {count} Unread updates
        </Button>
        <span className="h-3 border-l border-white/60" />
        <Button
          size="md"
          seamless
          className="ml-[-6px] h-full rounded-r-full px-2 pl-1.5 focus-visible:ring-offset-1"
          onPress={() => store.setReadAll()}
          excludeFromTabOrder={!visible}
        >
          <XIcon className="h-3 w-3" />
        </Button>
      </div>
    </div>
  );
}
