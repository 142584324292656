import DetailsIcon from '@/assets/icon-details.svg?react';
import {CreateCommentView} from '@/components/comment/CreateCommentView';
import {TaskDescriptionEditor} from '@/components/task/TaskDescriptionEditor';
import {TaskDetailsView} from '@/components/task/TaskDetailsView';
import {TaskHeader} from '@/components/task/TaskHeader';
import {TaskHistoryView} from '@/components/task/TaskHistoryView';
import {UnreadCounter} from '@/components/task/UnreadCounter';
import {ScrollView} from '@/design-system/ScrollView';
import {ScrollSection} from '@/design-system/section-scroller/ScrollSection';
import {SectionScroller} from '@/design-system/section-scroller/SectionScroller';
import {taskStore} from '@/stores/task';
import {TaskHistoryContext, TaskHistoryUiStore} from '@/stores/ui/taskHistoryUiStore';
import {FileTextIcon, MessageCircleIcon} from 'lucide-react';
import React, {useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';

interface Props {
  taskId: number;
  className?: string;
  scrollToUnread?: boolean;
}

export const TaskView: React.FC<Props> = ({taskId, className, scrollToUnread}) => {
  const [store, setStore] = useState<TaskHistoryUiStore | null>(null);
  taskStore.require((s) => s.getById(taskId));

  useEffect(() => {
    const newStore = new TaskHistoryUiStore(taskId);
    setStore(newStore);

    function onLoadChange(isLoaded: boolean) {
      if (isLoaded && scrollToUnread) {
        newStore.scrollToFirstUnread(false);
      }
    }
    newStore.selectAndSubscribe((s) => s.state.isLoaded ?? false, onLoadChange);

    return () => {
      newStore.unsubscribe(onLoadChange);
      newStore.destroy();
    };
  }, [taskId]);

  if (!store || store.taskId !== taskId) return null;

  return (
    <TaskHistoryContext.Provider value={store}>
      <div
        className={twMerge('flex h-full flex-col delay-75 duration-100 animate-in fade-in fill-mode-both', className)}
      >
        <div className="relative flex flex-grow flex-col">
          <TaskHeader id={taskId} className="bg-white" expanded />
          <ScrollView autoHide={false} className="h-auto flex-grow">
            <div className="sticky -top-5 z-10 -mx-2 -mt-5 h-5 shadow-md-wide transition-shadow duration-300" />
            <TaskContent taskId={taskId} />
          </ScrollView>
          <UnreadCounter />
        </div>
        <CreateCommentView taskId={taskId} className="border-t border-gray-800/10 shadow" />
      </div>
    </TaskHistoryContext.Provider>
  );
};

function TaskContent({taskId}: {taskId: number}) {
  return (
    <SectionScroller>
      <div className="flex h-full flex-col gap-5">
        <ScrollSection
          id="description"
          rank={1}
          icon={<FileTextIcon />}
          tooltip="Jump to description"
          className="-mb-2"
        >
          <TaskDescriptionEditor taskId={taskId} />
        </ScrollSection>
        <ScrollSection id="details" rank={2} icon={<DetailsIcon />} tooltip="Jump to details">
          <SectionDivider className="mb-6">Details</SectionDivider>
          <TaskDetailsView id={taskId} />
        </ScrollSection>
        <SectionDivider className="mb-5">History & comments</SectionDivider>
        <div className="-my-5 flex-grow"></div>
        <ScrollSection
          id="history"
          rank={3}
          icon={<MessageCircleIcon />}
          tooltip="Jump to latest comments"
          targetPosition="bottom"
        >
          <TaskHistoryView id={taskId} />
        </ScrollSection>
      </div>
    </SectionScroller>
  );
}

function SectionDivider({className, children}: {className?: string; children: React.ReactNode}) {
  return (
    <>
      <div className={twMerge('flex items-center gap-2', className)}>
        <div className="h-px w-8 bg-gray-800/35" />
        <span className="shrink-0 text-sm font-semibold uppercase text-gray-800/50">{children}</span>
        <div className="h-px flex-grow bg-gray-800/35" />
      </div>
    </>
  );
}
