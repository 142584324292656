import {SuspenseLoader} from '@/design-system/SuspenseLoader';
import {debounce} from '@shared/lib/debounce';
import {useCallback, useEffect, useRef, useState} from 'react';
import type {PopoverProps as AriaPopoverProps} from 'react-aria-components';
import {
  Popover as AriaPopover,
  OverlayArrow,
  PopoverContext,
  composeRenderProps,
  useSlottedContext,
} from 'react-aria-components';
import type {positionValues} from 'react-custom-scrollbars-2';
import Scrollbars from 'react-custom-scrollbars-2';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

export interface PopoverProps extends Omit<AriaPopoverProps, 'children'> {
  showArrow?: boolean;
  noScroll?: boolean;
  children: React.ReactNode | (() => React.ReactNode);
}

const styles = tv({
  base: 'rounded-lg border border-black/20 bg-white bg-clip-padding text-slate-700 shadow-xl transition-none duration-200 forced-colors:bg-[Canvas]',
  variants: {
    isEntering: {
      true: 'ease-out animate-in fade-in placement-left:slide-in-from-right-1 placement-right:slide-in-from-left-1 placement-top:slide-in-from-bottom-1 placement-bottom:slide-in-from-top-1',
    },
    isExiting: {
      true: 'ease-in animate-out fade-out placement-left:slide-out-to-right-1 placement-right:slide-out-to-left-1 placement-top:slide-out-to-bottom-1 placement-bottom:slide-out-to-top-1',
    },
  },
});

const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

export function Popover({children, showArrow, noScroll = true, className, ...props}: PopoverProps) {
  const [height, setHeight] = useState(0);
  const popRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!popRef.current) return;
    setHeight(parseInt(popRef.current!.style.maxHeight ?? '200'));
    const resizeHandler = debounce((entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        setHeight(parseInt((entry.target as HTMLDivElement).style.maxHeight ?? '200'));
      }
    }, 0);
    const observer = new ResizeObserver(resizeHandler);
    observer.observe(popRef.current);
    return () => {
      observer.disconnect();
      resizeHandler.cancel();
    };
  });

  const [scrollHeight, setScrollHeight] = useState(150);
  const onScrollUpdate = useCallback((scroll: positionValues) => {
    setScrollHeight(scroll.scrollHeight);
  }, []);

  let popoverContext = useSlottedContext(PopoverContext)!;
  let isSubmenu = popoverContext?.trigger === 'SubmenuTrigger';
  let offset = showArrow ? 10 : 6;
  offset = isSubmenu ? offset - 6 : offset;
  return (
    <AriaPopover
      ref={popRef}
      offset={offset}
      {...props}
      className={composeRenderProps(className, (className, renderProps) =>
        twMerge(styles({...renderProps}), className),
      )}
      style={{padding: 0}}
      UNSTABLE_portalContainer={document.body}
    >
      {showArrow && (
        <OverlayArrow className="group">
          <svg
            width={12}
            height={12}
            viewBox="0 0 12 12"
            className="block fill-white stroke-black/20 stroke-1 group-placement-left:-rotate-90 group-placement-right:rotate-90 group-placement-bottom:rotate-180 forced-colors:fill-[Canvas] forced-colors:stroke-[ButtonBorder]"
          >
            <path d="M0 0 L6 6 L12 0" />
          </svg>
        </OverlayArrow>
      )}

      <div className="flex flex-col" onMouseDown={stopPropagation}>
        <SuspenseLoader>
          {noScroll ? (
            typeof children === 'function' ? (
              children()
            ) : (
              children
            )
          ) : (
            <Scrollbars
              autoHide
              autoHeight
              autoHeightMax={Math.min(512, height)}
              onUpdate={onScrollUpdate}
              style={{minHeight: Math.min(scrollHeight, 150)}}
            >
              {typeof children === 'function' ? children() : children}
            </Scrollbars>
          )}
        </SuspenseLoader>
      </div>
    </AriaPopover>
  );
}
