export interface UrlValidationOptions {
  fieldName?: string;
  required?: boolean;
}

const DEFAULT_OPTIONS: UrlValidationOptions = {
  required: true,
};

export function validateUrl(url?: string, options?: UrlValidationOptions): string | undefined {
  options = {...DEFAULT_OPTIONS, ...options};

  if (!url) {
    if (options?.required) return `${options?.fieldName ?? 'URL'} is required`;
    return undefined;
  }

  try {
    new URL(url);
  } catch (_e) {
    return 'invalid URL';
  }

  return undefined;
}

validateUrl.withOptions = function (options: UrlValidationOptions) {
  return (url?: string) => validateUrl(url, options);
};
