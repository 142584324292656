import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import {contextStore} from '@/stores/context';
import type {EntityStoreState} from '@/stores/lib/EntityStore';
import {EntityStore} from '@/stores/lib/EntityStore';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import type {EntityListItem} from '@shared/models/FilteredEntityList';
import type {Label} from '@shared/models/Label';

class LabelStore extends EntityStore<EntityStoreState<Label>, Label> {
  private allLabels: EntityListItem[] = [];

  constructor() {
    super(EntityType.Label, {byId: new Map(), byList: new Map()});

    this.selectAndSubscribe(
      (s) => s.getList(Filters.labelFilter({projectId: contextStore.projectId})),
      (list) => {
        this.allLabels = list ?? [];
      },
    );
  }

  getByName(name: string) {
    const label = this.allLabels.find((l) => l.text === name);
    if (label) {
      this.tracking.accessed.add(label.id);
    }
    return label;
  }
}

export const labelStore = persistThroughHotReload('labelStore', new LabelStore());

exposeInDev({labelStore});
import.meta.hot?.accept(reloadOnHotUpdate);
