export enum TaskVariant {
  Epic = 'Epic',
  Task = 'Task',
  SubTask = 'SubTask',
}

export enum DocumentFormat {
  None = 'None',
  ADF = 'ADF',
  Momentum = 'Momentum',
  Markdown = 'Markdown',
}

export interface Task {
  id: number;
  accountId: number;
  createdAt: string;
  updatedAt: string;
  key: string;
  title: string;
  description: string;
  descriptionFormat: DocumentFormat;
  typeId: number;
  parentId?: number;
  projectId: number;
  priorityId: number;
  statusId: number;
  creatorId: number;
  reporterId?: number;
  assigneeId?: number;
  rank: string;
  points?: number;
  sprintId?: number;
  variant: TaskVariant;
  labels: number[];
  components: number[];
  // TODO ...

  customProperties: Record<string, any>;
}
