import {MessageListItem} from '@/components/inbox/MessageListItem';
import type {ListStore, Selection} from '@/design-system/lists/ListStore';
import type {ListItemRenderProps} from '@/design-system/lists/VList';
import {VList} from '@/design-system/lists/VList';
import {inboxMessageStore} from '@/stores/inboxMessage';
import {inboxStore} from '@/stores/ui/inboxStore';
import {Filters} from '@shared/filters/Filters';
import type {EntityListItem} from '@shared/models/FilteredEntityList';
import React, {useMemo, useRef} from 'react';

interface Props {
  projectId?: number;
  onClick: (id: number) => void;
  onMarkUnread: (id: number) => void;
}

export const MessageList: React.FC<Props> = ({projectId, onClick, onMarkUnread}) => {
  const unread = [
    ...(inboxMessageStore.use((s) => s.getList(Filters.inboxMessageFilter({projectId, read: false})), [projectId]) ??
      []),
  ];
  const read = [
    ...(inboxMessageStore.use((s) => s.getList(Filters.inboxMessageFilter({projectId, read: true})), [projectId]) ??
      []),
  ];
  const [selectedId, wasSelectedUnread] = inboxStore.use((s) => [s.state.selectedId, s.state.wasSelectedUnread], []);
  const sourceIndex = selectedId != null ? read.findIndex((r) => r.id === selectedId) : -1;
  if (sourceIndex !== -1 && wasSelectedUnread) {
    // keep in unread until the selection changes
    const targetIndex = unread.findIndex((r) => r.rank < read[sourceIndex].rank);
    if (targetIndex !== -1) {
      unread.splice(targetIndex, 0, read.splice(sourceIndex, 1)[0]);
    } else {
      unread.push(read.splice(sourceIndex, 1)[0]);
    }
  }
  const ids = unread.concat([{id: 0, rank: ''}]).concat(read);
  const count = (unread?.length ?? 0) + (read?.length ?? 0) + 1;
  const ref = useRef<ListStore<EntityListItem, number>>(null);

  function handleSelectionChange(selection: Selection<number>) {
    inboxStore.setSelectedId([...selection.keys].pop());
  }

  const ItemRenderer = useMemo(
    () => (item: ListItemRenderProps<EntityListItem, number | string>) =>
      item.id === 0 ? (
        <div
          key={item.id}
          className="flex h-full items-end justify-center pb-4 text-xs font-semibold text-pink-900 text-opacity-50"
        >
          — RECENTLY READ —
        </div>
      ) : (
        <MessageListItem key={item.id} id={item.id as number} onClick={onClick} onMarkUnread={onMarkUnread} />
      ),
    [onClick, onMarkUnread],
  );

  if (count === 1) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center text-pink-900 text-opacity-25">
        No messages
      </div>
    );
  }

  return (
    <div className="p-4">
      <VList<EntityListItem, number>
        ref={ref}
        items={ids}
        renderItem={ItemRenderer}
        selection={selectedId}
        itemClassName="rounded-md"
        itemHeight={80}
        itemGap={10}
        onSelectionChange={handleSelectionChange}
      />
    </div>
  );
};
