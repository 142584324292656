import {Badge} from '@/design-system/Badge';
import {useDrag, useDrop} from '@/design-system/dnd/dragHooks';
import type {Collection} from '@/design-system/lists/Collection';
import {indexOf, useCollection} from '@/design-system/lists/Collection';
import type {Selection} from '@/design-system/lists/ListStore';
import {DEFAULT_STATE, DropLocation, ListContext, ListStore, type ListItem} from '@/design-system/lists/ListStore';
import {debounce} from '@shared/lib/debounce';
import {isStateEqual} from '@shared/lib/isStateEqual';
import type {DragEvent, Ref} from 'react';
import {forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';

export interface ListItemRenderProps<T extends ListItem<K>, K extends number | string> {
  id: K;
  item: T | undefined;
  isSelected: boolean;
  isFocused: boolean;
  isDragging: boolean;
  isDragPreview: boolean;
}

export interface DndOptions<T extends ListItem<K>, K extends number | string> {
  mode?: 'reorder/insert' | 'none';
  source?: string;
  getDragData?: (keys: Set<K>) => Record<string, string> | undefined;
  allowedEffect?: (index: number, location: DropLocation, dataTypes: readonly string[]) => DataTransfer['dropEffect'];
  onDrop?: (index: number, location: DropLocation, e: DragEvent) => DataTransfer['dropEffect'];
  /** if not provided, the renderItem fn will be used */
  renderPreview?: (item: T, e: DragEvent) => React.ReactNode;
}

export interface Props<T extends ListItem<K>, K extends number | string> extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  items: T[] | Collection<K, T>;
  selected?: K | undefined;
  selection?: Selection<K> | Set<K> | K;
  onSelectionChange?: (selection: Selection<K>) => void;
  /** Should be memoized! Renders an item in the list. */
  renderItem: (renderProps: ListItemRenderProps<T, K>) => React.ReactNode;
  // virtualization
  itemHeight: number;
  itemGap?: number;
  itemClassName?: string;
  overscan?: number;
  /** dnd options should be memoized to reduce row-rerenders */
  dnd?: DndOptions<T, K>;
}

type VListComponent = <T extends ListItem<K>, K extends number | string>(
  props: Props<T, K> & {ref: Ref<ListStore<T, K>>},
  ref: Ref<ListStore<T, K>>,
) => React.ReactElement;
export const VList: VListComponent = forwardRef(function VList<T extends ListItem<K>, K extends number | string>(
  {
    className,
    items,
    itemHeight,
    itemGap = 0,
    itemClassName,
    renderItem,
    overscan = 10,
    onSelectionChange,
    selection,
    dnd,
    ...props
  }: Props<T, K>,
  ref: Ref<ListStore<T, K>>,
) {
  const collection = useCollection(items);
  const [store] = useState(() => new ListStore<T, K>({...DEFAULT_STATE, collection}));
  useImperativeHandle(ref, () => store, [store]);
  useEffect(() => {
    if (!onSelectionChange) return;
    return store.subscribe((s) => s.state.selection, onSelectionChange);
  }, [store, onSelectionChange]);

  useEffect(() => {
    store.mutate((s) => {
      s.collection = collection;
      s.itemHeight = itemHeight;
      s.itemGap = itemGap;
    });
  }, [store, collection, itemHeight, itemGap]);

  useEffect(() => {
    // support providing selection as a set, specific key, or entire selection
    let sel: Selection<K>;
    if (selection instanceof Set) {
      sel = {keys: selection};
    } else if (typeof selection === 'number' || typeof selection === 'string') {
      sel = {keys: new Set([selection])};
    } else if (typeof selection === 'object') {
      sel = selection;
    } else {
      sel = {keys: new Set()};
    }

    // if the selection has changed, update it in the store
    for (const [key, value] of Object.entries(sel) as [keyof Selection<K>, unknown][]) {
      if (!isStateEqual(store.selection[key] as typeof value, value)) {
        // this has a side effect if the entire selection is not provided, it ensures the anchor/etc are included in the set
        const focus = sel.keys.values().next()?.value;
        store.setSelection({...sel, focus: sel.focus ?? focus});
        break;
      }
    }
  }, [store, selection]);

  const selfRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let root: HTMLElement | undefined;
    let el: HTMLElement = selfRef.current!;
    while (el && el.parentElement) {
      // TODO: this could be better, but we know it will exist in our scroll view, which has overflow: scroll
      if (el.style.overflow === 'scroll' || el.style.overflowY === 'scroll') {
        root = el;
        break;
      }
      root = el;
      el = el.parentElement;
    }

    let lastScrollPosition = el?.scrollTop;
    function onLayoutChange(e: Event | Parameters<ResizeObserverCallback>[0][0]) {
      const container = e.target as HTMLDivElement;
      const containerRect = container.getBoundingClientRect();
      const elRect = selfRef.current?.getBoundingClientRect();
      if (!elRect) return;
      const intersectionTop = Math.max(elRect.top, containerRect.top);
      const intersectionBottom = Math.min(elRect.bottom, containerRect.bottom);

      let firstRow = 0;
      let nRows = 0;
      const rowHeight = store.itemHeight + store.itemGap;
      if (intersectionTop < intersectionBottom && rowHeight > 0) {
        firstRow = Math.max(0, ((intersectionTop - elRect.top) / rowHeight) >> 0);
        nRows = Math.ceil((intersectionBottom - intersectionTop) / rowHeight) + overscan * 2;
      } else {
        nRows = overscan * 2;
      }
      // overscan more in the direction we're scrolling
      if (container.scrollTop < lastScrollPosition) {
        firstRow = Math.max(0, (firstRow - overscan * 1.8) >> 0);
      } else {
        firstRow = Math.max(0, (firstRow - overscan * 0.2) >> 0);
      }
      lastScrollPosition = container.scrollTop;

      store.mutate((s) => {
        s.firstRow = firstRow;
        s.nRows = nRows;
        s.nRowsPerPage = Math.max(1, (containerRect.height / (store.itemHeight + store.itemGap) - 1) >>> 0);
      });
    }

    const resizeHandler = debounce((entries: ResizeObserverEntry[]) => {
      onLayoutChange(entries[0]);
    }, 0);
    const resizeObserver = new ResizeObserver(resizeHandler);

    root?.addEventListener('scroll', onLayoutChange);
    root && resizeObserver.observe(root);

    function onFocusChange(id: K | undefined) {
      const index = store.collection.idToIndex[id!];
      if (index >= 0 && root && selfRef.current) {
        const listScrollOffset =
          selfRef.current.getBoundingClientRect().top + root.scrollTop - root.getBoundingClientRect().top;
        let targetAsTop = listScrollOffset + (itemHeight + store.itemGap) * index;
        const targetAsBottom = targetAsTop - root.clientHeight + (itemHeight + store.itemGap) * 2;
        targetAsTop -= itemHeight + store.itemGap;
        if (targetAsBottom > root.scrollTop) {
          root.scrollTo({top: targetAsBottom, behavior: 'instant'});
        } else if (targetAsTop < root.scrollTop) {
          root.scrollTo({top: targetAsTop, behavior: 'instant'});
        }
      }
    }
    const unsubscribe = store.subscribe((s) => s.state.selection.focus, onFocusChange);
    if (store.selection.focus) onFocusChange(store.selection.focus);

    root && onLayoutChange({target: root} as unknown as Event);

    return () => {
      root?.removeEventListener('scroll', onLayoutChange);
      root && resizeObserver.unobserve(root);
      resizeHandler.cancel();
      unsubscribe();
    };
  }, [overscan, selfRef.current, collection.list.length]);

  const [firstRow, nRows, dragStartIndex] = store.use(
    (s) => [s.state.firstRow, s.state.nRows, s.state.dragStartIndex],
    [],
  );
  const rows = [];
  for (let i = firstRow; i < firstRow + nRows && i < collection.list.length; i++) {
    rows.push(
      <VirtualListRow<T, K>
        key={i}
        item={collection.list[i]}
        className={itemClassName}
        index={i}
        itemHeight={itemHeight}
        itemGap={itemGap}
        renderItem={renderItem}
        dnd={dnd}
      />,
    );
  }

  // always render the item the user started the drag from
  if (dragStartIndex !== undefined && (dragStartIndex < firstRow || dragStartIndex > firstRow + nRows)) {
    const row = (
      <VirtualListRow<T, K>
        key={dragStartIndex}
        className={itemClassName}
        item={collection.list[dragStartIndex]}
        index={dragStartIndex}
        itemHeight={itemHeight}
        itemGap={itemGap}
        renderItem={renderItem}
        dnd={dnd}
      />
    );
    if (dragStartIndex < firstRow) {
      rows.unshift(row);
    } else {
      rows.push(row);
    }
  }

  return (
    <ListContext.Provider value={{listStore: store}}>
      <div
        {...props}
        ref={selfRef}
        className={twMerge('relative', className)}
        style={{height: (itemHeight + itemGap) * collection.list.length}}
      >
        <DropLayer dnd={dnd}>{rows}</DropLayer>
      </div>
    </ListContext.Provider>
  );
}) as VListComponent;

function VirtualListRow<T extends ListItem<K>, K extends number | string>({
  className,
  index,
  item,
  itemHeight,
  itemGap,
  renderItem,
  dnd,
}: {
  className?: string;
  item: T;
  index: number;
  itemHeight: number;
  itemGap: number;
  renderItem: (renderProps: ListItemRenderProps<T, K>) => React.ReactNode;
  dnd?: DndOptions<T, K>;
}) {
  const store = useContext(ListContext).listStore as ListStore<T, K>;
  const selection = store.use((s) => s.state.selection, []);
  const [wasFocused, setFocused] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handlers = useMemo(() => createHandlers(store, index, item.id, ref), [store, index, item.id]);

  const isFocused = selection.focus === item.id;
  useEffect(() => {
    if (wasFocused != isFocused) {
      setFocused(isFocused);
      if (isFocused) {
        ref.current?.focus({preventScroll: true});
      }
    }
  }, [isFocused !== wasFocused]);

  if (item === undefined) return null;

  let {
    dragProps: dragProps,
    renderProps: {isDragging},
  } = useDrag(
    {
      source: dnd?.source ?? 'VListItem',
      preview: dnd?.renderPreview ? (e) => dnd.renderPreview!(item, e) : createPreviewRenderer(store, item, renderItem),
      onDragStart: () => {
        if ((dnd?.mode ?? 'none') === 'none') {
          return false;
        }
        store.update({dragStartIndex: index});
        const data = dnd?.getDragData?.(store.selection.keys);
        if (dnd?.getDragData && !data) return false;
        return {data: data ?? {}};
      },
      onDragEnd: () => {
        store.update({dragStartIndex: undefined});
      },
    },
    [item.id, renderItem, dnd],
  );

  const itemRenderProps: ListItemRenderProps<T, K> = {
    id: item.id,
    item,
    isSelected: selection.keys.has(item.id),
    isFocused,
    isDragging,
    isDragPreview: false,
  };

  return (
    <div
      className={twMerge('absolute w-full', className)}
      style={{height: itemHeight, top: (itemHeight + itemGap) * index}}
      {...handlers}
      tabIndex={0}
      ref={ref}
      {...dragProps}
    >
      {renderItem(itemRenderProps)}
    </div>
  );
}

function createHandlers<T extends ListItem<K>, K extends number | string>(
  store: ListStore<T, K>,
  index: number,
  itemId: K,
  ref: React.RefObject<HTMLDivElement>,
) {
  return {
    onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const expanding = e.shiftKey;
      const toggling = e.ctrlKey || e.metaKey;
      const maintaining = !expanding && !toggling && store.selection.keys.has(itemId);
      const newSelection = {
        selected: itemId,
        keys: maintaining ? store.selection.keys : new Set(expanding || toggling ? store.selection.keys : [itemId]),
        focus: itemId,
        anchor: expanding ? (store.selection.anchor ?? itemId) : itemId,
      };

      if (expanding) {
        const items = store.collection;
        const anchorIndex = indexOf(items, newSelection.anchor);
        const targetIndex = indexOf(items, itemId);
        const focusIndex =
          store.selection.focus === newSelection.anchor ? anchorIndex : indexOf(items, store.selection.focus);
        // remove from focus up to anchor, then add from anchor to target
        if (focusIndex >= 0 && anchorIndex >= 0) {
          for (let i = focusIndex; i != anchorIndex; i += i < anchorIndex ? 1 : -1) {
            newSelection.keys.delete(store.collection.list[i].id);
          }
        }
        if (anchorIndex >= 0) {
          for (let i = anchorIndex; i != targetIndex && targetIndex >= 0; i += i < targetIndex ? 1 : -1) {
            newSelection.keys.add(store.collection.list[i].id);
          }
        }
        newSelection.keys.add(itemId);
      } else if (toggling) {
        if (newSelection.keys.has(itemId)) {
          newSelection.keys.delete(itemId);
        } else {
          newSelection.keys.add(itemId);
        }
      }

      store.setSelection(newSelection);
    },
    onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.target !== ref.current) return;

      function extendSelectionTo(targetIndex: number | undefined) {
        if (targetIndex === undefined) return;
        targetIndex = Math.max(0, Math.min(targetIndex, store.collection.list.length - 1));
        let fromIndex = indexOf(store.collection, store.state.selection.focus ?? store.state.selection.selected);
        // only if the user is trying to move beyond the list, allow the key event to propagate
        if (fromIndex !== targetIndex) e.stopPropagation();

        store.mutate((s) => {
          if (fromIndex < 0) fromIndex = index;
          const anchorIndex = indexOf(store.collection, s.selection.anchor);
          const isExtending =
            e.shiftKey && fromIndex !== undefined && anchorIndex !== undefined && fromIndex != targetIndex;
          if (isExtending && fromIndex >= 0) {
            const dir = fromIndex <= targetIndex ? 1 : -1;
            const anchorDir = fromIndex <= anchorIndex ? 1 : -1;
            let i = fromIndex;
            // subtract if moving toward anchor
            if (dir === anchorDir) {
              for (; i != anchorIndex && i != targetIndex; i += dir) {
                s.selection.keys.delete(store.collection.list[i].id);
              }
            }
            // then add from there to target
            for (; i != targetIndex; i += dir) {
              s.selection.keys.add(store.collection.list[i].id);
            }
            s.selection.keys.add(store.collection.list[targetIndex].id);
          } else {
            s.selection.keys = new Set([store.collection.list[targetIndex].id]);
            s.selection.anchor = store.collection.list[targetIndex].id;
          }
          s.selection.focus = store.collection.list[targetIndex].id;
          s.selection.selected = store.collection.list[targetIndex].id;
        });
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        extendSelectionTo(index + 1);
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        extendSelectionTo(index - 1);
      } else if (e.key === 'Home') {
        e.preventDefault();
        extendSelectionTo(0);
      } else if (e.key === 'End') {
        e.preventDefault();
        extendSelectionTo(store.collection.list.length - 1);
      } else if (e.key === 'PageDown') {
        e.preventDefault();
        extendSelectionTo(index + store.state.nRowsPerPage);
      } else if (e.key === 'PageUp') {
        e.preventDefault();
        extendSelectionTo(index - store.state.nRowsPerPage);
      } else if (e.key === 'Escape') {
        e.preventDefault();
        store.setSelection({keys: new Set()});
      } else if (e.key === 'Enter') {
        e.preventDefault();
        store.setSelection({keys: new Set([itemId])});
      }
    },
    onFocus(e: React.FocusEvent<HTMLDivElement>) {
      if (e.target !== ref.current) return;
      if (index >= 0) {
        store.mutate((s) => {
          s.selection.focus = itemId;
        });
      }
    },
  };
}

function DropLayer<T extends ListItem<K>, K extends string | number>({
  children,
  dnd,
}: {
  children: React.ReactNode;
  dnd?: DndOptions<T, K>;
}) {
  const store = useContext(ListContext).listStore;
  const ref = useRef<HTMLDivElement>(null);

  const handleDragOver = (e: DragEvent): DataTransfer['dropEffect'] => {
    const isDisabled = (dnd?.mode ?? 'none') === 'none';
    if (isDisabled) return 'none';

    const y = e.pageY - (ref.current?.getBoundingClientRect().top ?? 0);
    const row = y / (store.itemHeight + store.itemGap);
    const index = Math.min(Math.max(0, Math.floor(row)), store.collection.list.length - 1);

    // // avoid top-bottom jiggle when on the center of an item
    // if (store.state.dragOver.index === index && store.state.dragOver.location !== DropLocation.None) {
    //   const center = index * (store.itemHeight + store.itemGap) + store.itemHeight / 2;
    //   if (Math.abs(y - center) < 1.5) return effect;
    // }

    let location = row - index > 0.5 ? DropLocation.Below : DropLocation.Above;
    if (store.state.selection.keys.has(store.collection.list[index]?.id)) {
      location = DropLocation.None;
    }

    let effect = dnd?.allowedEffect?.(index, location, e.dataTransfer.types) ?? 'none';
    if (effect === 'none') {
      store.clearDragOver();
      return 'none';
    }

    store.setDragOver(index, location);
    return location === DropLocation.None ? 'none' : effect;
  };

  const {dropProps} = useDrop(
    {
      onDragEnter: handleDragOver,
      onDragOver: handleDragOver,
      onDragLeave: (_e) => {
        store.clearDragOver();
      },
      onDrop: (e) => {
        store.clearDragOver();
        if (store.state.dragOver.index === undefined || store.state.dragOver.location === DropLocation.None)
          return 'none';

        return dnd?.onDrop?.(store.state.dragOver.index!, store.state.dragOver.location, e) ?? 'move';
      },
    },
    [dnd],
  );

  return (
    <div ref={ref} className="absolute bottom-0 left-0 right-0 top-0" {...dropProps}>
      {children}
      <DropIndicator />
    </div>
  );
}

function DropIndicator() {
  const store = useContext(ListContext).listStore;
  const {location, index, itemHeight, itemGap} = store.use(
    (s) => ({
      location: s.state.dragOver.location,
      index: s.state.dragOver.index,
      itemHeight: s.itemHeight,
      itemGap: s.itemGap,
    }),
    [],
  );

  const isExiting = location === DropLocation.None;

  if (index === undefined || isExiting) return null;
  const top =
    index * (itemHeight + itemGap) + (location === DropLocation.Above ? -itemGap / 2 : itemHeight + itemGap / 2);

  return (
    <div
      className={twMerge(
        'pointer-events-none absolute left-0 right-0 z-10 h-0 -translate-y-[1px] border-b-2 border-dashed border-pink-500',
      )}
      style={{top: `${top}px`}}
    />
  );
}

function createPreviewRenderer<T extends ListItem<K>, K extends number | string>(
  store: ListStore<T, K>,
  _item: T,
  renderItem: Props<T, K>['renderItem'],
) {
  return () => {
    const limit = Math.max(1, Math.floor(150 / store.itemHeight));
    const size = store.selection.keys.size;
    return (
      <div className="max-w-[min(600px,75vw)] origin-top-left scale-90 rounded border border-pink-500/70">
        <Badge
          content={store.selection.keys.size > 1 ? store.selection.keys.size : ''}
          size="lg"
          className="left-2 right-[inherit]"
        >
          {size > limit && (
            <>
              <div className="absolute left-[4.17%] top-full h-1 w-11/12 translate-y-[1px] rounded-b border-x border-b border-pink-500/50 bg-white/80" />
              {size > limit + 1 && (
                <div className="absolute left-[8.33%] top-full h-1 w-10/12 translate-y-[5px] rounded-b border-x border-b border-pink-500/35 bg-white/60" />
              )}
              {size > limit + 2 && (
                <div className="absolute left-[12.5%] top-full h-1 w-9/12 translate-y-[9px] rounded-b border-x border-b border-pink-500/20 bg-white/40" />
              )}
            </>
          )}
          <div className="flex flex-col gap-[1px] overflow-hidden rounded bg-gray-100">
            {[...new Set([...store.selection.keys])]
              .sort((a, b) => store.collection.idToIndex[a] - store.collection.idToIndex[b])
              .filter((id) => id != undefined)
              .slice(0, limit)
              .map((id) =>
                renderItem({
                  id,
                  item: store.collection.list[store.collection.idToIndex[id]],
                  isSelected: true,
                  isFocused: false,
                  isDragging: false,
                  isDragPreview: true,
                }),
              )}
          </div>
        </Badge>
      </div>
    );
  };
}
