import {Spacer} from '@/design-system';
import {Time, TimeUnit} from '@/lib/time';
import {timeMonitor} from '@/lib/TimeMonitor';
import React, {useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';

interface Props {
  date: string;
  prefix?: string;
  suffix?: string;
  precision?: TimeUnit;
  className?: string;
}

interface State {
  updateAt: TimeUnit;
  text: string;
}

export const RelativeTime: React.FC<Props> = ({
  date,
  prefix,
  suffix,
  precision = TimeUnit.Seconds,
  className = 'text-xs text-gray-500',
}) => {
  const [state, setState] = useState<State>(() => getRelativeTime(new Date(date), new Date(), precision));

  useEffect(() => {
    setState(getRelativeTime(new Date(date), new Date(), precision));
    const handler = (now: Date) => setState(getRelativeTime(new Date(date), now, precision));
    timeMonitor.on(state.updateAt, handler);
    return () => timeMonitor.off(state.updateAt, handler);
  }, [state.text, state.updateAt, date]);

  return (
    <div className={twMerge('relative cursor-default', className)}>
      <div className="flex">
        {prefix && (
          <>
            {prefix}
            <Spacer />
          </>
        )}
        {state.text}
        {suffix}
      </div>
      <div
        className="absolute left-0 top-0 h-full w-full opacity-0 hover:opacity-100"
        style={{transition: 'opacity 0.15s'}}
      >
        <div className="pointer-events-none absolute right-0 top-0 mr-[-0.25rem] mt-[-0.25rem] w-fit whitespace-nowrap rounded-sm bg-gray-100 p-1 text-xs text-gray-500 drop-shadow-md">
          {new Date(date).toLocaleString()}
        </div>
      </div>
    </div>
  );
};

function getRelativeTime(date: Date, now: Date, precision: TimeUnit = TimeUnit.Seconds): State {
  let seconds = (Time.trunc(now.getTime(), precision) - Time.trunc(date.getTime(), precision)) / 1000;
  let prefix = '';
  let suffix = ' ago';

  // future dates
  if (seconds < 0) {
    seconds = -seconds;
    prefix = 'in ';
    suffix = '';
  }

  if (seconds < 60 && precision <= TimeUnit.Seconds) {
    return {updateAt: TimeUnit.Seconds, text: `${prefix}${seconds}s${suffix}`};
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60 && precision <= TimeUnit.Minutes) {
    return {updateAt: TimeUnit.Seconds, text: `${prefix}${minutes}m${suffix}`};
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24 && precision <= TimeUnit.Hours) {
    return {updateAt: TimeUnit.Minutes, text: `${prefix}${hours}h${suffix}`};
  }

  const days = Math.floor(hours / 24);
  if (days < 30 && precision <= TimeUnit.Days) {
    return {updateAt: TimeUnit.Hours, text: `${prefix}${days}d${suffix}`};
  }

  if (days < 300 && precision <= TimeUnit.Months) {
    return {updateAt: TimeUnit.Days, text: shortMonths[date.getMonth()] + ' ' + date.getDate()};
  }

  return {updateAt: TimeUnit.None, text: shortMonths[date.getMonth()] + ' ' + date.getFullYear()};
}

const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] as Readonly<
  string[]
>;
