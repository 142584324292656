import {ScrollView} from '@/design-system/ScrollView';
import {ContentMenu} from '@/text-editor/interfaces/ContentMenu';
import {FormattingMenu} from '@/text-editor/interfaces/FormattingMenu';
import {LinkMenu} from '@/text-editor/interfaces/LinkMenu';
import {moEditorOptions} from '@/text-editor/useMoEditor';
import {debounce} from '@shared/lib/debounce';
import {isStateEqual} from '@shared/lib/isStateEqual';
import type {FocusPosition} from '@tiptap/core';
import Placeholder from '@tiptap/extension-placeholder';
import type {Transaction} from '@tiptap/pm/state';
import type {JSONContent} from '@tiptap/react';
import {BubbleMenu, Editor, EditorContent} from '@tiptap/react';
import type {ForwardedRef, KeyboardEvent} from 'react';
import React, {Component, createRef} from 'react';
import {twMerge} from 'tailwind-merge';

interface Props {
  doc?: string;
  taskId?: number;
  onSave?: (doc: string) => void;
  onDirtyChange?: (isDirty: boolean) => void;
  className?: string;
  editorClassName?: string;
  placeholder?: string;
  autofocus?: FocusPosition;
  onEscape?: () => void;
  onSubmit?: () => void;
  onChange?: (doc: JSONContent) => void;
  inline?: boolean;
  focusControls?: boolean;
}

export interface EditorState {
  editor: Editor | null;
  isDirty: boolean;
}

export const emptyDocString = JSON.stringify({type: 'doc', content: [{type: 'paragraph', content: []}]});
export function isEmptyDoc(doc: JSONContent) {
  if (!doc.content?.length) return true;
  if (doc.content.length === 1 && doc.content[0].type === 'paragraph') {
    const paragraph = doc.content[0];
    if (!paragraph.content?.length) return true;
    if (paragraph.content.length === 1 && paragraph.content[0].type === 'text') {
      return paragraph.content[0].text === '';
    }
  }
  return false;
}
export function isEmptyDocString(doc: string) {
  return !doc || isEmptyDoc(JSON.parse(doc));
}
export let taskIdForActiveEditor: number | undefined;

const popperOptions = {modifiers: [{name: 'preventOverflow', options: {rootBoundary: 'document'}}]};

interface State {
  providedDoc?: string;
  initialJson?: JSONContent;
  editorState: EditorState;
  isFocusWithin: boolean;
}

export class MoEditor extends Component<Props & {forwardedRef: ForwardedRef<EditorState>}, State> {
  private containerRef = createRef<HTMLDivElement>();
  private editor: Editor | null = null;
  private debouncedHandleUpdate: ((props: {editor: Editor; transaction: Transaction}) => void) & {cancel: () => void};

  static defaultProps = {
    autofocus: false,
    inline: false,
    focusControls: false,
  };

  constructor(props: Props & {forwardedRef: ForwardedRef<EditorState>}) {
    super(props);
    this.editor = new Editor(
      moEditorOptions(
        {
          editable: !!this.props.doc,
          autofocus: this.props.autofocus,
          extensions: this.props.placeholder ? [Placeholder.configure({placeholder: this.props.placeholder})] : [],
        },
        this.props.editorClassName,
      ),
    );

    this.state = {
      providedDoc: undefined,
      initialJson: undefined,
      editorState: {
        editor: this.editor,
        isDirty: false,
      },
      isFocusWithin: false,
    };

    this.debouncedHandleUpdate = debounce(this.handleUpdate, 200, 5000);
  }

  componentDidMount() {
    this.editor?.on('update', this.debouncedHandleUpdate);

    if (this.props.forwardedRef) {
      if (typeof this.props.forwardedRef === 'function') {
        this.props.forwardedRef(this.state.editorState);
      } else {
        this.props.forwardedRef.current = this.state.editorState;
      }
    }

    this.containerRef.current?.addEventListener('focusin', this.handleFocusIn);
    this.containerRef.current?.addEventListener('focusout', this.handleFocusOut);
    this.handleDocChange();
  }

  componentWillUnmount() {
    this.debouncedHandleUpdate.cancel();
    this.editor?.off('update', this.debouncedHandleUpdate);
    this.containerRef.current?.removeEventListener('focusin', this.handleFocusIn);
    this.containerRef.current?.removeEventListener('focusout', this.handleFocusOut);
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isStateEqual(this.props, nextProps) || !isStateEqual(this.state, nextState);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.doc !== prevProps.doc) {
      this.handleDocChange();
    }
  }

  handleUpdate = () => {
    if (!this.editor || !this.state.initialJson) return;
    const json = this.editor.getJSON();
    const isDirty = !isStateEqual(this.state.initialJson, json);
    if (isDirty !== this.state.editorState.isDirty) {
      this.setState({
        editorState: {editor: this.editor, isDirty},
      });
      this.props.onDirtyChange?.(isDirty);
    } else if (this.state.editorState.editor !== this.editor) {
      this.setState({
        editorState: {editor: this.editor, isDirty: this.state.editorState.isDirty},
      });
    }
    this.props.onChange?.(json);
  };

  handleDocChange = () => {
    if (!this.props.doc || !this.editor) return;
    if (this.props.doc !== this.state.providedDoc) {
      const content = JSON.parse(this.props.doc);
      this.editor.commands.setContent(content, false);
      this.editor.setEditable(true);
      if (this.state.providedDoc === undefined && this.props.autofocus) {
        this.editor.commands.focus();
      }
      this.setState(
        {
          providedDoc: this.props.doc,
          initialJson: this.editor.getJSON(),
        },
        this.handleUpdate,
      );
    } else {
      this.setState((prevState) => ({
        initialJson: prevState.initialJson ?? this.editor!.getJSON(),
      }));
    }
    if (this.state.editorState.editor !== this.editor) {
      this.setState((prevState) => ({
        editorState: {editor: this.editor, isDirty: prevState.editorState.isDirty},
      }));
    }
  };

  handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 's' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.editor || !this.state.editorState.isDirty) return;
      this.props.onSave?.(JSON.stringify(this.editor.getJSON()));
    } else if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      this.props.onEscape?.();
    } else if ((event.key === 'Enter' || event.key === 'Return') && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.editor || !this.state.editorState.isDirty) return;
      this.props.onSubmit?.();
    }
  };

  handleFocusIn = () => {
    taskIdForActiveEditor = this.props.taskId;
    this.setState({isFocusWithin: true});
  };

  handleFocusOut = () => {
    taskIdForActiveEditor === this.props.taskId && (taskIdForActiveEditor = undefined);
    this.setState({isFocusWithin: false});
  };

  render() {
    if (!this.props.doc || !this.editor) {
      return '';
    }

    return (
      <div
        ref={this.containerRef}
        className={twMerge(
          'flex flex-col focus-within:bg-white focus-within:ring-2 focus-within:ring-pink-500/80',
          this.props.className,
        )}
      >
        <ContentMenu
          editor={this.editor}
          className={twMerge(
            'opacity-100 transition-opacity',
            this.props.focusControls && this.props.inline && 'border-t-2 border-pink-500/80',
            this.props.focusControls && !this.state.isFocusWithin && 'pointer-events-none opacity-0',
          )}
        />
        <BubbleMenu
          editor={this.editor}
          tippyOptions={{popperOptions, appendTo: () => this.containerRef.current!, zIndex: 10}}
          shouldShow={(props) => !props.editor.isActive('link') && !props.state.selection.empty}
        >
          <FormattingMenu editor={this.editor} />
        </BubbleMenu>
        <BubbleMenu
          editor={this.editor}
          pluginKey="linkBubbleMenu"
          shouldShow={(props) => props.editor.isActive('link')}
          tippyOptions={{
            placement: 'bottom',
            maxWidth: 450,
            popperOptions,
            zIndex: 10,
            appendTo: () => this.containerRef.current!,
          }}
        >
          <LinkMenu editor={this.editor} />
        </BubbleMenu>
        {this.props.inline ? (
          <div className="border-y border-gray-800/10 bg-white">
            <EditorContent editor={this.editor} onKeyDownCapture={this.handleKeyDown} />
          </div>
        ) : (
          <ScrollView autoHide autoHeight>
            <EditorContent editor={this.editor} onKeyDownCapture={this.handleKeyDown} />
          </ScrollView>
        )}
      </div>
    );
  }
}

// Wrap component to handle forwardRef
export default React.forwardRef<EditorState, Props>((props, ref) => <MoEditor {...props} forwardedRef={ref} />);
