import {EntityType} from '@shared/EntityType';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from '@shared/filters/EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC} from '@shared/filters/EntityFilter';
import type {ActorDatum, ActorDatumType} from '@shared/models/ActorDatum';
import type {DB} from 'src-sw/db/db';

/**
 * This is an actor specific entity, so we don't need to filter by actorId,
 * as it will be based off our authorization context.
 */
export class ActorDatumFilter extends EntityFilter<IActorDatumFilter> {
  readonly type = EntityType.ActorDatum;
  readonly datumType?: ActorDatumType;
  readonly key?: string;

  constructor(filter: Omit<IActorDatumFilter, 'type'>) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(_db: DB, entity: ActorDatum): Promise<[boolean, string, RankComparator]> {
    if (this.datumType !== undefined && entity.type !== this.datumType) {
      return [false, entity.type, RANK_COMPARATOR_ASC];
    }

    if (this.key !== undefined && entity.key !== this.key) {
      if (this.key.endsWith('*') && entity.key.startsWith(this.key.slice(0, -1))) {
        return [true, entity.key, RANK_COMPARATOR_ASC];
      }
      return [false, entity.key, RANK_COMPARATOR_ASC];
    }

    return [true, entity.type, RANK_COMPARATOR_ASC];
  }

  public toString(): string {
    return `${this.type}|${this.datumType ?? ''}|${this.key ?? ''}`;
  }
}

export type IActorDatumFilter = PublicPropertiesOf<ActorDatumFilter>;
export function isActorDatumFilter(filter: IEntityFilter): filter is ActorDatumFilter {
  return filter.type === EntityType.ActorDatum;
}
