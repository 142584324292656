import {RelativeTime} from '@/components/time/RelativeTime';
import {sprintStore} from '@/stores/sprint';
import type {Sprint} from '@shared/models/Sprint';
import {SprintState} from '@shared/models/Sprint';
import React from 'react';
import {twMerge} from 'tailwind-merge';

export interface SprintLabelProps {
  id?: number | null;
  text?: string;
  className?: string;
}

export const SprintLabel: React.FC<SprintLabelProps> = ({id, text, className = 'text-xs text-gray-500'}) => {
  const sprint = sprintStore.use((s) => (id ? s.getById(id) : null), [id]);
  const name = text ?? sprint?.name ?? (id === -2 ? 'New Sprint' : id === -1 ? 'Backlog' : '');

  return (
    <div className={twMerge('flex w-full items-center justify-between gap-2 whitespace-nowrap px-2', className)}>
      <span>{name}</span>
      <span className="flex flex-shrink text-ellipsis text-xs text-gray-500/50">
        <Label sprint={sprint} id={id} />
      </span>
    </div>
  );
};

function Label({sprint, id}: {sprint?: Sprint | null; id?: number | null}) {
  if (sprint?.state === SprintState.Future && sprint.startsAt) {
    return <RelativeTime className="" prefix="(Starts" suffix=")" date={sprint.startsAt} />;
  } else if (sprint?.state === SprintState.Active && sprint.endsAt) {
    return <RelativeTime className="" prefix="(Ends" suffix=")" date={sprint.endsAt} />;
  } else if (sprint?.state === SprintState.Closed && sprint.completedAt) {
    return <RelativeTime className="" prefix="(Ended" suffix=")" date={sprint.completedAt} />;
  } else if (id === -2) {
    return '(create)';
  } else {
    return '';
  }
}
