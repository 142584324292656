import {Worker} from '@/app-service-worker/Worker';
import {persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import {runLatest} from '@/lib/runLatest';
import {inboxMessageStore} from '@/stores/inboxMessage';
import {MoStore, mutation} from '@/stores/lib/MoStore';
import {taskStore} from '@/stores/task';
import {EntityType} from '@shared/EntityType';
import autobind from 'autobind-decorator';

interface InboxState {
  selectedId?: number;
  wasSelectedUnread?: boolean;
  taskId?: number;
  selectedTime: number;
}

const pageUrlRegex = /(?<context>\/:[^/]+)?\/(?<page>inbox|task)(?:\/(?<taskKey>\w+-\d+))?/;
const DEFAULT_STATE: InboxState = {
  selectedTime: 0,
};

class InboxStore extends MoStore<InboxState> {
  constructor() {
    super(DEFAULT_STATE);
    // subscribe to window url changes
    window.addEventListener('popstate', this.onPopState);
    window.addEventListener('pushState', this.onPushState);
    this.selectAndSubscribe((s) => s.state.selectedId, this.updateUrlFromState);
  }

  @autobind
  @mutation
  setSelectedId(id?: number) {
    if (id === this._state.selectedId) return;
    this._state.selectedId = id;
    this._state.selectedTime = Date.now();

    if (id == null || id === 0) return;

    inboxMessageStore
      .await((s) => s.getById(id))
      .then((message) => {
        if (message) {
          this.set('wasSelectedUnread', message.readAt == null);
        }
      });
  }

  @mutation
  setTaskId(id?: number) {
    this._state.taskId = id;
  }

  markRead(id: number) {
    inboxMessageStore
      .await((s) => s.getById(id))
      .then((message) => {
        if (!message?.readAt) {
          const now = new Date().toISOString();
          Worker.patchEntities(EntityType.InboxMessage, [{id: message?.id, readAt: now, updatedAt: now}]);
        }
      });
  }

  onMount() {
    this.onPopState();
  }

  private getUrlComponents() {
    return window.location.pathname.match(pageUrlRegex)?.groups ?? {};
  }

  private urlFromComponents(components: Partial<ReturnType<typeof this.getUrlComponents>>) {
    const {context, page, taskKey} = components;
    const basePath = context ? `${context}/` : '';
    return `${basePath}${page}/${taskKey ?? ''}`;
  }

  @autobind
  private onPopState() {
    const {taskKey} = this.getUrlComponents();
    if (taskKey) {
      runLatest(
        'InboxStore:urlSync',
        taskStore.await((s) => s.getIdByKey(taskKey)),
      )
        .then((taskId) => {
          this.setTaskId(taskId);
          return taskId && inboxMessageStore.await((s) => s.getIdByTaskId(taskId));
        })
        .then((inboxMessageId) => {
          this.setSelectedId(inboxMessageId);
        });
    } else {
      this.setSelectedId(undefined);
    }
  }

  @autobind
  private onPushState() {
    const {taskKey} = this.getUrlComponents();
    if (!taskKey) {
      this.mutate(() => {
        this.setSelectedId(undefined);
        this.setTaskId(undefined);
      });
    }
  }

  @autobind
  private updateUrlFromState() {
    const {selectedId} = this.state;
    runLatest('InboxStore:urlSync')
      .then(() => (selectedId ? inboxMessageStore.await((s) => s.getById(selectedId)) : undefined))
      .then((inboxMessage) => {
        this.setTaskId(inboxMessage?.taskId);
        return inboxMessage && taskStore.await((s) => s.getById(inboxMessage.taskId)?.key);
      })
      .then((selectedTaskKey) => {
        const {context, page, taskKey} = this.getUrlComponents();
        if (selectedTaskKey !== taskKey) {
          if (selectedTaskKey) {
            window.history.pushState(null, '', this.urlFromComponents({context, page, taskKey: selectedTaskKey}));
          } else {
            window.history.pushState(null, '', this.urlFromComponents({context, page}));
          }
        }
      });
  }
}

export const inboxStore = persistThroughHotReload('inboxStore', new InboxStore());
import.meta.hot?.accept(reloadOnHotUpdate);
