import {EntityType} from '@shared/EntityType';
import type {StatusCategory} from '@shared/models/Status';
import type {Task} from '@shared/models/Task';
import type {DB} from 'src-sw/db/db';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from './EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC, RANK_COMPARATOR_DESC} from './EntityFilter';

export enum TaskFilterOrder {
  RecentlyUpdated = 'RecentlyUpdated',
}

export class TaskFilter extends EntityFilter<ITaskFilter> {
  readonly type = EntityType.Task;
  readonly projectId?: number;
  readonly sprintId?: number | null;
  readonly statusId?: number[];
  readonly statusCategory?: StatusCategory[];
  readonly query?: string;
  readonly key?: string;
  readonly keys?: string[];
  readonly typeId?: number[];
  readonly assigneeId?: (number | null)[];
  readonly orderBy?: TaskFilterOrder;
  readonly limit?: number;

  public constructor(filter: Omit<ITaskFilter, 'type'>) {
    super();
    Object.assign(this, filter);
    this.statusCategory?.sort();
    this.statusId?.sort();
  }

  public async matchWithRank(db: DB, entity: Task): Promise<[boolean, string, RankComparator]> {
    const [rank, order] =
      this.orderBy === TaskFilterOrder.RecentlyUpdated
        ? [entity.updatedAt, RANK_COMPARATOR_DESC]
        : [entity.rank, RANK_COMPARATOR_ASC];

    if (this.projectId !== undefined && entity.projectId != this.projectId) {
      return [false, rank, order];
    }

    if (this.sprintId !== undefined && entity.sprintId != this.sprintId) {
      return [false, rank, order];
    }

    if (this.statusId && !this.statusId.includes(entity.statusId)) {
      return [false, rank, order];
    }

    if (this.statusCategory) {
      // TODO: cache all statuses in memory
      const status = await db.status.where('id').equals(entity.statusId).first();
      if (!status || !this.statusCategory.includes(status.category)) {
        return [false, rank, order];
      }
    }

    if (this.query) {
      const query = this.query.toLowerCase();
      if (!entity.title.toLowerCase().includes(query) && entity.key.toLowerCase() !== query) {
        return [false, rank, order];
      }
    }

    if (this.keys && this.keys.length > 0) {
      if (!this.keys.includes(entity.key)) {
        return [false, rank, order];
      }
    }

    if (this.typeId && this.typeId.length > 0) {
      if (!this.typeId.includes(entity.typeId)) {
        return [false, rank, order];
      }
    }

    if (this.assigneeId && this.assigneeId.length > 0) {
      if (!this.assigneeId.includes(entity.assigneeId ?? null)) {
        return [false, rank, order];
      }
    }

    return [true, rank, order];
  }

  public toString(): string {
    return [
      this.type,
      this.projectId ?? '',
      this.sprintId ?? '',
      this.statusId?.sort().join(',') ?? '',
      this.statusCategory?.sort().join(',') ?? '',
      this.query ?? '',
      this.key ?? '',
      this.keys ? `[${this.keys.sort().join(',')}]` : '',
      this.typeId?.join(',') ?? '', // FIXME: sorting this causes an infinite render loop ??
      this.assigneeId?.sort().join(',') ?? '',
      this.orderBy ?? '',
      this.limit ?? '',
    ].join('|');
  }
}
export type ITaskFilter = PublicPropertiesOf<TaskFilter>;
export function isTaskFilter(filter: IEntityFilter): filter is TaskFilter {
  return filter.type === EntityType.Task;
}
