import {mergeAttributes, Node} from '@tiptap/core';

enum PanelType {
  Info = 'info',
  Note = 'note',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export interface PanelNodeAttributes {
  panelType: PanelType;
}

export interface PanelOptions {
  HTMLAttributes: Record<string, any>;
}

/**
 * This extension is for viewing the Jira panel node type
 * We currently don't plan to use this or allow the user to create it inside Momentum
 */
export const Panel = Node.create<PanelOptions, PanelNodeAttributes>({
  name: 'panel',

  inline: false,
  group: 'block',
  content: 'paragraph*',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      panelType: {
        default: PanelType.Info,
        parseHTML: (element) => element.getAttribute('data-panel-type'),
        renderHTML: (attributes) => ({
          'data-panel-type': attributes.panelType,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({HTMLAttributes, node}) {
    return [
      'div',
      mergeAttributes({'data-type': this.name}, this.options.HTMLAttributes, HTMLAttributes, {
        'data-panel-type': node.attrs?.panelType ?? PanelType.Info,
      }),
      0,
    ];
  },
});
