import BlockquoteIcon from '@/assets/text-editor/blockquote.svg?react';
import BulletedListIcon from '@/assets/text-editor/bulleted-list.svg?react';
import Code2Icon from '@/assets/text-editor/code-block.svg?react';
import Heading1Icon from '@/assets/text-editor/heading-1.svg?react';
import Heading2Icon from '@/assets/text-editor/heading-2.svg?react';
import Heading3Icon from '@/assets/text-editor/heading-3.svg?react';
import Heading4Icon from '@/assets/text-editor/heading-4.svg?react';
import Heading5Icon from '@/assets/text-editor/heading-5.svg?react';
import Heading6Icon from '@/assets/text-editor/heading-6.svg?react';
import HorizontalRuleIcon from '@/assets/text-editor/horizontal-rule.svg?react';
import ALargeSmallIcon from '@/assets/text-editor/no-heading.svg?react';
import NumberedListIcon from '@/assets/text-editor/numbered-list.svg?react';
import {Popover} from '@/design-system';
import {MenuItem} from '@/design-system/MenuItem';
import type {Level} from '@tiptap/extension-heading';
import type {Editor} from '@tiptap/react';
import {ChevronDownIcon} from 'lucide-react';
import {useCallback, useMemo} from 'react';
import {Button, Menu, MenuTrigger} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props {
  editor: Editor | null;
  className?: string;
}

const styles = tv({
  slots: {
    base: 'border-b-gray-1 sticky top-0 z-10 flex items-center gap-1 bg-white px-4 py-0.5 text-black/80 shadow',
    button: 'flex items-center gap-4 rounded-sm p-1 text-sm hover:bg-gray-50',
    icon: 'h-5 w-5',
  },
  variants: {
    active: {
      true: {
        button: 'bg-purple-200 hover:bg-purple-100',
      },
    },
    disabled: {
      true: {
        button: 'opacity-30 hover:bg-white',
      },
    },
  },
});

export const ContentMenu: React.FC<Props> = ({editor, className}) => {
  const {base, button, icon} = styles();
  const actions = useMemo(
    () => ({
      toggleOrderedList: () => editor?.chain().focus().toggleOrderedList().run(),
      toggleBulletList: () => editor?.chain().focus().toggleBulletList().run(),
      toggleBlockquote: () => editor?.chain().focus().toggleBlockquote().run(),
      toggleCodeBlock: () => editor?.chain().focus().toggleCodeBlock().run(),
      toggleHorizontalRule: () => editor?.chain().focus().setHorizontalRule().run(),
    }),
    [editor],
  );

  return (
    <div className={twMerge(base(), className)} tabIndex={-1} onMouseDown={(e) => e.stopPropagation()}>
      <HeadingButton editor={editor} />
      <Button onPress={actions.toggleOrderedList} className={button({active: editor?.isActive('orderedList')})}>
        <NumberedListIcon className={icon()} />
      </Button>
      <Button onPress={actions.toggleBulletList} className={button({active: editor?.isActive('bulletList')})}>
        <BulletedListIcon className={icon()} />
      </Button>
      <Button onPress={actions.toggleBlockquote} className={button({active: editor?.isActive('blockquote')})}>
        <BlockquoteIcon className={icon()} />
      </Button>
      <Button onPress={actions.toggleCodeBlock} className={button({active: editor?.isActive('codeBlock')})}>
        <Code2Icon className={icon()} />
      </Button>
      <Divider />
      <Button onPress={actions.toggleHorizontalRule} className={button({active: editor?.isActive('horizontalRule')})}>
        <HorizontalRuleIcon className={icon()} />
      </Button>
    </div>
  );
};

function Divider() {
  return <div className="border-gray-1 mx-1 h-4 border-l" />;
}

const headingIcon = [
  ALargeSmallIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  Heading4Icon,
  Heading5Icon,
  Heading6Icon,
];

function HeadingButton({editor}: {editor: Editor | null}) {
  let level = 0;

  if (editor?.isActive('heading')) {
    for (let i = 1; i < headingIcon.length; i++) {
      if (editor.isActive('heading', {level: i})) {
        level = i;
        break;
      }
    }
  }

  const {button, icon} = styles();
  const Icon = headingIcon[level];
  return (
    <MenuTrigger aria-label="Heading">
      <Button className={twMerge(button({active: editor?.isActive('heading')}), 'flex items-center gap-1')}>
        <Icon className={icon()} />
        <ChevronDownIcon className="-ml-1 h-3 w-3" />
      </Button>
      <Popover placement="bottom" className="rounded-md">
        <HeadingMenu editor={editor} currentLevel={level} />
      </Popover>
    </MenuTrigger>
  );
}

const HEADING_NAMES = ['Normal Text', 'Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5', 'Heading 6'];

function HeadingMenu({editor, currentLevel}: {editor: Editor | null; currentLevel: number}) {
  const {icon} = styles();
  const onAction = useCallback(
    (level: Level | 0) => {
      if (level === 0) {
        editor?.chain().focus().setParagraph().run();
      } else {
        editor?.chain().focus().setHeading({level}).run();
      }
    },
    [editor, currentLevel],
  );

  return (
    <Menu className="flex flex-col gap-1 py-2">
      {headingIcon.map((Icon, i) => (
        <MenuItem key={i} onAction={() => onAction(i as Level)} isCurrent={currentLevel === i} className="gap-4">
          <Icon className={icon()} />
          {HEADING_NAMES[i]}
        </MenuItem>
      ))}
    </Menu>
  );
}
