import type {Section} from '@/design-system/section-scroller/SectionScroller';
import {useContext, useEffect, useRef} from 'react';
import {SectionScrollerContext} from './SectionScrollerContext';

interface Props extends Omit<Section, 'element'> {
  children: React.ReactNode;
  className?: string;
}

export function ScrollSection({children, className, ...section}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const sectionScroller = useContext(SectionScrollerContext);

  useEffect(() => {
    if (ref.current) {
      sectionScroller.addSection({...section, element: ref.current});
    } else {
      sectionScroller.removeSection(section.id);
    }
  }, [ref.current, section.id, section.rank, section.icon, section.tooltip]);

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
}
