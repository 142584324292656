export const debounce = <T extends (...args: never[]) => unknown>(func: T, wait: number, limit: number = 0) => {
  let timeout: number | undefined;
  let start: number | null = null;
  const fn = (...args: Parameters<T>) => {
    if (timeout !== undefined) {
      clearTimeout(timeout);
    }
    start ??= new Date().getTime();
    if (limit > 0 && new Date().getTime() - start > limit) {
      start = null;
      func(...args);
    } else {
      timeout = (typeof window === 'undefined' ? self : window).setTimeout(() => {
        start = null;
        func(...args);
      }, wait);
    }
  };

  fn.cancel = () => {
    if (timeout !== undefined) {
      clearTimeout(timeout);
      timeout = undefined;
    }
  };
  return fn;
};
