export interface Wip {
  id: number;
  createdAt: string;
  updatedAt: string;
  accountId: number;
  actorId?: number;
  taskId?: number;
  type: WipType;
  body: string;
}

export enum WipType {
  Comment = 'Comment',
}
