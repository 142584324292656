import {Button} from '@/design-system';
import type {ScrollViewState} from '@/design-system/stores/ScrollViewStore';
import {ScrollViewContext} from '@/design-system/stores/ScrollViewStore';
import {debounce} from '@shared/lib/debounce';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {tv} from 'tailwind-variants';
import {SectionScrollerContext} from './SectionScrollerContext';

export interface Section {
  id: string;
  rank: number;
  element: HTMLElement;
  icon: React.ReactNode;
  tooltip?: string;
  targetPosition?: 'top' | 'bottom';
}

export interface SectionScrollerInterface {
  addSection: (section: Section) => void;
  removeSection: (id: string) => void;
}

const styles = tv({
  slots: {
    wrapper: 'sticky top-2 z-20 flex h-0 w-full justify-end',
    container:
      'absolute right-3 w-7 overflow-hidden rounded-full border border-gray-800/20 bg-white shadow-md transition-all duration-100',
    button:
      'flex w-full items-center justify-center rounded-none px-1.5 py-0.5 transition-colors duration-100 first:pt-1 last:pb-1 hover:bg-pink-500/20',
  },
  variants: {
    visible: {
      true: {container: 'scale-100 opacity-100'},
      false: {container: 'scale-90 opacity-0'},
    },
    selected: {
      true: {button: 'bg-pink-500 text-white hover:bg-pink-500'},
    },
  },
});

export function SectionScroller({children}: {children: React.ReactNode}) {
  const scrollContext = useContext(ScrollViewContext);
  const [sections, setSections] = useState<Section[]>([]);
  const [selectedSectionId, setSelectedSectionId] = useState<string | null>(null);
  const canScroll = scrollContext!.use(
    (s) => {
      if (sections.length < 2 || !s.state.scrollViewHeight || s.state.scrollMax < 20) return false;
      const firstSectionHeight = sections[0]?.element.clientHeight ?? 0;
      const lastSectionHeight = sections[sections.length - 1]?.element.clientHeight ?? 0;
      return firstSectionHeight * 0.7 < s.state.scrollMax || lastSectionHeight * 0.7 < s.state.scrollMax;
    },
    [sections],
  );

  const contextInterface = useMemo(() => {
    return {
      addSection: (section: Section) => {
        setSections((prev) => [...prev.filter((s) => s.id !== section.id), section].sort((a, b) => a.rank - b.rank));
      },
      removeSection: (id: string) => {
        setSections((prev) => prev.filter((s) => s.id !== id));
      },
    };
  }, []);

  const handleScroll = useCallback(
    (state: ScrollViewState) => {
      // update which section is currently selected
      const scrollTop = state.scrollTop ?? 0;

      // Ensure top/bottom are selected when scrolled to the nearly top/bottom
      if (scrollTop < 5) {
        setSelectedSectionId(sections[0]?.id ?? null);
        return;
      } else if (scrollTop > state.scrollMax - 5) {
        setSelectedSectionId(sections[sections.length - 1]?.id ?? null);
        return;
      }

      const viewportHeight = state.scrollView?.clientHeight ?? 0;
      // this is the region we're interested in for determining which section is currently selected
      const viewBox = {
        top: scrollTop + viewportHeight * 0.15,
        bottom: scrollTop + viewportHeight * 0.3,
      };

      const section = sections.find((s) => {
        const top = s.element.offsetTop;
        const bottom = top + s.element.clientHeight;
        return top < viewBox.bottom && bottom > viewBox.top;
      });

      if (section) {
        setSelectedSectionId(section.id);
      }
    },
    [sections],
  );

  useEffect(() => {
    if (!scrollContext) return;
    const debounced = debounce(handleScroll, 50, 100);
    const unsub = scrollContext.on('mutate', debounced);
    handleScroll(scrollContext.state);
    return () => {
      debounced.cancel();
      unsub?.();
    };
  }, [scrollContext, handleScroll]);

  const scrollTo = useCallback(
    (id: string) => {
      const section = sections.find((s) => s.id === id);
      if (section) {
        section.element.scrollIntoView({
          behavior: 'smooth',
          block: section.targetPosition == 'bottom' ? 'end' : 'start',
        });
      }
    },
    [sections],
  );

  const {container, button, wrapper} = styles({visible: canScroll});

  return (
    <SectionScrollerContext.Provider value={contextInterface}>
      <div className={wrapper()}>
        <div className={container()}>
          {sections.map((section) => (
            <Button
              key={section.id}
              seamless
              className={button({selected: section.id === selectedSectionId})}
              tooltip={section.tooltip}
              tooltipPlacement="left"
              onPress={() => scrollTo(section.id)}
            >
              {section.icon}
            </Button>
          ))}
        </div>
      </div>
      {children}
    </SectionScrollerContext.Provider>
  );
}
