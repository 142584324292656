import React from 'react';
import {twMerge} from 'tailwind-merge';
import {imageStore} from '../stores/imageStore';

interface ImageProps {
  id?: number;
  alt?: string;
  fallback?: string;
  className?: string;
}

export const Image: React.FC<ImageProps> = ({id, alt, fallback, className}) => {
  const image = imageStore.use((s) => s.getById(id), [id]);

  if (image) {
    return <img src={image?.url ?? fallback} alt={alt} className={twMerge('h-full w-full', className)} />;
  } else {
    return <span className={twMerge('inline-block h-full w-full rounded-sm bg-gray-200', className)} />;
  }
};
