import {TaskLabel} from '@/components/task/TaskLabel';
import type {MentionNodeAttrs} from '@/text-editor/extensions/mentions/Mention';
import {EntityType} from '@shared/EntityType';
import type {Node as ProseMirrorNode} from '@tiptap/pm/model';
import {NodeViewWrapper} from '@tiptap/react';

export function MentionRenderer({node, HTMLAttributes}: {node: ProseMirrorNode; HTMLAttributes: Record<string, any>}) {
  const attrs = node.attrs as MentionNodeAttrs;
  return (
    <NodeViewWrapper {...HTMLAttributes} className="inline-block">
      {attrs.entity === EntityType.Task ? <TaskRenderer attrs={attrs} /> : <ActorRenderer attrs={attrs} />}
    </NodeViewWrapper>
  );
}

function ActorRenderer({attrs}: {attrs: MentionNodeAttrs}) {
  return <span className="font-semibold text-pink-600">@{attrs.label}</span>;
}

function TaskRenderer({attrs}: {attrs: MentionNodeAttrs}) {
  return (
    <div className="mx-0.5 mb-0.5 inline-flex align-middle">
      <TaskLabel id={+attrs.id!} taskKey={attrs.key} fallbackText={attrs.label} linkToTask />
    </div>
  );
}
