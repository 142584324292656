import {ActorAvatar} from '@/components/actor/ActorAvatar';
import {NonNullable} from '@/components/lib/NonNullable';
import {TaskLabel} from '@/components/task/TaskLabel';
import {RelativeTime} from '@/components/time/RelativeTime';
import {Skeleton, Spacer} from '@/design-system';
import {commentStore} from '@/stores/comment';
import {MOMENTUM_TIPTAP_EXTENSIONS} from '@/text-editor/useMoEditor';
import {EntityType} from '@shared/EntityType';
import {isIdEphemeral} from '@shared/lib/isIdEphemeral';
import type {Comment} from '@shared/models/Comment';
import {DocumentFormat} from '@shared/models/Task';
import {generateHTML} from '@tiptap/core';
import {ElementType} from 'domelementtype';
import type {HTMLReactParserOptions} from 'html-react-parser';
import parse from 'html-react-parser';
import React, {useEffect, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  commentId: number;
  onLoaded?: () => void;
}

const styles = tv({
  slots: {
    container:
      'w-fill flex min-h-24 flex-col justify-between rounded-md bg-white p-5 shadow transition-opacity animate-in fade-in',
  },
  variants: {
    n: {
      true: {
        container: 'min-h-0',
      },
    },
    isEphemeral: {
      true: {
        container: 'opacity-50',
      },
      false: {
        container: 'opacity-100',
      },
    },
  },
});

export const CommentView = React.forwardRef<HTMLDivElement, Props>(({commentId, onLoaded, ...props}, ref) => {
  const comment = commentStore.use((s) => s.getById(commentId), [commentId]);
  const {container} = styles({n: comment === null, isEphemeral: isIdEphemeral(commentId)});

  useEffect(() => {
    comment && onLoaded?.();
  }, [comment, onLoaded]);

  return (
    <div {...props} className={twMerge('mx-[-1.25rem] py-1', props.className)}>
      <div ref={ref} className={container()}>
        <NonNullable value={comment} fallback={<CommentSkeleton />} nullFallback={<CommentNotFound />}>
          {(comment) => (
            <>
              <CommentHeader comment={comment} />
              <CommentBody comment={comment} />
            </>
          )}
        </NonNullable>
      </div>
    </div>
  );
});

CommentView.displayName = 'CommentView';

const CommentHeader: React.FC<{comment: Comment}> = ({comment}) => {
  return (
    <div className="flex flex-row items-center justify-between">
      <ActorAvatar actorId={comment.authorId} size="sm" showName />
      <Spacer />
      <RelativeTime date={comment.createdAt} />
    </div>
  );
};

const renderComment = (comment: Comment) => {
  let html: string;
  if (comment.bodyFormat === DocumentFormat.Momentum) {
    const doc = JSON.parse(comment.body);
    html = generateHTML(doc, MOMENTUM_TIPTAP_EXTENSIONS);
  } else {
    console.warn('Comment not in Momentum format', comment);
    html = comment.renderedBody ?? '';
  }
  return parse(html, commentParseOptions);
};

const CommentBody: React.FC<{comment: Comment}> = ({comment}) => {
  const [children, setChildren] = useState(() => renderComment(comment));

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      setChildren(renderComment(comment));
    } else {
      isFirstRender.current = false;
    }
  }, [comment.renderedBody, comment.body]);

  return <div className="tiptap text-sm text-gray-700">{children}</div>;
};

const CommentSkeleton: React.FC = () => {
  return (
    <>
      <div className="flex flex-row items-center justify-between pb-5">
        <div className="flex flex-row items-center justify-between">
          <Skeleton className="h-6 w-6" />
          <Spacer />
          <Skeleton className="h-5 w-28" />
        </div>
        <Spacer />
        <Skeleton className="h-4 w-24" />
      </div>
      <div className="flex flex-col justify-between">
        <Skeleton className="h-4 w-2/3" />
        <Spacer />
        <Skeleton className="h-4 w-36" />
      </div>
    </>
  );
};

const CommentNotFound: React.FC = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-gray-500/50">Comment not found</div>
  );
};

const commentParseOptions: HTMLReactParserOptions = {
  replace: (node) => {
    if (
      node.type === ElementType.Tag &&
      node.attribs['data-type'] === 'mention' &&
      node.attribs['data-entity'] === EntityType.Task
    ) {
      return (
        <TaskLabel
          id={+node.attribs['data-id']}
          taskKey={node.attribs['data-key']}
          fallbackText={node.attribs['data-label']}
          className="mx-0.5 mb-0.5 inline-flex align-middle"
          linkToTask
        />
      );
    } else if (node.type === ElementType.Tag && node.attribs['contenteditable'] === 'false') {
      delete node.attribs['contenteditable'];
    }
  },
};
