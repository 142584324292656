import {dragStore} from '@/design-system/dnd/dragStore';
import {ScrollViewContext, ScrollViewStore} from '@/design-system/stores/ScrollViewStore';
import {mouseMonitor} from '@/lib/MouseMonitor';
import {isStateEqual} from '@shared/lib/isStateEqual';
import {forwardRef, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import type {positionValues, ScrollbarProps} from 'react-custom-scrollbars-2';
import Scrollbars from 'react-custom-scrollbars-2';
import {twMerge} from 'tailwind-merge';

export interface ScrollViewRenderProps {
  hasMoreTop: boolean;
  hasMoreBottom: boolean;
  hasMoreLeft: boolean;
  hasMoreRight: boolean;
}

const DEFAULT_RENDER_PROPS: ScrollViewRenderProps = {
  hasMoreTop: false,
  hasMoreBottom: false,
  hasMoreLeft: false,
  hasMoreRight: false,
};

interface Props extends Omit<ScrollbarProps, 'children'> {
  children: React.ReactNode | ((props: ScrollViewRenderProps) => React.ReactNode);
  dropTypes?: string[]; // note: cannot change after mount
}

export const ScrollView = forwardRef<HTMLDivElement, Props>(
  ({children, dropTypes = ['momentum/task'], ...props}, forwardedRef) => {
    const [renderProps, setRenderProps] = useState<ScrollViewRenderProps>(DEFAULT_RENDER_PROPS);
    const wasAtBottomRef = useRef(false);

    const onScrollFrame = useCallback(
      (values: positionValues) => {
        const newRenderProps: ScrollViewRenderProps = {
          hasMoreTop: values.scrollTop > 5,
          hasMoreBottom: values.scrollTop + values.clientHeight + 5 < values.scrollHeight,
          hasMoreLeft: values.scrollLeft > 5,
          hasMoreRight: values.scrollLeft + values.clientWidth + 5 < values.scrollWidth,
        };

        // Store whether we're at/near the bottom for use in the mutation/resize observer
        wasAtBottomRef.current =
          !(values.scrollTop + values.clientHeight + 40 < values.scrollHeight) &&
          values.scrollHeight > values.clientHeight;

        if (!isStateEqual(newRenderProps, renderProps)) {
          setRenderProps(newRenderProps);
        }
        props.onScrollFrame?.(values);
      },
      [renderProps],
    );

    const scrollViewStore = useMemo(() => new ScrollViewStore(), []);
    const scrollRef = useRef<Scrollbars>(null);
    useEffect(() => {
      const node: HTMLDivElement | null = scrollRef.current?.container.children[0] as HTMLDivElement | null;
      if (node) {
        node.classList.add('scroll-p-5');
        node.classList.add('z-0'); // create a new stacking context to ensure the content stays below the scrollbar
        setTimeout(() => scrollViewStore.setScrollView(node));
        if (forwardedRef) {
          if (typeof forwardedRef === 'function') {
            forwardedRef(node);
          } else {
            forwardedRef.current = node;
          }
        }
      }
      return () => {
        scrollViewStore.setScrollView(null);
      };
    }, [scrollRef.current?.container]);

    // ensure we update the render props when the dom changes / view resizes
    useEffect(() => {
      if (!scrollRef.current) return;

      let lastSize = {
        width: scrollRef.current.container.clientWidth,
        height: scrollRef.current.container.clientHeight,
      };
      const handler = (updates: ResizeObserverEntry[] | MutationRecord[]) => {
        if (updates.length === 0) return;
        const update = updates[0];
        // ignore initial resize events / unchanged size events
        if (update instanceof ResizeObserverEntry) {
          if (
            isApproximatelyEqual(update.contentRect.width, lastSize.width) &&
            isApproximatelyEqual(update.contentRect.height, lastSize.height)
          ) {
            return;
          }
          lastSize = {width: update.contentRect.width, height: update.contentRect.height};
        }
        if (!scrollRef.current) return;
        const values = scrollRef.current.getValues();

        // If we were at the bottom before, scroll to bottom after update
        if (wasAtBottomRef.current) {
          scrollRef.current?.scrollToBottom();
        }

        onScrollFrame(values);
      };

      const mutationObserver = new MutationObserver(handler);
      mutationObserver.observe(scrollRef.current.container, {subtree: true, childList: true});

      const resizeObserver = new ResizeObserver(handler);
      resizeObserver.observe(scrollRef.current.container);

      onScrollFrame(scrollRef.current.getValues());

      return () => {
        mutationObserver.disconnect();
        resizeObserver.disconnect();
      };
    }, [scrollRef.current, onScrollFrame]);

    useEffect(() => autoScrollWhenDragging(scrollRef.current, dropTypes), []);

    return (
      <ScrollViewContext.Provider value={scrollViewStore}>
        <Scrollbars
          autoHide
          {...props}
          onScrollFrame={onScrollFrame} // overflow-clip fixes an issue with the content shifting over by the right margin
          className={twMerge('!overflow-clip', renderProps.hasMoreBottom && 'gradient-mask-b', props.className)}
          ref={scrollRef}
          style={{overflowAnchor: 'auto'}}
        >
          {typeof children === 'function' ? children(renderProps) : children}
        </Scrollbars>
      </ScrollViewContext.Provider>
    );
  },
);

function autoScrollWhenDragging(scrollRef: Scrollbars | null, dropTypes: string[]) {
  let unsubDrag: () => void = () => void 0;
  let unsubDrop: () => void = () => void 0;
  let movement = {x: 0, y: 0};

  const handler = (isDragging: boolean) => {
    if (!isDragging || !dropTypes.some((t) => dragStore.state.dragTypes.includes(t))) {
      unsubDrag();
      unsubDrag = () => void 0;
      return;
    }
    let lastTime = performance.now();
    function frame(t: DOMHighResTimeStamp) {
      const dt = (t - lastTime) / 1000;
      const speed = 2000;
      lastTime = t;
      if (movement.x != 0 || movement.y != 0) {
        const el = scrollRef?.container.children[0] as HTMLDivElement | null;
        if (el) {
          el.scrollBy({left: movement.x * dt * speed, top: movement.y * dt * speed});
        }
      }

      if (dragStore.state.isDragging) {
        requestAnimationFrame(frame);
      }
    }
    requestAnimationFrame(frame);
  };

  unsubDrag = mouseMonitor.on('drag', (position) => {
    const el = scrollRef?.container.children[0] as HTMLDivElement | null;

    if (scrollRef && el) {
      // convert absolute position to relative position
      const rect = el.getBoundingClientRect();
      const insideContainer = position.x >= rect.left && position.x <= rect.right && position.y >= rect.top;
      if (!insideContainer) {
        movement.x = 0;
        movement.y = 0;
        return;
      }

      const xInset = Math.min(175, rect.width / 4);
      const yInset = Math.min(175, rect.height / 4);

      if (position.x < rect.left + xInset) {
        movement.x = (position.x - (rect.left + xInset)) / xInset;
      } else if (position.x > rect.right - xInset) {
        movement.x = (position.x - (rect.right - xInset)) / xInset;
      } else {
        movement.x = 0;
      }

      if (position.y < rect.top + yInset) {
        movement.y = (position.y - (rect.top + yInset)) / yInset;
      } else if (position.y > rect.bottom - yInset) {
        movement.y = (position.y - (rect.bottom - yInset)) / yInset;
      } else {
        movement.y = 0;
      }

      movement.x = Math.sign(movement.x) * Math.pow(Math.abs(movement.x), 3);
      movement.y = Math.sign(movement.y) * Math.pow(Math.abs(movement.y), 3);
    } else {
      movement.x = 0;
      movement.y = 0;
    }
  });

  unsubDrop = mouseMonitor.on('dragend', () => {
    movement.x = 0;
    movement.y = 0;
  });

  dragStore.selectAndSubscribe((s) => s.state.isDragging, handler);

  return () => {
    dragStore.unsubscribe(handler);
    unsubDrag();
    unsubDrop();
  };
}

function isApproximatelyEqual(a: number, b: number) {
  return Math.abs(a - b) < 2;
}
