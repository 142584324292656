import {actorDatumStore} from '@/stores/ActorDatum';
import {taskStore} from '@/stores/task';
import {taskTypeStore} from '@/stores/taskType';
import type {AnyEntity} from '@shared/EntityType';
import {EntityType} from '@shared/EntityType';
import {ActorDatumType} from '@shared/models/ActorDatum';
import {defaultFieldId, FieldType} from '@shared/models/FieldSchema';
import type {Mutation} from '@shared/models/Mutation';
import type {Task} from '@shared/models/Task';
import type {TaskLink} from '@shared/models/TaskLink';

export async function trackRecents(type: EntityType, entities: Partial<AnyEntity>[]) {
  if (type === EntityType.Task) {
    const tasks = (await taskStore.await((s) =>
      (entities as Task[]).filter((e) => e.id && +e.id > 0).map((t) => s.getById(t.id)),
    )) as Task[];
    const epics = tasks.filter((e) => taskTypeStore.getById(e.typeId)?.hierarchyLevel === 1);
    if (epics.length > 0) {
      actorDatumStore.addToRecents(
        ActorDatumType.RecentEpics,
        epics.map((e) => e.id!),
      );
    }
    actorDatumStore.addToRecents(
      ActorDatumType.RecentTasks,
      entities.map((e) => e.id!),
    );
  }
}

export async function trackRecentsByMutation(mutation: Mutation) {
  if (mutation.entity === EntityType.Task) {
    if (mutation.id && +mutation.id > 0) {
      taskStore
        .await((s) => s.getById(+mutation.id))
        .then((task) => {
          task && trackRecents(mutation.entity, [task]);
        });
    }
    if (mutation.operations.replace?.[defaultFieldId[FieldType.Parent]]) {
      const epicId = mutation.operations.replace[defaultFieldId[FieldType.Parent]].value;
      if (epicId && +epicId > 0) {
        actorDatumStore.addToRecents(ActorDatumType.RecentEpics, [+epicId]);
        actorDatumStore.addToRecents(ActorDatumType.RecentTasks, [+epicId]);
      }
    } else if (mutation.operations.append?.[defaultFieldId[FieldType.LinkedTasks]]) {
      const value = mutation.operations.append[defaultFieldId[FieldType.LinkedTasks]];
      try {
        const link = JSON.parse(value.value!) as TaskLink;
        await trackRecents(
          mutation.entity,
          [link.srcTaskId, link.dstTaskId].map((id) => ({id})),
        );
      } catch (e) {
        console.error('[App] Failed to parse linked tasks from mutation', value, e);
      }
    }
    if (mutation.operations.append?.[defaultFieldId[FieldType.Labels]]) {
      const value = mutation.operations.append[defaultFieldId[FieldType.Labels]];
      const labelIds = value.list ? value.list : [value.value!];
      actorDatumStore.addToRecents(
        ActorDatumType.RecentTags,
        labelIds.map((label) => `L|${label}`),
      );
    }
    if (mutation.operations.append?.[defaultFieldId[FieldType.Components]]) {
      const value = mutation.operations.append[defaultFieldId[FieldType.Components]];
      const componentIds = value.list ? value.list : [value.value!];
      actorDatumStore.addToRecents(
        ActorDatumType.RecentTags,
        componentIds.map((component) => `C|${component}`),
      );
    }
  }
}
