import {contextStore} from '@/stores/context';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import type {TaskType} from '@shared/models/TaskType';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '../lib/dev';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

class TaskTypeStore extends EntityStore<EntityStoreState<TaskType>, TaskType> {
  constructor(type: EntityType, state: EntityStoreState<TaskType>) {
    super(type, state);

    // preload and memoize some common data
    this.selectAndSubscribe(
      (s) => [
        s.getList(Filters.taskTypeFilter({projectId: contextStore.projectId}))?.map((t) => s.getById(t.id)),
        s.getList(Filters.taskTypeFilter({projectId: contextStore.projectId, hierarchyLevel: 0})),
        s.getList(Filters.taskTypeFilter({projectId: contextStore.projectId, hierarchyLevel: 1})),
      ],
      () => {},
    );
  }

  getHierarchy0Ids() {
    return this.getList(Filters.taskTypeFilter({projectId: contextStore.projectId, hierarchyLevel: 0}))?.map(
      (t) => t.id,
    );
  }

  getHierarchy1Ids() {
    return this.getList(Filters.taskTypeFilter({projectId: contextStore.projectId, hierarchyLevel: 1}))?.map(
      (t) => t.id,
    );
  }
}

export const taskTypeStore = persistThroughHotReload(
  'taskTypeStore',
  new TaskTypeStore(EntityType.TaskType, {
    byId: new Map(),
    byList: new Map(),
  }),
);

exposeInDev({taskTypeStore});
import.meta.hot?.accept(reloadOnHotUpdate);
