import {Worker} from '@/app-service-worker/Worker';
import {RightSide} from '@/components/backlog/RightSide';
import {MessageList} from '@/components/inbox/MessageList';
import {ScrollView} from '@/design-system/ScrollView';
import {inboxMessageStore} from '@/stores/inboxMessage';
import {taskReadStateStore} from '@/stores/taskReadState';
import {inboxStore} from '@/stores/ui/inboxStore';
import {EntityType} from '@shared/EntityType';
import React, {useCallback, useEffect} from 'react';

const Inbox: React.FC = () => {
  const {selectedId, taskId} = inboxStore.use((s) => s.state, []);
  const message = inboxMessageStore.use((s) => s.getById(selectedId), [selectedId]);
  const selectedTaskId = message?.taskId ?? taskId;

  useEffect(() => {
    inboxStore.onMount();
  }, []);

  const onMarkUnread = useCallback(async (id: number) => {
    if (id) {
      Worker.patchEntities(EntityType.InboxMessage, [{id, readAt: null, updatedAt: new Date().toISOString()}]);
      inboxStore.setSelectedId();
      const message = await inboxMessageStore.await((s) => s.getById(id));
      if (message?.createdAt && message.taskId) {
        const readState = await taskReadStateStore.await((s) => s.getById(message.taskId));
        if (readState == null || (readState.readTo && readState.readTo >= message.createdAt)) {
          const date = new Date(new Date(message.createdAt).getTime() - 1000);
          taskReadStateStore.patchReadAt(message.taskId, date.toISOString());
        }
      }
    }
  }, []);

  return (
    <div className="flex h-full flex-row">
      <div
        className="h-full w-1/3 min-w-64 border-r-2 border-[#B13982] border-opacity-65 bg-background-med"
        onMouseDown={() => inboxStore.setSelectedId()}
      >
        {/* <div className="flex flex-row justify-end px-4 py-2">
          <NotificationIconInterface />
        </div> */}
        <ScrollView>
          <MessageList onClick={inboxStore.setSelectedId} onMarkUnread={onMarkUnread} />
        </ScrollView>
      </div>
      <div className="flex h-full w-2/3 flex-col">
        <RightSide taskId={selectedTaskId} scrollToUnread={true} />
      </div>
    </div>
  );
};

export default Inbox;
