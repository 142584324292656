import {Worker} from '@/app-service-worker/Worker';
import {wipStore} from '@/stores/wip';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import type {Wip, WipType} from '@shared/models/Wip';
import type {WSEntityUpdateResult} from '@shared/webSocket/interface';
import {useRef} from 'react';

export interface WipInterface {
  wip: Wip | null;
  cancel: (canceled?: boolean) => void;
  canceled: boolean;
  setWip: (body: string | null) => void;
}

interface InternalWipInterface extends WipInterface {
  handleResult: (result: WSEntityUpdateResult) => void;
}

function updateWip(state: InternalWipInterface, body: string | null) {
  if (!body || state.canceled) {
    if (state.wip) {
      const wip = state.wip;
      state.wip = null;
      return Worker.deleteEntity(EntityType.Wip, wip.id.toString());
    }
    return Promise.resolve();
  }

  return Worker.mutateEntity({
    entity: EntityType.Wip,
    id: state.wip!.id.toString(),
    operations: {replace: {body: {value: body}}},
  }).then(state.handleResult);
}

function createWipInterface(wip: Wip | null, taskId: number, type: WipType): InternalWipInterface {
  const state: InternalWipInterface = {
    wip,
    canceled: false,
    cancel: (canceled = true) => {
      state.canceled = canceled;
      if (canceled && state.wip) {
        Worker.deleteEntity(EntityType.Wip, state.wip.id.toString());
        state.wip = null;
      }
    },
    setWip: (body: string | null) => {
      if (state.canceled) {
        console.error("WIP canceled, can't setWip");
        return;
      }
      if (state.wip && state.wip.body === body) return;
      if (state.wip || !body) {
        updateWip(state, body);
      } else {
        Worker.postEntity(EntityType.Wip, {body, taskId, type}).then(state.handleResult);
      }
    },
    handleResult: (result: WSEntityUpdateResult) => {
      const wip = result.entities?.find((e) => e.type === EntityType.Wip)?.entities[0] as Wip | undefined;
      if (wip && state.canceled) {
        Worker.deleteEntity(EntityType.Wip, wip.id.toString());
        state.wip = null;
      } else if (wip && (!state.wip || state.wip.updatedAt <= wip.updatedAt!)) {
        state.wip = wip;
      }
    },
  };

  return state;
}

/**
 * Get an interface to manage a WIP.
 * @param type Note: cannot be changed after initial render
 * @param taskId Note: cannot be changed after initial render
 *
 * Note: there is a lot of specific handling for canceling/deleting a WIP.
 * An unhandled edge case is related to offline usage: when we add support for offline,
 * we need to use and manage the requests from the draft queue and not allow a deleted draft to be posted
 * when we come back online.
 */
export const useWip = (type: WipType, taskId: number): WipInterface => {
  const wip = wipStore.use(
    (s) => s.getList(Filters.wipFilter({taskId, wipType: type}))?.map((item) => s.getById(item.id))[0],
  );

  const interfaceRef = useRef<InternalWipInterface>();
  interfaceRef.current ??= createWipInterface(wip ?? null, taskId, type);
  if (interfaceRef.current.wip?.taskId !== taskId) {
    interfaceRef.current = createWipInterface(wip ?? null, taskId, type);
  }

  if (interfaceRef.current.wip !== wip && !interfaceRef.current.canceled) {
    if (
      !interfaceRef.current.wip?.updatedAt ||
      !wip?.updatedAt ||
      interfaceRef.current.wip.updatedAt <= wip.updatedAt
    ) {
      interfaceRef.current.wip = wip ?? null;
    }
  }

  return interfaceRef.current;
};
