import {trackRecents, trackRecentsByMutation} from '@/app-service-worker/trackRecents';
import {persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import {authStore} from '@/stores/auth';
import type {AnyEntity, EntityType} from '@shared/EntityType';
import {DevLog, devLog} from '@shared/lib/devLog';
import {generateRequestId} from '@shared/lib/requestId';
import type {Mutation} from '@shared/models/Mutation';
import type {SWCloseWindow, SWPing, SWRegisterWindow, SWUpdate} from '@shared/serviceWorker/interface';
import {
  isBatchSubscriptionUpdate,
  isReadyMessage,
  isUpdateMessage,
  SWMessageType,
} from '@shared/serviceWorker/interface';
import {TtlCache} from '@shared/TtlCache';
import type {
  WSEntityUpdateResult,
  WSMutateEntityRequest,
  WSPatchEntitiesRequest,
  WSPostEntityRequest,
  WSPutEntitiesRequest,
  WSRequest,
  WSTaskChangeRank,
  WSTaskMoveRequest,
  WSTaskTransitionStatus,
} from '@shared/webSocket/interface';
import {isWSResultResponse, WSMessageType} from '@shared/webSocket/interface';
import {startApp} from '../main';
import {subscriptions} from '../stores/lib/subscriptions';
import {createSubscriptionRelay} from './subscriptionRelay';

const pendingRequests = new TtlCache<string, (result: WSEntityUpdateResult) => void>(30_000);

export const Worker = persistThroughHotReload('Worker', {
  port: null! as MessagePort,
  pingInterval: null! as number,
  windowId: null! as number,
  post(request: WSRequest) {
    const requestId = generateRequestId(authStore.actorId, Worker.windowId);
    this.port.postMessage({...request, requestId});
    devLog(DevLog.AppWorker, 'Post', {...request, requestId});
    return new Promise((resolve: (result: WSEntityUpdateResult) => void, reject: (reason: any) => void) => {
      pendingRequests.put(requestId, resolve, undefined, reject);
    });
  },
  patchEntities(type: EntityType, entities: Partial<AnyEntity & {id: number}>[]) {
    devLog(DevLog.AppWorker, 'patchEntities', type, entities);
    const result = this.post({type: WSMessageType.PatchEntities, message: {type, entities}} as WSPatchEntitiesRequest);
    trackRecents(type, entities);
    return result;
  },
  putEntities(type: EntityType, entities: AnyEntity[]) {
    devLog(DevLog.AppWorker, 'putEntities', type, entities);
    const result = this.post({type: WSMessageType.PutEntities, message: {type, entities}} as WSPutEntitiesRequest);
    trackRecents(type, entities);
    return result;
  },
  postEntity(type: EntityType, entity: Partial<AnyEntity>) {
    devLog(DevLog.AppWorker, 'postEntity', type, entity);
    const result = this.post({type: WSMessageType.PostEntity, message: {type, entity}} as WSPostEntityRequest);
    trackRecents(type, [entity]);
    return result;
  },
  mutateEntity(mutation: Mutation) {
    devLog(DevLog.AppWorker, 'mutateEntity', mutation);
    const result = this.post({type: WSMessageType.MutateEntity, message: {mutation}} as WSMutateEntityRequest);
    trackRecentsByMutation(mutation);
    return result;
  },
  deleteEntity(type: EntityType, id: string) {
    devLog(DevLog.AppWorker, 'deleteEntity', type, id);
    const mutation: Mutation = {
      entity: type,
      id,
      operations: {delete: ['id']},
    };
    return this.post({type: WSMessageType.MutateEntity, message: {mutation}} as WSMutateEntityRequest);
  },
  moveTasks(changes: WSTaskChangeRank | WSTaskTransitionStatus) {
    devLog(DevLog.AppWorker, 'moveTasks', changes);
    return this.post({
      type: WSMessageType.TaskMove,
      message: changes,
    } as WSTaskMoveRequest);
  },
  handleMessage(event: MessageEvent) {
    const data = event.data;
    if (isBatchSubscriptionUpdate(data)) {
      devLog(DevLog.AppWorker, 'Batch entities update', data);
      for (const update of data.updates) {
        subscriptions.update(update);
      }
    } else if (isWSResultResponse(data)) {
      // TODO: handle unhandled errors
      devLog(DevLog.AppWorker, 'Result', data);
    } else {
      console.error('[App] Unknown message', data);
    }

    if (typeof data.requestId === 'string') {
      pendingRequests.getAndRemove(data.requestId)?.(data);
    }
  },
  performUpdate() {
    this.port.postMessage({type: SWMessageType.Update} as SWUpdate);
  },
});

if ('serviceWorker' in navigator) {
  const channel = new MessageChannel();
  const readyListener = (event: MessageEvent) => {
    const data = event.data;
    if (isReadyMessage(data)) {
      devLog(DevLog.AppWorker, 'Service Worker reports ready', data);
      if (data.buildVersion !== VITE_BUILD_VERSION) {
        console.error('[App] Service worker build version mismatch', data.buildVersion, VITE_BUILD_VERSION);
      }
      Worker.windowId = data.windowId;
      channel.port1.removeEventListener('message', readyListener);
      channel.port1.addEventListener('message', Worker.handleMessage);
      createSubscriptionRelay(subscriptions, channel.port1);
      Worker.port = channel.port1;
      if (DEV) {
        import('@/debug/debugStore').then(({debugStore}) => {
          debugStore.init();
          startApp();
        });
      } else {
        startApp();
      }
      clearInterval(Worker.pingInterval);
      Worker.pingInterval = setInterval(() => {
        navigator.serviceWorker.controller?.postMessage({type: SWMessageType.Ping} as SWPing);
      }, 20000) as never;
    } else if (isUpdateMessage(data)) {
      setTimeout(() => window.location.reload(), 0);
    } else {
      console.error('[App] Unknown message', data);
    }
  };
  channel.port1.addEventListener('message', readyListener);
  channel.port1.addEventListener('messageerror', (event) => {
    console.error('[App] Window port message error', event);
  });
  channel.port1.addEventListener('close', () => {
    console.error('[App] Window port closed');
  });
  channel.port1.addEventListener('error', (event) => {
    console.error('[App] Window port error', event);
  });

  window.addEventListener('load', function () {
    const start = () => {
      navigator.serviceWorker.ready.then((registration) => {
        devLog(DevLog.AppWorker, 'ServiceWorker reports ready', registration);
        const jwt = authStore.token;
        if (!jwt) {
          // TODO: this is a hack, we need a way to render routes that do not require auth
          startApp();
          return;
        }
        navigator.serviceWorker.controller?.postMessage(
          {
            type: SWMessageType.RegisterWindow,
            port: channel.port2,
            jwt,
          } as SWRegisterWindow,
          [channel.port2],
        );
        channel.port1.start();
        console.log('[App] Window port started');
      });
    };

    // TODO: resubscribe instead of reloading
    let refreshing = false;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (!refreshing) {
        window.location.reload();
        refreshing = true;
      }
    });

    navigator.serviceWorker
      .register(DEV ? '/service-worker.js?dev' : '/service-worker.js?prod')
      .then((registration): void => {
        let started = false;
        function waitToStart() {
          if (registration.waiting && registration.active) {
            registration.active?.postMessage({type: SWMessageType.Update} as SWUpdate);
          } else if (registration.installing) {
            registration.installing?.addEventListener('statechange', (event) => {
              devLog(DevLog.AppWorker, 'ServiceWorker installing', event.target, registration.active);
              if (registration.active) {
                !started && start();
                started = true;
              }
            });
          } else if (registration.active) {
            start();
          } else {
            registration.update();
            setTimeout(waitToStart, 500);
          }
        }
        waitToStart();
        devLog(DevLog.AppWorker, 'service worker registered', registration);
      })
      .catch((err) => console.error('[AppWorker] service worker not registered', err));
  });

  window.addEventListener('beforeunload', function () {
    channel.port1.postMessage({type: SWMessageType.CloseWindow} as SWCloseWindow);
    channel.port1.close();
    channel.port2.close();
  });
}

import.meta.hot?.accept(reloadOnHotUpdate);
