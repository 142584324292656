import {persistThroughHotReload} from '@/lib/dev';
import type {EntityStoreState} from '@/stores/lib/EntityStore';
import {EntityStore} from '@/stores/lib/EntityStore';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import type {Board} from '@shared/models/Board';

export const boardStore = persistThroughHotReload(
  'boardStore',
  new EntityStore<EntityStoreState<Board>, Board>(EntityType.Board, {
    byId: new Map(),
    byList: new Map(),
  }).extend(function () {
    return {
      getFirstByProjectId: (projectId: number) => {
        const list = this.getList(Filters.boardFilter({projectId}));
        if (!list) return undefined;
        if (list.length === 0) return null;
        return this.getById(list[0].id);
      },
    };
  }),
);
