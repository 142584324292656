import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import {contextStore} from '@/stores/context';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import type {Component} from '@shared/models/Component';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

class ComponentStore extends EntityStore<EntityStoreState<Component>, Component> {
  constructor() {
    super(EntityType.Component, {byId: new Map(), byList: new Map()});

    this.selectAndSubscribe(
      (s) => {
        return s.getList(Filters.componentFilter({projectId: contextStore.projectId}))?.map((c) => s.getById(c.id));
      },
      () => {},
    );
  }
}

export const componentStore = persistThroughHotReload('componentStore', new ComponentStore());

exposeInDev({componentStore});
import.meta.hot?.accept(reloadOnHotUpdate);
