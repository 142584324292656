import {actorDatumStore} from '@/stores/ActorDatum';
import {contextStore} from '@/stores/context';
import {taskStore} from '@/stores/task';
import {taskTypeStore} from '@/stores/taskType';
import type {OmitType} from '@shared/filters/Filters';
import {Filters} from '@shared/filters/Filters';
import type {ITaskFilter} from '@shared/filters/TaskFilter';
import {TaskFilter, TaskFilterOrder} from '@shared/filters/TaskFilter';
import {debounce} from '@shared/lib/debounce';
import {isStateEqual} from '@shared/lib/isStateEqual';
import {ActorDatumType} from '@shared/models/ActorDatum';
import type {EntityListItem} from '@shared/models/FilteredEntityList';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

export enum TaskSuggestionType {
  Epic = 'epic',
  TaskOrEpic = 'taskOrEpic',
}

export interface TaskSuggestionsOptions {
  baseFilter?: OmitType<ITaskFilter>;
  excludeTaskIds?: number[];
  type?: TaskSuggestionType;
  excludeTaskIdsFromRecents?: number[];
}

export function useTaskSuggestions({
  baseFilter,
  excludeTaskIds = [],
  type = TaskSuggestionType.TaskOrEpic,
  excludeTaskIdsFromRecents = [],
}: TaskSuggestionsOptions) {
  const [debouncedSearchString, setDebouncedSearchString] = useState('');
  const setSearchString = useCallback(debounce(setDebouncedSearchString, 250), []);
  useEffect(() => setSearchString.cancel, [debouncedSearchString]);

  const unsubscribe = useRef<() => void>();

  const recentTaskIds = actorDatumStore.use((s) =>
    s.getRecents(type === TaskSuggestionType.Epic ? ActorDatumType.RecentEpics : ActorDatumType.RecentTasks),
  );

  const recentTaskList = taskStore.use(
    (s) =>
      recentTaskIds
        ?.map((id) => s.getById(id))
        ?.filter(Boolean)
        ?.map(
          (t) =>
            ({
              ...TaskFilter.toListItem(t!),
              icon: taskTypeStore.getById(t!.typeId)?.iconImageId,
              key: t!.key,
            }) as EntityListItem,
        ),
    [recentTaskIds],
  );

  // allow the excludes arrays to change without invalidating the memo if the contents are the same
  const excludes = [excludeTaskIds, excludeTaskIdsFromRecents];
  const excludesDepRef = useRef(excludes);
  if (!isStateEqual(excludesDepRef.current, excludes)) {
    excludesDepRef.current = excludes;
  }
  const deps = [recentTaskList, debouncedSearchString, excludesDepRef.current];

  const getSuggestions = useMemo(() => {
    return (value: string, resolve: (suggestions: EntityListItem[]) => void) => {
      setSearchString(value.trim().toLowerCase());
      unsubscribe.current?.();
      unsubscribe.current = taskStore.selectAndSubscribe((s) => {
        let searchString = value.trim().toLowerCase();

        // Use actor-based list of recent tasks first
        // append recently updated tasks (current project)
        // append global (any project) search results if there is a search query
        // then filter, unique, and limit to 20
        let list = (recentTaskList ?? [])
          .concat(
            s.getList(
              Filters.taskFilter({
                typeId: type === TaskSuggestionType.Epic ? taskTypeStore.getHierarchy1Ids() : undefined,
                orderBy: TaskFilterOrder.RecentlyUpdated,
                ...baseFilter,
                projectId: contextStore.projectId,
                limit: 20,
              }),
            ) ?? [],
          )
          .filter((item) => !excludeTaskIdsFromRecents?.some((id) => id === item.id))
          .concat(
            debouncedSearchString && debouncedSearchString.length > 1
              ? (s.getList(
                  Filters.taskFilter({
                    typeId: type === TaskSuggestionType.Epic ? taskTypeStore.getHierarchy1Ids() : undefined,
                    orderBy: TaskFilterOrder.RecentlyUpdated,
                    ...baseFilter,
                    query: debouncedSearchString,
                    limit: 20,
                  }),
                ) ?? [])
              : [],
          )
          .filter(
            (item) =>
              (!searchString ||
                item.text?.toLowerCase().includes(searchString) ||
                item.key?.toLowerCase().startsWith(searchString)) &&
              !excludeTaskIds?.some((id) => id === item.id),
          )
          .filter((item, index, self) => self.findIndex((t) => t.id === item.id) === index) // unique by id
          .slice(0, 20); // limit to 20

        return list;
      }, resolve);
    };
  }, deps);

  useEffect(() => () => unsubscribe.current?.(), deps);

  return getSuggestions;
}
