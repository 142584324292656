import {TaskView} from '@/components/task/TaskView';
import {Suspense} from 'react';

interface Props {
  taskId?: number;
  scrollToUnread?: boolean;
}

export function RightSide({taskId, scrollToUnread}: Props) {
  if (taskId) {
    return (
      <Suspense fallback={<div className="h-full w-full" />}>
        <TaskView taskId={taskId} scrollToUnread={scrollToUnread} />
      </Suspense>
    );
  }
  return <div className="h-full w-full" />;
}
