import {ActorAvatar} from '@/components/actor/ActorAvatar';
import {CommentView} from '@/components/comment/CommentView';
import {StatusLabel} from '@/components/task/StatusLabel';
import {Tag} from '@/components/task/Tag';
import {TaskLabel} from '@/components/task/TaskLabel';
import {RelativeTime} from '@/components/time/RelativeTime';
import {Skeleton, Spacer} from '@/design-system';
import {actorStore} from '@/stores/actor';
import {authStore} from '@/stores/auth';
import {fieldSchemaStore} from '@/stores/fieldSchema';
import {TaskHistoryContext} from '@/stores/ui/taskHistoryUiStore';
import type {FieldSchema} from '@shared/models/FieldSchema';
import {FieldType} from '@shared/models/FieldSchema';
import type {TaskHistoryListItem} from '@shared/models/FilteredEntityList';
import React, {Suspense, memo, useCallback, useContext, useEffect, useRef} from 'react';
import {twMerge} from 'tailwind-merge';

interface Props {
  item: TaskHistoryListItem;
  isRead: boolean;
  onOptionClick?: (item: TaskHistoryListItem) => void;
}

export const TaskHistoryItem: React.FC<Props> = ({item, onOptionClick, isRead}) => {
  return (
    <Suspense fallback={<TaskHistoryLoading />}>
      <TaskHistoryItemRow item={item} onOptionClick={onOptionClick} isRead={isRead} />
    </Suspense>
  );
};

const TaskHistoryLoading = memo(() => {
  return (
    <div className="m-1 flex flex-row items-center justify-start opacity-50">
      <Skeleton className="h-4 w-64" />
      <Spacer className="flex-grow" />
      <Skeleton className="h-4 w-24" />
    </div>
  );
});

const TaskHistoryItemRow: React.FC<Props> = ({item, onOptionClick, isRead}) => {
  const fieldSchema = fieldSchemaStore.require((s) => s.getById(item.fieldSchemaId))!;
  const actor = actorStore.require((s) => s.getById(item.authorId))!;
  const ref = useRef<HTMLDivElement>(null);
  const store = useContext(TaskHistoryContext);
  const isFaint = isRead || item.authorId === authStore.actorId;

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.altKey) {
        e.stopPropagation();
        onOptionClick?.(item);
      }
    },
    [onOptionClick, item],
  );

  const isComment = fieldSchema.type === FieldType.Comment && item.newValue;
  const onLoaded = useCallback(() => {
    if (ref.current) {
      store.setRef(item.id, ref.current);
    }
  }, [item.id, ref.current]);

  useEffect(() => {
    ref.current && !isComment && onLoaded();
  }, [ref.current]);

  if (!fieldSchema) {
    return <div className="text-red-600/50">Unknown field schema {JSON.stringify(item)}</div>;
  }

  if (isComment) {
    return <CommentView ref={ref} commentId={+item.newValue!} onLoaded={onLoaded} onClick={onClick} />;
  }

  return (
    <div
      ref={ref}
      className={twMerge(
        'flex w-[111.111111%] origin-left scale-90 flex-row items-center justify-start leading-6 text-gray-500',
        isFaint && 'opacity-50',
      )}
      onClick={onClick}
    >
      <div className="whitespace-nowrap font-semibold">@{actor.name}</div>
      <Spacer />
      <div className="line-clamp-1 flex flex-grow flex-row items-center">{generateStatement(fieldSchema, item)}</div>
      <Spacer />
      <div className="flex-shrink-0 flex-grow-0">
        <RelativeTime date={item.createdAt} />
      </div>
    </div>
  );
};

const NewValue: React.FC<{children: React.ReactNode}> = ({children}) => {
  return <span className="line-clamp-1 break-all font-semibold">{children}</span>;
};

const OldValue: React.FC<{children: React.ReactNode}> = ({children}) => {
  return <span className="line-clamp-1 break-all line-through opacity-50">{children}</span>;
};

const Action: React.FC<{children: React.ReactNode}> = ({children}) => {
  return <span className="line-clamp-1 flex-shrink-0 whitespace-nowrap break-all">{children}</span>;
};

const generateStatement = (fieldSchema: FieldSchema, item: TaskHistoryListItem): React.ReactNode => {
  const {type, name} = fieldSchema;
  const {oldValue, oldDisplay, newValue, newDisplay} = item;

  switch (type) {
    case FieldType.Comment: {
      return 'created a comment that was later deleted';
    }
    case FieldType.CreatedAt: {
      return 'created this task';
    }
    case FieldType.Assignee: {
      const text = oldValue && newValue ? 'reassigned' : oldValue ? 'unassigned' : 'assigned';
      return renderActorUpdate(text, oldValue, newValue);
    }
    case FieldType.Reporter: {
      const text =
        oldValue && newValue ? 'changed the reporter' : oldValue ? 'removed the reporter' : 'set the reporter';
      return renderActorUpdate(text, oldValue, newValue);
    }
    case FieldType.Labels: {
      return renderLabelUpdate(oldDisplay, newDisplay);
    }
    case FieldType.Rank: {
      return (
        <>
          changed the rank:
          <Spacer />
          <NewValue>{newDisplay ?? newValue}</NewValue>
        </>
      );
    }
    case FieldType.Points: {
      const verb = oldDisplay && newDisplay ? 'changed' : newDisplay ? 'set' : 'cleared';
      return (
        <>
          <Action>{verb} the points:</Action>
          <Spacer />
          <OldValue>{oldDisplay ?? oldValue}</OldValue>
          {oldDisplay && newDisplay ? (
            <>
              <Spacer />→<Spacer />
            </>
          ) : (
            ''
          )}
          <NewValue>{newDisplay ?? newValue}</NewValue>
        </>
      );
    }
    case FieldType.Description:
    case FieldType.Title: {
      // TODO: diff view
      return (
        <>
          <Action>changed the {name}:</Action>
          <Spacer />
          <OldValue>{oldDisplay ?? oldValue}</OldValue>
          <Spacer />→<Spacer />
          <span className="line-clamp-1 break-all">{newDisplay ?? newValue}</span>
        </>
      );
    }
    case FieldType.Status: {
      return (
        <>
          <Action>changed the status:</Action>
          <Spacer />
          <OldValue>
            <StatusLabel id={+oldValue!} />
          </OldValue>
          {oldDisplay && newDisplay ? (
            <>
              <Spacer />→<Spacer />
            </>
          ) : (
            ''
          )}
          <StatusLabel id={+newValue!} />
        </>
      );
    }
    case FieldType.LinkedTasks: {
      if (oldDisplay ?? oldValue) {
        return (
          <>
            <Action>removed a task link:</Action>
            <Spacer />
            <OldValue>{oldDisplay ?? oldValue}</OldValue>
          </>
        );
      } else {
        return (
          <>
            <Action>added a task link:</Action>
            <Spacer />
            <NewValue>{newDisplay ?? newValue}</NewValue>
          </>
        );
      }
    }
    case FieldType.Parent: {
      return (
        <>
          <Action>changed the {name}:</Action>
          <Spacer />
          {oldValue && (
            <OldValue>
              <TaskLabel id={+oldValue} fallbackText={oldDisplay} />
            </OldValue>
          )}
          {oldValue && newValue && (
            <>
              <Spacer />→<Spacer />
            </>
          )}
          {newValue && (
            <NewValue>
              <TaskLabel id={+newValue} fallbackText={newDisplay} />
            </NewValue>
          )}
        </>
      );
    }

    default: {
      if ((oldDisplay ?? oldValue) && (newDisplay ?? newValue)) {
        return (
          <>
            <Action>changed the {name}:</Action>
            <Spacer />
            <OldValue>{oldDisplay ?? oldValue}</OldValue>
            <Spacer />→<Spacer />
            <NewValue>{newDisplay ?? newValue}</NewValue>
          </>
        );
      } else if (oldDisplay ?? oldValue) {
        return (
          <>
            <span className="shrink-0">updated the {name}:</span>
            <Spacer />
            <OldValue>{oldDisplay ?? oldValue}</OldValue>
          </>
        );
      }
      return (
        <>
          <Action>set the {name} to</Action>
          <Spacer />
          <NewValue>{newDisplay ?? newValue}</NewValue>
        </>
      );
    }
  }
};

const renderActorUpdate = (
  text: string,
  oldValue: string | undefined,
  newValue: string | undefined,
): React.ReactNode => {
  if (oldValue && newValue) {
    return (
      <>
        <Action>{text}:</Action>
        <Spacer />
        <ActorAvatar actorId={+oldValue} size="sm" showName />
        <Spacer />
        →
        <Spacer />
        <ActorAvatar actorId={+newValue!} size="sm" showName />
      </>
    );
  }
  return (
    <>
      <Action>{text}:</Action>
      <Spacer />
      <ActorAvatar actorId={newValue ? +newValue : +oldValue!} size="sm" showName />
    </>
  );
};

const renderLabelUpdate = (oldDisplay: string | undefined, newDisplay: string | undefined): React.ReactNode => {
  const oldTags = oldDisplay?.split(' ').filter((l) => !!l) ?? [];
  const newTags = newDisplay?.split(' ').filter((l) => !!l) ?? [];
  const removed = oldTags.filter((l) => !newTags.includes(l));
  const added = newTags.filter((l) => !oldTags.includes(l));
  return (
    <div className="line-clamp-1 flex flex-row items-center">
      <Action>updated the labels:</Action>
      {removed.length > 0 && <Spacer />}
      {removed.map((tag, i) => (
        <Tag key={i} readonly>
          {'- '}
          <span className="line-through opacity-50">{tag}</span>
        </Tag>
      ))}
      {added.length > 0 && <Spacer />}
      {added.map((tag, i) => (
        <Tag readonly key={i}>{`+ ${tag}`}</Tag>
      ))}
    </div>
  );
};
