import './lib/dev';

import BacklogIcon from '@/assets/icon-backlog.svg?react';
import BoardIcon from '@/assets/icon-board.svg?react';
import InboxIcon from '@/assets/icon-inbox.svg?react';
import MomentumIcon from '@/assets/icon-momentum-ai.svg?react';
import NavIcon from '@/components/nav/NavIcon';
import {ProjectSelector} from '@/components/project-selector/ProjectSelector';
import {Toasts} from '@/components/toast/Toasts';
import {Badge, Spacer} from '@/design-system';
import {SuspenseLoader} from '@/design-system/SuspenseLoader';
import {AppLayout} from '@/layouts/AppLayout';
import {Backlog} from '@/pages/Backlog';
import {accountStore} from '@/stores/account';
import {contextStore} from '@/stores/context';
import {externalAccountStore} from '@/stores/externalAccount';
import {inboxMessageStore} from '@/stores/inboxMessage';
import {projectStore} from '@/stores/project';
import {Filters} from '@shared/filters/Filters';
import {lazy} from 'react';
import {OverlayProvider} from 'react-aria';
import {Redirect, Route, Switch, useParams} from 'wouter';
import AuthTransfer from './pages/AuthTransfer';
import Board from './pages/Board';
import Inbox from './pages/Inbox';
import {authStore} from './stores/auth';

/**
 * for bugsnag performance monitoring to properly group routes
 * we need to convert dynamic segments to named segments
 */
export function resolveRoute(url: URL): string {
  const path = url.pathname;
  return path
    .split('/')
    .map((p) => {
      if (p.match(/^:[\w-_]+/)) {
        return ':context';
      } else if (p.match(/^\w+-\d+$/)) {
        return ':taskKey';
      } else if (p.match(/^\d+$/)) {
        return ':id';
      }
      return p;
    })
    .join('/')
    .replace(/\/+$/, '');
}

// matches :account:project:boardId in a URL like https://app.mmntm.ai/:fooaccount:FA:1/board
const pathContextRegex = /(?:\/(?<context>(?::[\w-_]+){1,3}))?/;
const DebugButton = lazy(() => import('@/debug/DebugButton'));

const init = () => {
  import('./stores/ui/appStatusStore');
  import('@shared/lib/debug');
};

function App() {
  init();

  return (
    <OverlayProvider>
      <StoreCache />
      <Switch>
        <Route path="/auth/transfer" component={AuthTransfer} />
        <Route path={pathContextRegex} component={AppContainer} nest />
        <Route>
          <div>404</div>
        </Route>
      </Switch>
      <Toasts />
    </OverlayProvider>
  );
}

function AppContainer() {
  setupAppContext();

  return (
    <AppLayout nav={<Nav />}>
      <SuspenseLoader>
        <Switch>
          <Route path="/">
            <Redirect to="/inbox/" />
          </Route>
          <Route path="/inbox/:taskKey?" component={Inbox} />
          <Route path="/task/:taskKey?" component={Inbox} />
          <Route path="/board/:taskKey?" component={Board} />
          <Route path="/backlog/:taskKey?" component={Backlog} />
          <Route>
            <div>404!</div>
          </Route>
        </Switch>
      </SuspenseLoader>
    </AppLayout>
  );
}

function StoreCache() {
  // stay subscribed to account and external account so they will be in cache
  const accountCache = accountStore.use((s) => s.getById(authStore.accountId), [authStore.accountId]);
  externalAccountStore.use((s) => s.getById(accountCache?.externalAccountId), [accountCache?.externalAccountId]);

  return null;
}

function setupAppContext() {
  // for caching purposes. Do not load app until the account is loaded
  const account = accountStore.require((s) => s.getById(authStore.accountId))!;
  externalAccountStore.use((s) => s.getById(account.externalAccountId), [account.externalAccountId]);
  projectStore.use((s) => s.getList(Filters.projectFilter({}))?.map((p) => s.getById(p.id)), []);

  const {context} = useParams();

  contextStore.reconcileWithLocation(context);

  contextStore.require((s) => s.isReady);
}

function Nav() {
  const count = inboxMessageStore.use((s) => s.getList(Filters.inboxMessageFilter({read: false}))?.length ?? 0, []);
  const badge = count < 1000 ? count : '?:D';

  return (
    <>
      {' '}
      <Badge invisible={count === 0} content={badge} className="shadow-md">
        <NavIcon icon={<InboxIcon />} to="/inbox/" selected />
      </Badge>
      <Spacer className="my-2 w-full border-b border-dashed border-b-pink-50/20" />
      <ProjectSelector />
      <NavIcon icon={<BoardIcon />} to="/board/" match={/^\/board(\/|$)/} />
      <NavIcon icon={<BacklogIcon />} to="/backlog/" match={/^\/backlog(\/|$)/} />
      <span className="flex-1"></span>
      <DebugButton />
      <NavIcon icon={<MomentumIcon />} />
      <Spacer />
    </>
  );
}

export default App;
