import {TaskLinksInput} from '@/components/task/TaskLinksInput';
import {TaskTagsInput} from '@/components/task/TaskTagsInput';
import {actorDatumStore} from '@/stores/ActorDatum';
import {ActorDatumType} from '@shared/models/ActorDatum';
import {LinkIcon, TagIcon} from 'lucide-react';
import {useEffect} from 'react';
import type {positionValues} from 'react-custom-scrollbars-2';
import {tv} from 'tailwind-variants';

interface Props {
  id: number;
  onScrollChange?: (scroll: positionValues) => void;
}

const styles = tv({
  slots: {
    fieldsList: 'flex flex-col gap-5 p-5 pr-8',
  },
});

export const TaskDetailsView: React.FC<Props> = ({id}) => {
  const {fieldsList} = styles();

  useEffect(() => {
    actorDatumStore.addToRecents(ActorDatumType.RecentTasks, id);
  }, []);

  return (
    <div className="bg-0 flex w-full flex-col pb-4 pl-2">
      <div className={fieldsList()}>
        <FieldRow>
          <FieldHeader Icon={TagIcon}>Tags</FieldHeader>
          <FieldValue>
            <TaskTagsInput taskId={id} />
          </FieldValue>
        </FieldRow>
        <FieldRow>
          <FieldHeader Icon={LinkIcon}>Links</FieldHeader>
          <FieldValue>
            <TaskLinksInput taskId={id} />
          </FieldValue>
        </FieldRow>
      </div>
    </div>
  );
};

const FieldRow = ({children}: {children: React.ReactNode}) => {
  return <div className="flex min-h-7 flex-row gap-2">{children}</div>;
};

const FieldHeader = ({Icon, children}: {Icon: React.FC<React.SVGProps<SVGSVGElement>>; children: React.ReactNode}) => {
  return (
    <div className="w-32">
      <div className="flex items-center gap-0.5 pt-1 text-xs font-semibold uppercase text-gray-800/50">
        <Icon className="h-5" />
        {children}
      </div>
    </div>
  );
};

const FieldValue = ({children}: {children: React.ReactNode}) => {
  return <div className="w-full text-sm text-gray-800">{children}</div>;
};
