import {ErrorBoundary as MoErrorBoundary} from '@/components/ErrorBoundary';
import {SuspenseLoader} from '@/design-system/SuspenseLoader';
import {ErrorPage} from '@/pages/ErrorPage';
import BugsnagPerformance, {DefaultRoutingProvider} from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {enableMapSet} from 'immer';
import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import App, {resolveRoute} from './App';
import './app-service-worker/Worker';
import './aria.css';
import './lib/pushStateEvent';
import './stores/auth';
import './styles.css';
import './tiptap.css';

if (VITE_BUGSNAG_API_KEY) {
  Bugsnag.start({
    appVersion: VITE_BUILD_VERSION,
    apiKey: VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: DEV ? 'development' : 'production',
  });
  BugsnagPerformance.start({
    apiKey: VITE_BUGSNAG_API_KEY,
    routingProvider: new DefaultRoutingProvider(resolveRoute),
  });
}

enableMapSet();

const ErrorBoundary =
  (VITE_BUGSNAG_API_KEY ? Bugsnag.getPlugin('react')?.createErrorBoundary(React) : null) ?? MoErrorBoundary;

export const startApp = () => {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <SuspenseLoader>
          <App />
        </SuspenseLoader>
      </ErrorBoundary>
    </StrictMode>,
  );
};
