import {Image} from '@/components/Image';
import {RelativeTime} from '@/components/time/RelativeTime';
import {authStore} from '@/stores/auth';
import {inboxMessageStore} from '@/stores/inboxMessage';
import {inboxStore} from '@/stores/ui/inboxStore';
import type {ReactNode} from 'react';
import React from 'react';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props {
  id: number;
  onClick: (id: number) => void;
  onMarkUnread: (id: number) => void;
  className?: string;
}

const styles = tv({
  base: 'transition-color-opacity flex cursor-pointer flex-col justify-between overflow-hidden rounded-md bg-white bg-opacity-70 p-2 shadow-sm outline outline-2 outline-pink-500/30 outline-transparent duration-200 hover:bg-opacity-100 hover:opacity-100 hover:shadow-md',
  variants: {
    isRead: {
      true: 'bg-opacity-40 opacity-70 hover:bg-opacity-60 hover:opacity-100',
    },
    isSelected: {
      true:
        'bg-opacity-100 opacity-100 shadow-md hover:bg-opacity-100 hover:opacity-100' +
        ' outline outline-2 outline-pink-500/50 duration-0',
    },
  },
  compoundVariants: [
    {
      isSelected: true,
      isRead: true,
      className: 'opacity-90',
    },
  ],
});

export const MessageListItem: React.FC<Props> = ({id, onClick, onMarkUnread, className}) => {
  const item = inboxMessageStore.use((s) => s.getById(id), [id]);
  const isSelected = inboxStore.use((s) => s.state.selectedId === id, [id]);

  if (!item) return null;

  const mentionIndex = item.content.indexOf(`@${authStore.actor?.name}`);
  let content: ReactNode;
  if (mentionIndex >= 0 && authStore.actor?.name) {
    content = (
      <>
        <span>{item.content.slice(0, mentionIndex)}</span>
        <span className="font-bold text-pink-500">
          {item.content.slice(mentionIndex, mentionIndex + authStore.actor.name.length + 1)}
        </span>
        <span>{item.content.slice(mentionIndex + authStore.actor.name.length + 1)}</span>
      </>
    );
  } else {
    content = item.content;
  }

  return (
    <div
      className={twMerge(className, styles({isSelected, isRead: !!item?.readAt}))}
      style={{height: '80px'}}
      onMouseDown={(e) => {
        e.stopPropagation();
        if (e.altKey) {
          onMarkUnread(id);
        } else {
          onClick(id);
        }
      }}
    >
      <div className="mb-1 flex flex-row items-start justify-start">
        <div className="mr-1 h-4 w-4 flex-shrink-0">{item.titleIconId && <Image id={item.titleIconId} />}</div>
        <div className="line-clamp-2 flex-grow overflow-hidden text-xs font-semibold text-gray-600">{item.title}</div>
        <div className="flex-shrink-0 text-xs opacity-50 hover:opacity-100">
          <RelativeTime date={item.createdAt} />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="line-clamp-2 text-xs text-gray-500">{content}</div>
      </div>
    </div>
  );
};
