import React, {useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props {
  children: React.ReactNode;
  invisible?: boolean;
  content: React.ReactNode;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

const styles = tv({
  slots: {
    container: 'absolute right-0 top-0 min-h-4 min-w-4 -translate-y-1/2 translate-x-1/2 transition-opacity',
    badge:
      'rounded-full bg-red-500 px-1 text-center text-xs font-semibold leading-4 text-white shadow-sm shadow-black/50',
  },
  variants: {
    invisible: {
      true: {badge: 'animate-out zoom-out-50', container: 'opacity-0'},
      false: {badge: 'animate-in zoom-in-50', container: 'opacity-100'},
    },
    size: {
      sm: {badge: 'scale-80', container: ''},
      md: {badge: '', container: ''},
      lg: {badge: 'scale-125', container: ''},
    },
  },
});

export const Badge: React.FC<Props> = ({children, invisible, content, className, size = 'md'}) => {
  const [text, setText] = useState<React.ReactNode>(content);
  useEffect(() => {
    content && setText(content);
  }, [content]);

  const {container, badge} = styles({size, invisible: text == null || text == '' ? true : invisible});

  return (
    <div className="relative">
      {children}
      <div className={container()}>
        <div className={twMerge(badge(), className)}>
          <div className="mt-[-.03125rem]">{text}</div>
        </div>
      </div>
    </div>
  );
};
