import {mergeAttributes, Node} from '@tiptap/core';

export interface UnsupportedNodeOptions {
  /**
   * HTML attributes to add to the image element.
   * @default {}
   * @example { class: 'foo' }
   */

  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Commands<ReturnType> {}
}

/**
 * This extension captures unsupported node data for ADF -> Momentum -> ADF conversions
 */
export const UnsupportedNode = Node.create<UnsupportedNodeOptions>({
  name: 'unsupportedNode',

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'unsupported-node',
      },
    };
  },

  addAttributes() {
    return {
      'data-unsupported-node': {default: null},
    };
  },

  inline: true,
  group: 'inline',
  draggable: false,
  content: 'text*',
  marks: '',

  parseHTML() {
    return [
      {
        tag: 'span[data-unsupported-node]',
      },
    ];
  },

  renderHTML({HTMLAttributes}) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        contentEditable: false,
        style: 'display: inline-block',
      }),
      0,
    ];
  },

  addCommands() {
    return {};
  },

  addInputRules() {
    return [];
  },
});
