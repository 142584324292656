import {BacklogTaskGroup} from '@/components/backlog/BacklogTaskGroup';
import {NewSprintGroup} from '@/components/backlog/NewSprintGroup';
import {RightSide} from '@/components/backlog/RightSide';
import {SprintTaskGroup} from '@/components/backlog/SprintTaskGroup';
import type {TaskListItemType} from '@/components/backlog/TaskList';
import {TaskList} from '@/components/backlog/TaskList';
import {withSuspenseLoader} from '@/components/lib/withSuspenseLoader';
import {AnimateIn} from '@/design-system/AnimateIn';
import {SelectMode, type ListStore, type Selection} from '@/design-system/lists/ListStore';
import {ScrollView} from '@/design-system/ScrollView';
import {contextStore} from '@/stores/context';
import {taskTypeStore} from '@/stores/taskType';
import {backlogStore} from '@/stores/ui/backlogStore';
import {Filters} from '@shared/filters/Filters';
import {NOT_DONE_STATUS_CATEGORIES} from '@shared/models/Status';
import {memo, useCallback, useEffect, useRef} from 'react';

interface Props {
  params: {
    taskKey?: string;
    context?: string;
  };
}

export function Backlog(_: Props) {
  useEffect(() => backlogStore.onMount(), []);

  return <BacklogContent />;
}

const listStores: (ListStore<TaskListItemType, number | string> | null)[] = [];

const BacklogContent = memo(
  withSuspenseLoader(function BacklogContent() {
    const [selected, selection, sprintList, _taskSprintMap] = backlogStore.use(
      (s) => [s.state.selected, s.state.selection, s.state.sprintList, s.state.taskSprintMap],
      [],
    );

    const taskTypeIds = taskTypeStore.require((s) => s.getHierarchy0Ids());
    const ref = useRef<HTMLDivElement>(null);

    const onSelectionChange = useCallback((selection: Selection<number | string>) => {
      backlogStore.updateSelection(selection.keys);
    }, []);

    const onUnprocessedKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
      // for whatever reason, aria lists allow the keydown to sometimes propagate even when they handle it
      if ((e.target as HTMLElement).id?.startsWith('react-aria')) return;

      let dir = 0;
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        dir = 1;
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        dir = -1;
      }
      if (dir !== 0) {
        const store = listStores[index + dir];
        if (store) {
          const item = dir > 0 ? store.collection.list[0] : store.collection.list[store.collection.list.length - 1];
          store.selectAndFocus(item.id, SelectMode.Set);
        }
      }
    }, []);

    listStores.length = sprintList.length + 1;
    const taskLists = sprintList.map((s, i) => (
      <TaskList
        key={s.id}
        filter={Filters.taskFilter({sprintId: s.id, projectId: contextStore.projectId, typeId: taskTypeIds})}
        onSelectionChange={onSelectionChange}
        selection={selection}
        onUnprocessedKeyDown={(e) => onUnprocessedKeyDown?.(e, i)}
        ref={(s) => (listStores[i] = s)}
      />
    ));
    const backlogTaskList = (
      <TaskList
        key="backlog"
        filter={Filters.taskFilter({
          sprintId: null,
          projectId: contextStore.projectId,
          typeId: taskTypeIds,
          statusCategory: NOT_DONE_STATUS_CATEGORIES,
        })}
        onSelectionChange={onSelectionChange}
        selection={selection}
        onUnprocessedKeyDown={(e) => onUnprocessedKeyDown?.(e, sprintList.length)}
        ref={(s) => (listStores[sprintList.length] = s)}
      />
    );

    function deselect(e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) {
      if (
        (e.type === 'mousedown' && (e as React.MouseEvent).button === 0) ||
        (e.type === 'keydown' && (e as React.KeyboardEvent).key === 'Escape')
      ) {
        backlogStore.updateSelection(new Set());
      }
    }

    return (
      <div ref={ref} className="flex h-full max-h-full w-full flex-row overflow-hidden">
        <div
          className="w-1/2 border-r-2 border-[#B13982] border-opacity-65 bg-background-med"
          onMouseDown={deselect}
          onKeyDown={deselect}
        >
          <ScrollView>
            <AnimateIn className="max-h-full py-5" animation="fade-in slide-in-from-left-5">
              {sprintList.map((s, i) => (
                <SprintTaskGroup
                  key={s.id}
                  sprintId={s.id}
                  // dragAndDropHooks={dragAndDropHooks}
                  isFirstInList={i === 0}
                  taskList={taskLists[i]}
                />
              ))}
              <NewSprintGroup templateId={sprintList[sprintList.length - 1]?.id} />
              <BacklogTaskGroup
                taskList={backlogTaskList}
                // dragAndDropHooks={dragAndDropHooks}
              />
            </AnimateIn>
          </ScrollView>
        </div>

        <div className="h-full max-h-full w-1/2 overflow-hidden">
          <RightSide key={selected} taskId={typeof selected === 'number' ? selected : undefined} />
        </div>
      </div>
    );
  }),
);
