import {EntityType} from '@shared/EntityType';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from '@shared/filters/EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC} from '@shared/filters/EntityFilter';
import type {Wip, WipType} from '@shared/models/Wip';
import type {DB} from 'src-sw/db/db';

/**
 * This is an actor specific entity, so we don't need to filter by actorId,
 * as it will be based off our authorization context.
 */
export class WipFilter extends EntityFilter<IWipFilter> {
  readonly type = EntityType.Wip;
  readonly taskId?: number;
  readonly wipType?: WipType;

  constructor(filter: Omit<IWipFilter, 'type'>) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(_db: DB, entity: Wip): Promise<[boolean, string, RankComparator]> {
    if (this.taskId !== undefined && entity.taskId !== this.taskId) {
      return [false, entity.type, RANK_COMPARATOR_ASC];
    }

    if (this.wipType !== undefined && entity.type !== this.wipType) {
      return [false, entity.type, RANK_COMPARATOR_ASC];
    }

    return [true, entity.type, RANK_COMPARATOR_ASC];
  }

  public toString(): string {
    return `${this.type}|${this.taskId ?? ''}|${this.wipType ?? ''}`;
  }
}

export type IWipFilter = PublicPropertiesOf<WipFilter>;
export function isWipFilter(filter: IEntityFilter): filter is WipFilter {
  return filter.type === EntityType.Wip;
}
