import LinkBreakIcon from '@/assets/text-editor/link-break.svg?react';
import {SelectTaskList} from '@/components/task/SelectTaskList';
import type {TaskLabelProps} from '@/components/task/TaskLabel';
import {TaskLabel} from '@/components/task/TaskLabel';
import {Button, Popover} from '@/design-system';
import {TaskSuggestionType} from '@/hooks/useTaskSuggestions';
import {contextStore} from '@/stores/context';
import {defaultFieldId, FieldType} from '@shared/models/FieldSchema';
import type {Mutation} from '@shared/models/Mutation';
import {PlusIcon} from 'lucide-react';
import {useCallback, useState} from 'react';
import {Button as AriaButton, MenuTrigger} from 'react-aria-components';
import {tv} from 'tailwind-variants';

interface Props extends Omit<TaskLabelProps, 'id'> {
  id: number | null;
  editable?: boolean;
  onCommit: (value: number | undefined, operation: Mutation['operations']) => void;
}

const styles = tv({
  base: 'group/button transition-color-opacity -m-0.5 flex items-center rounded-sm p-0.5',
  variants: {
    isEmpty: {
      true: 'text-xs text-pink-700/0 data-[focused]:!text-pink-700/80 data-[pressed]:!text-pink-700/80 hover:!text-pink-700/80 group-hover/task:text-pink-700/30',
    },
    isFocused: {
      true: 'opacity-100',
    },
  },
});

export function EditableEpicLabel({id, onCommit, editable = true, ...props}: Props) {
  const [isOpen, onOpenChange] = useState(false);
  const onChangeEpic = useCallback(
    (epicId: number) => {
      onOpenChange(false);
      if (epicId === 0) {
        onCommit(undefined, {
          delete: [defaultFieldId[FieldType.Parent].toString()],
        });
      } else {
        onCommit(epicId, {
          replace: {
            [defaultFieldId[FieldType.Parent].toString()]: {value: epicId.toString()},
          },
        });
      }
    },
    [onCommit, onOpenChange],
  );

  return (
    <MenuTrigger aria-label={`Change Epic`} isOpen={isOpen} onOpenChange={onOpenChange}>
      <AriaButton className={(s) => styles({...s, isEmpty: !id})} isDisabled={!editable}>
        {id && <TaskLabel {...props} id={id} />}
        {!id && (
          <div className="flex items-center whitespace-nowrap">
            <PlusIcon className="h-3 w-3" />
            &nbsp;Add to Epic
          </div>
        )}
      </AriaButton>
      <Popover noScroll showArrow placement="bottom">
        <SelectTaskList
          baseFilter={{projectId: contextStore.projectId}}
          suggestionType={TaskSuggestionType.Epic}
          onAction={onChangeEpic}
        />
        {id && (
          <div className="sticky bottom-0 rounded-b-[1.1rem] border-t border-gray-500/20 bg-white px-2 py-1">
            <Button seamless onPress={() => onChangeEpic(0)} className="px-2 py-1 text-sm">
              <LinkBreakIcon className="h-4 w-4" /> Unlink Epic
            </Button>
          </div>
        )}
      </Popover>
    </MenuTrigger>
  );
}
