import {TaskHistoryItem} from '@/components/task/TaskHistoryItem';
import {Button} from '@/design-system/Button';
import {ScrollViewContext} from '@/design-system/stores/ScrollViewStore';
import {TaskHistoryContext} from '@/stores/ui/taskHistoryUiStore';
import {defaultFieldId, FieldType} from '@shared/models/FieldSchema';
import type {TaskHistoryListItem} from '@shared/models/FilteredEntityList';
import {ChevronRightIcon} from 'lucide-react';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props {
  id: number;
  className?: string;
}

export const TaskHistoryView: React.FC<Props> = ({id: taskId, className}) => {
  const store = useContext(TaskHistoryContext);
  // const isLoaded = store.use((s) => s.state.isLoaded);
  const ref = useRef<HTMLDivElement>(null);

  const scrollViewStore = useContext(ScrollViewContext)!;
  const scrollView = scrollViewStore.use((s) => s.getScrollView());
  useEffect(() => {
    scrollView && store?.setScrollView(scrollView);
  }, [scrollView]);

  if (!store || store.taskId !== taskId) return null;

  return (
    <div ref={ref} className="w-full">
      <div
        className={twMerge(
          'relative flex h-full min-h-1 w-full flex-col px-10 text-sm leading-7 transition-opacity duration-100',
          // isLoaded ? 'opacity-100' : 'opacity-0',
          className,
        )}
      >
        <div className="min-h-5 flex-grow" />
        <RenderHistory />
        <div className="min-h-5" />
      </div>
    </div>
  );
};

function RenderHistory() {
  const store = useContext(TaskHistoryContext);
  const state = store.use((s) => s.getMany('history', 'manualReadTo', 'initialReadTo', 'readTo'));

  const onOptionClick = useCallback(
    (item: TaskHistoryListItem) => {
      const readTo = new Date(new Date(item.createdAt).getTime() - 1).toISOString();
      store.setManualReadTo(readTo);
    },
    [store],
  );

  let renderedUnreadMarker = false;
  const readTo = state.manualReadTo ?? state.initialReadTo;
  const groups = groupHistory(state.history ?? [], readTo);

  return groups.flatMap((g, index) => {
    if (g.length === 0) return [];
    if (g.length === 1) {
      const h = g[0];
      if (!renderedUnreadMarker && readTo && h.createdAt > readTo) {
        renderedUnreadMarker = true;
        return [
          <UnreadIndicator key="unread-marker" />,
          <TaskHistoryItem key={index} item={h} onOptionClick={onOptionClick} isRead={false} />,
        ];
      }
      return (
        <TaskHistoryItem key={index} item={h} onOptionClick={onOptionClick} isRead={!readTo || h.createdAt <= readTo} />
      );
    } else {
      return <TaskHistoryGroup key={index} items={g} onOptionClick={onOptionClick} />;
    }
  });
}

function groupHistory(history: TaskHistoryListItem[], readTo?: string | null) {
  const groups: TaskHistoryListItem[][] = [];
  let currentGroup: TaskHistoryListItem[] = [];
  for (const item of history) {
    if ((readTo && item.createdAt > readTo) || !isGroupable(item)) {
      if (currentGroup.length > 0) {
        groups.push(currentGroup);
      }
      groups.push([item]);
      currentGroup = [];
    } else {
      currentGroup.push(item);
    }
  }
  if (currentGroup.length > 0) {
    groups.push(currentGroup);
  }
  return groups;
}

function isGroupable(item: TaskHistoryListItem) {
  const isComment = item.fieldSchemaId === defaultFieldId[FieldType.Comment] && item.newValue;
  const isAssignee = item.fieldSchemaId === defaultFieldId[FieldType.Assignee];
  const isStatusChange = item.fieldSchemaId === defaultFieldId[FieldType.Status];
  const isCreatedAt = item.fieldSchemaId === defaultFieldId[FieldType.CreatedAt];
  return !(isComment || isAssignee || isStatusChange || isCreatedAt);
}

const UnreadIndicator = () => {
  const store = useContext(TaskHistoryContext);
  const {manualReadTo, initialReadTo, readTo} = store.use((s) => s.getMany('manualReadTo', 'initialReadTo', 'readTo'));

  const faded = !manualReadTo && initialReadTo !== readTo;

  const fadedClass = faded ? 'border-opacity-20' : 'border-opacity-80';
  return (
    <div className={`-my-[1px] h-0 w-full border-b-2 border-pink-500 ${fadedClass} transition-all duration-500`} />
  );
};

const groupStyles = tv({
  slots: {
    toggle:
      'group/toggle absolute -left-7 bottom-0 top-0 flex min-h-5 min-w-5 cursor-pointer flex-row items-center justify-center px-1 py-0 pl-6 text-gray-500/50 transition-colors hover:text-pink-500/65 focus:text-pink-500/65',
    toggleIcon: 'absolute left-1 top-1.5 h-4 w-4 origin-center transition-transform',
    toggleLine: 'absolute bottom-1.5 left-3 top-8 w-[1px] bg-current',
  },
  variants: {
    expanded: {
      true: {
        toggle: 'w-5 items-start pl-5',
        toggleIcon: 'rotate-90',
        toggleLine: '',
      },
      false: {
        toggle: '',
        toggleLine: '',
      },
    },
  },
});

function TaskHistoryGroup({
  items,
  onOptionClick,
}: {
  items: TaskHistoryListItem[];
  onOptionClick: (item: TaskHistoryListItem) => void;
}) {
  const store = useContext(TaskHistoryContext);
  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const onToggle = () => setExpanded((e) => !e);
  const {toggle, toggleLine, toggleIcon} = groupStyles({expanded});

  useEffect(() => {
    if (ref.current && !expanded) {
      store.mutate(() => {
        for (const item of items) {
          store.setRef(item.id, ref.current);
        }
      });
    }
  }, [ref.current, expanded]);

  return (
    <div ref={ref} className="group relative">
      <Button seamless onPress={onToggle} className={toggle()}>
        <div className={toggleLine()} />
        <ChevronRightIcon className={toggleIcon()} />
        {!expanded && <span className="pr-2 text-xs">{items.length} updates</span>}
      </Button>
      {!expanded ? (
        <div className="pointer-events-none w-[111.111111%] origin-left scale-90 select-none">&nbsp;</div>
      ) : (
        items.map((item) => <TaskHistoryItem key={item.id} item={item} onOptionClick={onOptionClick} isRead={true} />)
      )}
    </div>
  );
}
