import type {ErrorInfo} from 'react';
import React from 'react';

interface Props {
  children?: React.ReactNode | undefined;
  FallbackComponent?: React.ComponentType<{
    error: Error;
    info: React.ErrorInfo | null;
    clearError: () => void;
  }>;
}

interface State {
  errorInfo: React.ErrorInfo | null;
  error: Error | null;
}

export class ErrorBoundary extends React.Component<Props, State> {
  state = {errorInfo: null, error: null} as State;

  static getDerivedStateFromError(error: unknown) {
    if (error instanceof Error) {
      return {errorInfo: null, error};
    }
    return {errorInfo: null, error: new Error(`An unknown error occurred: ${error}`)};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo | null) {
    console.error('Uncaught error:', error, errorInfo);
    this.setState({errorInfo, error});
  }

  render() {
    if (this.state.error) {
      const FallbackComponent = this.props.FallbackComponent;
      if (FallbackComponent) {
        return (
          <FallbackComponent
            error={this.state.error}
            info={this.state.errorInfo}
            clearError={() => this.setState({errorInfo: null, error: null})}
          />
        );
      }
    }
    return this.props.children;
  }
}
