import {Image} from '@/components/Image';
import type {Suggestion} from '@/design-system';
import {FilterableList, SelectableItem} from '@/design-system';
import type {TaskSuggestionType} from '@/hooks/useTaskSuggestions';
import {useTaskSuggestions} from '@/hooks/useTaskSuggestions';
import type {OmitType} from '@shared/filters/Filters';
import type {ITaskFilter} from '@shared/filters/TaskFilter';
import type {EntityListItem} from '@shared/models/FilteredEntityList';
import React, {useEffect, useState} from 'react';

interface Props {
  onAction?: (taskId: number) => void;
  baseFilter?: OmitType<ITaskFilter>;
  suggestionType: TaskSuggestionType;
  excludeTaskIds?: number[];
}

export const SelectTaskList: React.FC<Props> = ({baseFilter, suggestionType, excludeTaskIds, onAction}) => {
  const getSuggestions = useTaskSuggestions({baseFilter, excludeTaskIds, type: suggestionType});
  const [filter, setFilter] = useState('');
  function onFilterChange(filter: string) {
    setFilter(filter);
  }
  const [items, setItems] = useState<Suggestion[]>([]);
  useEffect(() => {
    getSuggestions(filter, setItems);
  }, [filter, getSuggestions]);

  return (
    <FilterableList items={items} aria-label="Task list" onAction={onAction} onFilterChange={onFilterChange}>
      {(item) => (
        <SelectableItem textValue={item.text} key={item.id} className="flex items-center gap-2">
          <TaskListItem item={item} />
        </SelectableItem>
      )}
    </FilterableList>
  );
};

export const TaskListItem = ({item}: {item: EntityListItem}) => {
  return (
    <div className="flex max-w-96 flex-grow flex-row items-center break-all text-sm">
      <div className="mr-2 h-4 w-4 flex-shrink-0">
        <Image id={item.icon} />
      </div>
      <div className="line-clamp-1 flex-grow">{item.text}</div>
      <div className="flex-shrink-0 pl-2 text-xs text-gray-500/50">{item.key}</div>
    </div>
  );
};
