import {EntityType} from '@shared/EntityType';
import type {Actor, ActorType} from '@shared/models/Actor';
import type {DB} from 'src-sw/db/db';
import type {IEntityFilter, PublicPropertiesOf, RankComparator} from './EntityFilter';
import {EntityFilter, RANK_COMPARATOR_ASC} from './EntityFilter';

export class ActorFilter extends EntityFilter<IActorFilter> {
  readonly type = EntityType.Actor;
  readonly projectId?: number;
  readonly boardId?: number;
  readonly query?: string;
  readonly actorType?: ActorType[];
  readonly active?: boolean;
  readonly deleted?: boolean = false;

  public constructor(filter: Omit<IActorFilter, 'type'>) {
    super();
    Object.assign(this, filter);
  }

  public async matchWithRank(_db: DB, actor: Actor): Promise<[boolean, string, RankComparator]> {
    // TODO: determine if the actor belongs to this project or board
    if (
      this.query &&
      // TODO: consolidate our search logic somewhere
      !actor.name.toLowerCase().includes(this.query.toLowerCase())
    ) {
      return [false, actor.name, RANK_COMPARATOR_ASC];
    }

    if (this.actorType && !this.actorType.includes(actor.actorType)) {
      return [false, actor.name, RANK_COMPARATOR_ASC];
    }

    if (this.active !== undefined && this.active !== actor.active) {
      return [false, actor.name, RANK_COMPARATOR_ASC];
    }

    if (this.deleted !== actor.deleted) {
      return [false, actor.name, RANK_COMPARATOR_ASC];
    }

    return [true, actor.name, RANK_COMPARATOR_ASC];
  }

  public toString(): string {
    return `${this.type}|${this.projectId ?? ''}|${this.boardId ?? ''}|${this.query ?? ''}|${this.actorType?.join(',') ?? ''}|${this.active ?? ''}|${this.deleted ?? ''}`;
  }
}
export type IActorFilter = PublicPropertiesOf<ActorFilter>;
export function isActorFilter(filter: IEntityFilter): filter is ActorFilter {
  return filter.type === EntityType.Actor;
}
