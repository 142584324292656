import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import type {EntityStoreState} from '@/stores/lib/EntityStore';
import {EntityStore} from '@/stores/lib/EntityStore';
import {EntityType} from '@shared/EntityType';
import type {Wip} from '@shared/models/Wip';

export const wipStore = persistThroughHotReload(
  'wipStore',
  new EntityStore<EntityStoreState<Wip>, Wip>(EntityType.Wip, {
    byId: new Map(),
    byList: new Map(),
  }),
);

exposeInDev({wipStore});
import.meta.hot?.accept(reloadOnHotUpdate);
