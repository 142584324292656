import {ActorAvatar} from '@/components/actor/ActorAvatar';
import {TaskListItem} from '@/components/task/SelectTaskList';
import {SelectableItem} from '@/design-system';
import {useDebouncedState} from '@/hooks/useDebouncedState';
import {useTaskSuggestions} from '@/hooks/useTaskSuggestions';
import {EMPTY_LIST} from '@/lib/emptyList';
import {actorStore} from '@/stores/actor';
import {actorDatumStore} from '@/stores/ActorDatum';
import {taskStore} from '@/stores/task';
import {taskTypeStore} from '@/stores/taskType';
import type {MentionNodeAttrs} from '@/text-editor/extensions/mentions/Mention';
import {taskIdForActiveEditor} from '@/text-editor/MoEditor';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import {isStateEqual} from '@shared/lib/isStateEqual';
import {ActorType} from '@shared/models/Actor';
import {ActorDatumType} from '@shared/models/ActorDatum';
import type {EntityListItem} from '@shared/models/FilteredEntityList';
import type {SuggestionProps} from '@tiptap/suggestion';
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import type {Selection} from 'react-aria-components';
import {Dialog, ListBox} from 'react-aria-components';

export interface MentionListInterface {
  onKeyDown: (arg: {event: KeyboardEvent}) => boolean;
}

export const MentionList = forwardRef<MentionListInterface, SuggestionProps>((props, ref) => {
  const [selectedId, setSelectedId] = useState<string | number>(0);
  const [selectedKey, setSelectedKey] = useState<string | number>(0);
  const rawQuery = props.query.toLocaleLowerCase().trim();
  const [query, debouncedSetValue] = useDebouncedState(rawQuery, 100);
  const newOptions = useMentionSearch(query);
  const [options, setOptions] = useState(newOptions);
  useEffect(() => {
    if (query != rawQuery) debouncedSetValue(rawQuery);
    if (newOptions && !isStateEqual(newOptions, options)) setOptions(newOptions);
  }, [newOptions, query, rawQuery]);

  const filteredOptions = useMemo(() => {
    const query = rawQuery.replace(/^@/, '');
    return (
      options
        ?.filter((o) => o?.text?.toLocaleLowerCase().includes(query) || o?.key?.toLocaleLowerCase().startsWith(query))
        ?.slice(0, 11) ?? EMPTY_LIST
    );
  }, [options, rawQuery]);

  useEffect(() => {
    let index = filteredOptions.findIndex((o) => o.id === selectedId);
    let current = selectedId;
    if (index === -1 && filteredOptions.length > 0) {
      index = 0;
      current = filteredOptions[0].id;
      setSelectedId(current);
    }
    setSelectedKey(filteredOptions[index]?.key ?? current);
  }, [filteredOptions, selectedId]);

  const selectItem = (id: number | string) => {
    const option = filteredOptions.find((o) => o.id === id || o.key === id);
    if (option) {
      props.command({
        id: option.id.toString(),
        key: option.key,
        label: option.text,
        entity: option.type,
      } as MentionNodeAttrs);
      if (option.type === EntityType.Task) {
        actorDatumStore.addToRecents(ActorDatumType.RecentTasks, [option.id]);
        taskStore
          .await((s) => taskTypeStore.getById(s.getById(option.id)?.typeId)?.hierarchyLevel === 1)
          .then((isEpic) => {
            isEpic && actorDatumStore.addToRecents(ActorDatumType.RecentEpics, [option.id]);
          });
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: ({event}: {event: KeyboardEvent}) => {
      if (event.key === 'ArrowUp') {
        const index = Math.max(
          0,
          filteredOptions.findIndex((o) => o.id === selectedId),
        );
        const prev = filteredOptions[(index + filteredOptions.length - 1) % filteredOptions.length];
        prev && setSelectedId(prev.id);
      } else if (event.key === 'ArrowDown') {
        const index = filteredOptions.findIndex((o) => o.id === selectedId);
        const next = filteredOptions[(index + 1) % filteredOptions.length];
        next && setSelectedId(next.id);
      } else if (event.key === 'Enter' || event.key === 'Tab') {
        if (filteredOptions.length === 1) {
          selectItem(filteredOptions[0].key ?? filteredOptions[0].id);
        } else if (selectedId && filteredOptions.find((o) => o.id === selectedId || o.key === selectedId)) {
          selectItem(selectedId);
        } else {
          return false;
        }
      } else {
        return false;
      }

      return true;
    },
  }));

  return (
    <Dialog
      className="min-w-40 max-w-96 rounded-md border border-black/20 bg-white px-0 py-2 text-black/80 shadow-md"
      aria-label="Mention list"
    >
      {filteredOptions.length ? (
        <ListBox
          items={filteredOptions}
          aria-label="Mention list"
          onAction={selectItem}
          selectedKeys={[selectedKey]}
          selectionMode="single"
          onSelectionChange={(keys: Selection) => selectItem([...keys][0] as number)}
        >
          {(item) => (
            <SelectableItem key={item.id} textValue={item.text}>
              {item.type == EntityType.Actor && <ActorAvatar actorId={item.id} showName size="sm" style="normal" />}
              {item.type == EntityType.Task && <TaskListItem item={item} />}
            </SelectableItem>
          )}
        </ListBox>
      ) : (
        <pre className="w-full text-center text-xs text-gray-500/20">¯\_(ツ)_/¯</pre>
      )}
    </Dialog>
  );
});

interface Suggestion extends EntityListItem {
  type: EntityType;
  key?: string;
}

function useMentionSearch(query: string): Suggestion[] | undefined {
  const type = query.startsWith('@') ? EntityType.Task : EntityType.Actor;
  query = query.replace(/^@/, '');
  const actorOptions = actorStore.use(
    (s) =>
      type == EntityType.Actor
        ? s
            .getList(Filters.actorFilter(query ? {query} : {active: true, actorType: [ActorType.Person]}))
            ?.map((item) => ({...item, type: EntityType.Actor}))
        : undefined,
    [type, query],
  );
  const getSuggestions = useTaskSuggestions({
    excludeTaskIdsFromRecents: taskIdForActiveEditor ? [taskIdForActiveEditor] : [],
  });
  const [taskSuggestions, setTaskSuggestions] = useState<EntityListItem[]>([]);
  useEffect(() => {
    if (type === EntityType.Task) getSuggestions(query, setTaskSuggestions);
  }, [type, query, getSuggestions]);

  if (type == EntityType.Actor) {
    return actorOptions || undefined;
  } else if (type == EntityType.Task) {
    return taskSuggestions.map((item) => ({...item, type: EntityType.Task})) || undefined;
  }
}
