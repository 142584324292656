import {Mark, mergeAttributes} from '@tiptap/core';

export interface UnsupportedMarkOptions {
  /**
   * HTML attributes to add to the bold element.
   * @default {}
   * @example { class: 'foo' }
   */

  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Commands<ReturnType> {}
}

/**
 * This extension allows you to mark text as bold.
 * @see https://tiptap.dev/api/marks/bold
 */
export const UnsupportedMark = Mark.create<UnsupportedMarkOptions>({
  name: 'unsupportedMark',

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'unsupported-mark',
      },
    };
  },

  addAttributes() {
    return {
      'data-unsupported-mark': {default: null},
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-unsupported-mark]',
      },
    ];
  },

  renderHTML({HTMLAttributes}) {
    // const {originalMark, HTMLAttributes} = attributes;
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {};
  },

  addKeyboardShortcuts() {
    return {};
  },

  addInputRules() {
    return [];
  },

  addPasteRules() {
    return [];
  },
});
