import type {Suggestion, TypeaheadProps} from '@/design-system';
import {SelectableItem, Spacer, Typeahead} from '@/design-system';
import type {PartialOmit} from '@/lib/tsHelpers';
import {actorDatumStore} from '@/stores/ActorDatum';
import {componentStore} from '@/stores/component';
import {contextStore} from '@/stores/context';
import {labelStore} from '@/stores/label';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import {forwardRef, memo, useEffect, useMemo, useRef} from 'react';

type PartialTypeaheadProps = PartialOmit<TypeaheadProps<TagSuggestion>, 'getSuggestions' | 'itemRenderer'>;
interface Props extends PartialTypeaheadProps {
  labelsToExclude?: number[];
  componentsToExclude?: number[];
}

export interface TagSuggestion extends Suggestion {
  type: EntityType;
}

export const TaskTagTypeahead = forwardRef<HTMLInputElement, Props>(function (
  {labelsToExclude, componentsToExclude, ...props},
  ref,
) {
  const unsubscribe = useRef<() => void>();
  const getSuggestions = useMemo(() => {
    return (value: string, resolve: (suggestions: TagSuggestion[]) => void) => {
      unsubscribe.current?.();

      // TODO: make this less ugly. We need to subscribe to multiple selections to watch for changes

      let labels: TagSuggestion[];
      let components: TagSuggestion[];
      let recentLabels: TagSuggestion[];
      let recentComponents: TagSuggestion[];

      // update complete result
      const update = () => {
        if (!labels || !components || !recentLabels || !recentComponents) return;
        let result = [...recentLabels, ...recentComponents];
        if (result.length < 20) {
          const other = [...labels, ...components].sort((a, b) => (a.text ?? '').localeCompare(b.text ?? ''));
          result = [...result, ...other].filter(
            (s, i, self) => self.findIndex((t) => t.id === s.id && t.type === s.type) === i,
          );
        }
        resolve(result);
      };

      // individual selection updates
      const onLabelsUpdate = (result?: TagSuggestion[]) => {
        labels = result ?? labels;
        update();
      };
      const onComponentsUpdate = (result?: TagSuggestion[]) => {
        components = result ?? components;
        update();
      };
      const onRecentComponentsUpdate = (result?: TagSuggestion[]) => {
        recentComponents = result ?? recentComponents;
        update();
      };
      const onRecentLabelsUpdate = (result?: TagSuggestion[]) => {
        recentLabels = result ?? recentLabels;
        update();
      };

      let recentComponentsUnsubscribe: () => void;
      let recentLabelsUnsubscribe: () => void;
      const onRecentsUpdate = (tags: string[] = []) => {
        recentComponentsUnsubscribe = componentStore.selectAndSubscribe(
          (s) =>
            tags
              .filter((value) => value.startsWith('C|'))
              .map((value) => s.getById(+value.slice(2)))
              .filter((c) => c != null)
              .filter((c) => !componentsToExclude?.includes(c.id))
              .map((c) => ({id: c.id, text: c.name, type: EntityType.Component})) as TagSuggestion[],
          onRecentComponentsUpdate,
        );
        recentLabelsUnsubscribe = labelStore.selectAndSubscribe(
          (s) =>
            tags
              .filter((value) => value.startsWith('L|'))
              .map((name) => s.getByName(name.slice(2)))
              .filter((l) => l != null)
              .map((l) => ({id: l.id, text: l.text, type: EntityType.Label})) as TagSuggestion[],
          onRecentLabelsUpdate,
        );
        update();
      };

      const recentsUnsubscribe = actorDatumStore.selectAndSubscribe((s) => s.getRecentTags(), onRecentsUpdate);

      const labelUnsubscribe = labelStore.selectAndSubscribe(
        (s) =>
          s
            .getList(Filters.labelFilter({projectId: contextStore.projectId, startsWith: value}))
            ?.map((l) => s.getById(l.id))
            ?.filter((l) => l != null)
            ?.map((l) => ({id: l.id, text: l.name, type: EntityType.Label})) as TagSuggestion[],
        onLabelsUpdate,
      );

      const componentUnsubscribe = componentStore.selectAndSubscribe(
        (s) =>
          s
            .getList(Filters.componentFilter({startsWith: value}))
            ?.filter((c) => !componentsToExclude?.includes(c.id))
            ?.map((c) => s.getById(c.id))
            ?.filter((c) => c != null)
            ?.map((c) => ({id: c.id, text: c.name, type: EntityType.Component})) as TagSuggestion[],
        onComponentsUpdate,
      );

      unsubscribe.current = () => {
        labelUnsubscribe();
        componentUnsubscribe();
        recentsUnsubscribe();
        recentComponentsUnsubscribe();
        recentLabelsUnsubscribe();
      };
    };
  }, [componentsToExclude]);

  const postProcess = (rawValue: string, suggestions: TagSuggestion[]) => {
    if (
      rawValue.length > 0 &&
      suggestions.every(
        (s) => s.type === EntityType.Label && s.text?.toLocaleLowerCase() !== rawValue.toLocaleLowerCase(),
      )
    ) {
      suggestions = [...suggestions, {type: EntityType.Label, id: 0, text: rawValue, rank: ''}];
    }

    // we have to exclude labels at the end to decide if we are going to allow the user to add a new tag
    return suggestions.filter((s) => s.type !== EntityType.Label || !labelsToExclude?.includes(s.id));
  };

  const inputFilter = (value: string) => value.replace(/( +-* *|-* +)+/g, '-');

  useEffect(() => {
    return () => unsubscribe.current?.();
  }, []);

  return (
    <Typeahead<TagSuggestion>
      ref={ref}
      getSuggestions={getSuggestions}
      postProcess={postProcess}
      inputFilter={inputFilter}
      {...props}
      itemRenderer={(item, onSelect) => <SuggestionItem key={item.id} item={item} onSelect={onSelect} />}
    />
  );
});

interface SuggestionProps {
  item: TagSuggestion;
  onSelect: (value: TagSuggestion) => void;
}

const SuggestionItem = memo(({item, onSelect}: SuggestionProps) => {
  const pillClassNames =
    item.type === EntityType.Component
      ? 'bg-purple-200/50 shadow-[inset_0_0_0_1px_rgba(var(--color-purple-800),0.5)]'
      : 'bg-gray-500/15';
  return (
    <SelectableItem
      key={item.type + ':' + item.id}
      textValue={item.text}
      onAction={() => onSelect(item)}
      className="gap-2 text-sm"
    >
      <div className={`h-3 w-5 rounded-full bg-gray-500/15 ${pillClassNames}`} />
      {item.text}
      {item.id === 0 && (
        <>
          <Spacer className="flex-grow" />
          <span className="text-xs text-gray-800/50">(new)</span>
        </>
      )}
    </SelectableItem>
  );
});
