import {Worker} from '@/app-service-worker/Worker';
import type {ExcludesUndefined} from '@/lib/containsUndefined';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '@/lib/dev';
import {componentStore} from '@/stores/component';
import {contextStore} from '@/stores/context';
import type {EntityStoreState} from '@/stores/lib/EntityStore';
import {EntityStore} from '@/stores/lib/EntityStore';
import {taskStore} from '@/stores/task';
import type {EntityId} from '@shared/EntityType';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import type {ActorDatum} from '@shared/models/ActorDatum';
import {ActorDatumType} from '@shared/models/ActorDatum';

export interface NotificationSettings {
  enabled: boolean;
  endpoint: string;
}

class ActorDatumStore extends EntityStore<EntityStoreState<ActorDatum>, ActorDatum> {
  constructor() {
    super(EntityType.ActorDatum, {
      byId: new Map(),
      byList: new Map(),
    });

    // subscribe to changes and make sure we keep the recent tasks/epics/components in memory
    let unsubscribes: (() => void)[] = [];
    this.selectAndSubscribe(
      (s) => {
        const recentTasks = s.getRecents(ActorDatumType.RecentTasks);
        const recentEpics = s.getRecents(ActorDatumType.RecentEpics);
        const recentComponents = s
          .getRecents(ActorDatumType.RecentTags)
          ?.filter((item) => item.toString().startsWith('C|'))
          ?.map((item) => +item.toString().slice(2));

        s.getNotificationSettings();

        return {recentTasks, recentEpics, recentComponents};
      },
      ({recentTasks, recentEpics, recentComponents}) => {
        unsubscribes.forEach((unsubscribe) => unsubscribe());
        unsubscribes = [];
        unsubscribes.push(
          taskStore.selectAndSubscribe(
            (s) => [...(recentTasks ?? []), ...(recentEpics ?? [])].map((id) => s.getById(id)),
            () => {},
          ),
        );

        unsubscribes.push(
          componentStore.selectAndSubscribe(
            (s) => recentComponents?.map((id) => s.getById(id)),
            () => {},
          ),
        );
      },
    );
  }

  async addToRecents(type: ActorDatumType, ids: EntityId | EntityId[] | string | string[]) {
    const list = (Array.isArray(ids) ? ids : [ids]).filter((id) => id != null).map((id) => id.toString());
    const key = `${contextStore.projectId}`;

    let existing: ActorDatum | null = (await this.await((s) => {
      const list = s.getList(Filters.actorDatumFilter({datumType: type, key}));
      if (list && list.length === 0) return null;
      return list && s.getById(list[0].id);
    })) as ExcludesUndefined<ActorDatum>;

    if (!existing) {
      Worker.postEntity(EntityType.ActorDatum, {
        type,
        key,
        value: JSON.stringify(list),
      });
    } else {
      Worker.mutateEntity({
        entity: EntityType.ActorDatum,
        id: existing.id.toString(),
        operations: {
          append: {
            value: {list},
          },
        },
      });
    }
  }
  getRecents(type: ActorDatumType): EntityId[] {
    const list = this.getList(Filters.actorDatumFilter({datumType: type, key: `${contextStore.projectId}`}));
    const item = list?.map((item) => this.getById(item.id))?.[0];
    return item ? (JSON.parse(item.value).map((id: string) => +id) as EntityId[]) : [];
  }
  getRecentTags(): string[] {
    const list = this.getList(
      Filters.actorDatumFilter({datumType: ActorDatumType.RecentTags, key: `${contextStore.projectId}`}),
    );
    const item = list?.map((item) => this.getById(item.id))?.[0];
    return item ? JSON.parse(item.value) : [];
  }
  getNotificationSettings(): NotificationSettings | null {
    const list = this.getList(Filters.actorDatumFilter({datumType: ActorDatumType.NotificationSettings}));
    const item = list?.map((item) => this.getById(item.id))?.[0];
    return item ? JSON.parse(item.value) : null;
  }
  updateNotificationSettings(settings: Partial<NotificationSettings>) {
    // get the existing settings
    const list = this.getList(Filters.actorDatumFilter({datumType: ActorDatumType.NotificationSettings}));
    const item = list?.map((item) => this.getById(item.id))?.[0];
    const existing = item ? JSON.parse(item.value) : null;
    const newSettings = {
      ...existing,
      ...settings,
    };

    if (item) {
      Worker.mutateEntity({
        entity: EntityType.ActorDatum,
        id: item.id.toString(),
        operations: {
          replace: {enabled: {value: newSettings.enabled ? 'true' : 'false'}},
        },
      });
    } else {
      Worker.postEntity(EntityType.ActorDatum, {
        type: ActorDatumType.NotificationSettings,
        key: '',
        value: JSON.stringify(newSettings),
      });
    }
  }
}

export const actorDatumStore = persistThroughHotReload('actorDatumStore', new ActorDatumStore());

exposeInDev({actorDatumStore});
import.meta.hot?.accept(reloadOnHotUpdate);
