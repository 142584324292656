import RemoveIcon from '@/assets/icon-remove.svg?react';
import {Image} from '@/components/Image';
import {taskStore} from '@/stores/task';
import {taskTypeStore} from '@/stores/taskType';
import React from 'react';
import {Button} from 'react-aria-components';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';
import {Link} from 'wouter';

export interface TaskLabelProps {
  id?: number;
  taskKey?: string;
  onRemove?: (id: number) => void;
  className?: string;
  fallbackText?: string | null;
  style?: 'full' | 'label';
  linkToTask?: boolean;
}

const styles = tv({
  slots: {
    container:
      'flex max-w-56 flex-row break-all rounded-sm bg-gray-600/15 p-[2px] pl-1 pr-2 text-xs font-medium text-gray-600',
    image: 'mr-2 h-4 w-4 flex-shrink-0',
    button: '-m-0.5 -mr-1.5 ml-0.5 p-0.5 opacity-30 transition-all hover:text-red-500 hover:opacity-100',
    label: 'line-clamp-1 text-gray-600',
  },
  variants: {
    style: {
      label: {
        container: 'bg-purple-300/30 px-1 py-0 font-medium text-purple-900/60',
        image: 'hidden',
      },
      full: {},
    },
    link: {
      true: {
        container: 'transition-all duration-100 hover:bg-gray-600/10 hover:brightness-105 hover:filter',
      },
    },
  },
});

export const TaskLabel: React.FC<TaskLabelProps> = ({
  id,
  taskKey,
  onRemove,
  className,
  fallbackText,
  style,
  linkToTask,
}) => {
  const task = taskStore.use(
    (s) => {
      if (id) return s.getById(id);
      if (taskKey) return s.getByKey(taskKey);
    },
    [id, taskKey],
  );
  const taskType = taskTypeStore.use((s) => s.getById(task?.typeId), [task?.typeId]);
  const {container, image, button, label} = styles({style, link: linkToTask});

  const content = (
    <>
      {<Image className={image()} id={taskType?.iconImageId} />}
      <span className={label()}>{task?.title ?? fallbackText}</span>
      {onRemove && !linkToTask && id && (
        <Button onPress={() => onRemove(id)} className={button()}>
          <RemoveIcon className="h-3.5 w-3.5" />
        </Button>
      )}
    </>
  );

  if (linkToTask) {
    return (
      <Link href={`/backlog/${taskKey ?? task?.key}`} className={twMerge(container(), className)}>
        {content}
      </Link>
    );
  }

  return <span className={twMerge(container(), className)}>{content}</span>;
};
