import {Worker} from '@/app-service-worker/Worker';
import {authStore} from '@/stores/auth';
import {inboxMessageStore} from '@/stores/inboxMessage';
import {MoStore, mutation} from '@/stores/lib/MoStore';
import {taskHistoryStore} from '@/stores/taskHistory';
import {taskReadStateStore} from '@/stores/taskReadState';
import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import {debounce} from '@shared/lib/debounce';
import {DevLog, devLog} from '@shared/lib/devLog';
import type {TaskHistoryListItem} from '@shared/models/FilteredEntityList';
import {createContext} from 'react';

interface HistoryState {
  history: TaskHistoryListItem[];
  readTo?: string | null;
  initialReadTo?: string | null;
  manualReadTo?: string;
  isLoaded?: boolean;
}

const DEFAULT_STATE: HistoryState = {
  history: [],
};

export class TaskHistoryUiStore extends MoStore<HistoryState> {
  private refs = new Map<number, HTMLDivElement>();
  private unsubs?: (() => void)[];
  private scrollView?: HTMLDivElement;
  private resizeObserver?: ResizeObserver;

  constructor(public taskId: number) {
    super(DEFAULT_STATE);
    this.unsubs = [
      taskHistoryStore.selectAndSubscribe(
        (s) => (isNaN(taskId) ? [] : s.getList(Filters.taskHistoryFilter({taskId}))),
        (history) => {
          this.setHistory(history ?? []);
        },
      ),
      taskReadStateStore.selectAndSubscribe(
        (s) => (isNaN(taskId) ? undefined : s.getById(taskId)),
        (readState) => {
          this.mutate((s) => {
            s.readTo = readState && readState.readTo;
            s.initialReadTo ??= s.readTo;
            devLog(DevLog.TaskHistoryStore, `Task ${taskId} readTo`, s.readTo);
          });
        },
      ),
    ];

    this.on('mutate', this.onMutate);
    this.onMutate();
  }

  destroy() {
    this.unsubs?.forEach((unsub) => unsub());
    this.refs.clear();
    this.resizeObserver?.disconnect();
    this.scrollView?.removeEventListener('scroll', this.updateReadState);
  }

  setRef(id: number, ref: HTMLDivElement | null) {
    if (ref) {
      this.refs.set(id, ref);
    } else {
      this.refs.delete(id);
    }
    this.onMutate();
  }

  delRef(id: number) {
    this.refs.delete(id);
    this.onMutate();
  }

  setScrollView(ref: HTMLDivElement) {
    // Clean up any existing listeners/observers
    this.scrollView?.removeEventListener('scroll', this.updateReadState);
    this.resizeObserver?.disconnect();

    this.scrollView = ref;

    if (this.scrollView) {
      // monitor scroll changes / resize
      this.scrollView.addEventListener('scroll', this.updateReadState);
      this.resizeObserver = new ResizeObserver(this.updateReadState);
      this.resizeObserver.observe(this.scrollView);
    }

    this.onMutate();
  }

  setReadAll() {
    this.set('manualReadTo', undefined);
    if (this.state.history.length > 0) {
      const readTo = this.state.history[this.state.history.length - 1].createdAt;
      this.set('readTo', readTo);
      this.updateReadTo(readTo);
    }
  }

  setManualReadTo(readTo: string) {
    this.mutate((s) => {
      s.manualReadTo = readTo;
      s.readTo = readTo;
      s.initialReadTo = s.readTo;
    });

    taskReadStateStore.patchReadAt(this.taskId, readTo);

    // if there is a corresponding inbox message to the history we've just marked read/unread, mark it as read/unread
    inboxMessageStore
      .await((s) => {
        const messageId = s.getIdByTaskId(this.taskId);
        return messageId !== undefined ? s.getById(messageId) : undefined;
      })
      .then((message) => {
        if (!message?.createdAt) return;
        if (message?.readAt && message.createdAt > readTo) {
          const now = new Date().toISOString();
          Worker.patchEntities(EntityType.InboxMessage, [{id: message?.id, readAt: null, updatedAt: now}]);
        } else if (!message?.readAt && message.createdAt <= readTo) {
          const now = new Date().toISOString();
          Worker.patchEntities(EntityType.InboxMessage, [{id: message?.id, readAt: now, updatedAt: now}]);
        }
      });
  }

  scrollToFirstUnread(animate = true) {
    if (this.state.readTo === undefined || !this.state.isLoaded || !this.scrollView) return;
    if (!this.state.readTo) {
      this.scrollView.scrollTo({top: this.scrollView.scrollHeight, behavior: animate ? 'smooth' : 'instant'});
    } else {
      const firstUnread =
        this.state.history.find((h) => h.createdAt > this.state.readTo!) ||
        this.state.history[this.state.history.length - 1];
      if (firstUnread && this.refs.get(firstUnread.id)) {
        const viewTop = this.scrollView.getBoundingClientRect().top;
        const itemTop = this.refs.get(firstUnread.id)!.getBoundingClientRect().top;
        this.scrollView.scrollTo({
          top: this.scrollView.scrollTop + itemTop - viewTop - 20,
          behavior: animate ? 'smooth' : 'instant',
        });
      }
    }
  }

  @mutation
  private setHistory(history: TaskHistoryListItem[]) {
    const prevHistory = this.state.history;
    const existingLength = prevHistory.length;
    this._state.history = history;

    if (existingLength > 0 && history.length > existingLength) {
      const lastItem = prevHistory[prevHistory.length - 1];
      // new items were added, auto-read any that are by the current user
      if (this.state.readTo && lastItem.createdAt <= this.state.readTo) {
        let i = existingLength;
        for (; i < history.length; i++) {
          if (history[i].authorId !== authStore.actorId) break;
        }
        if (i > existingLength) {
          i = Math.min(i, history.length - 1);
          console.log('marking new items as read', history[i].createdAt);
          const newReadTo = history[i].createdAt;
          if (this.state.initialReadTo === this.state.readTo) {
            this._state.initialReadTo = newReadTo;
          }
          this.updateReadTo(newReadTo);
        }
      }
    }
  }

  private updateReadState = () => {
    if (!this.state.isLoaded || this.state.manualReadTo) return;

    const readTo = this.state.readTo;
    if (readTo === undefined) return;

    const viewRect = this.scrollView?.getBoundingClientRect();
    if (!viewRect) return;
    const viewHeight = viewRect.height;

    let newReadTo: string | null = readTo;
    for (const item of this.state.history) {
      // skip items that are already read
      if (readTo && item.createdAt <= readTo) continue;

      if (this.refs.has(item.id)) {
        // check if the item is completely visible or mostly fills the scroll view
        const itemRect = this.refs.get(item.id)!.getBoundingClientRect();
        const itemHeight = itemRect.height;
        const threshold = Math.max(10, Math.min(itemHeight - 30, viewHeight * 0.5));
        if (itemRect.top < viewRect.top + viewRect.height - threshold && itemRect.bottom > viewRect.top + threshold) {
          newReadTo = item.createdAt;
        }
      }
    }

    if (newReadTo && (!readTo || (readTo && newReadTo > readTo))) {
      this.updateReadTo(newReadTo);
    }
  };

  private updateReadTo = debounce((newReadTo: string | undefined) => {
    if (newReadTo === undefined) return;
    devLog(DevLog.TaskHistoryStore, `updateReadTo (task ${this.taskId})`, newReadTo);
    this.set('readTo', newReadTo);
    taskReadStateStore.patchReadAt(this.taskId, newReadTo);

    // if there is a corresponding inbox message to the history we've just read, mark it as read
    inboxMessageStore
      .await((s) => {
        const messageId = s.getIdByTaskId(this.taskId);
        return messageId !== undefined ? s.getById(messageId) : undefined;
      })
      .then((message) => {
        if (message?.createdAt && !message?.readAt && message.createdAt <= newReadTo) {
          const now = new Date().toISOString();
          Worker.patchEntities(EntityType.InboxMessage, [{id: message?.id, readAt: now, updatedAt: now}]);
        }
      });
  }, 500);

  private onMutate = debounce(() => {
    if (!this.state.isLoaded && this.isLoaded(this.state.readTo)) {
      this.set('isLoaded', true);
    }
    this.updateReadState();
  }, 10);

  private isLoaded(to?: string | null) {
    if (!this.scrollView || !this.resizeObserver) {
      devLog(DevLog.TaskHistoryStore, 'no scroll view or resize observer');
      return false;
    }
    if (this.state.history.length === 0) {
      devLog(DevLog.TaskHistoryStore, 'no history');
      return false;
    }

    // check if all history items are loaded up to the given date
    for (let i = this.state.history.length - 1; i >= 0; i--) {
      if (to && this.state.history[i].createdAt < to) break;
      if (!this.refs.has(this.state.history[i].id)) {
        devLog(DevLog.TaskHistoryStore, 'history item not loaded', this.state.history[i]);
        return false;
      }
    }

    devLog(DevLog.TaskHistoryStore, 'all necessary history items loaded');
    return true;
  }
}

export const TaskHistoryContext = createContext<TaskHistoryUiStore>(new TaskHistoryUiStore(NaN));
