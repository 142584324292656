export interface ActorDatum {
  id: number;
  createdAt: string;
  updatedAt: string;
  accountId: number;
  actorId: number;
  type: ActorDatumType;
  key: string;
  value: string;
}

export enum ActorDatumType {
  RecentTasks = 'RecentTasks',
  RecentEpics = 'RecentEpics',
  RecentTags = 'RecentTags',
  NotificationSettings = 'NotificationSettings',
}
