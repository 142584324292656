import {Worker} from '@/app-service-worker/Worker';
import {Button} from '@/design-system/Button';
import {taskStore} from '@/stores/task';
import type {EditorState} from '@/text-editor/MoEditor';
import {emptyDocString, MoEditor} from '@/text-editor/MoEditor';
import {EntityType} from '@shared/EntityType';
import {defaultFieldId} from '@shared/models/FieldSchema';
import {DocumentFormat} from '@shared/models/Task';
import {useCallback, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props {
  taskId: number;
}

const buttonStyles = tv({
  base: 'z-10 my-2 rounded-sm bg-pink-600',
  variants: {
    isCancel: {
      true: 'bg-gray-300 text-gray-700',
    },
    disabled: {
      true: 'bg-gray-200 text-gray-400',
    },
  },
});

export const TaskDescriptionEditor: React.FC<Props> = ({taskId}) => {
  const {description, descriptionFormat} = taskStore.use(
    (s) => {
      const t = s.getById(taskId);
      if (t) return {description: t.description || emptyDocString, descriptionFormat: t.descriptionFormat};
      return {description: undefined, descriptionFormat: undefined};
    },
    [taskId],
  );
  const editorRef = useRef<EditorState | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [editorKey, setEditorKey] = useState(0);

  const handleSave = useCallback(() => {
    const doc = editorRef.current?.editor?.getJSON();
    if (!doc) throw new Error('Unable to get JSON from document editor');
    Worker.mutateEntity({
      entity: EntityType.Task,
      id: taskId.toString(),
      operations: {
        replace: {
          [defaultFieldId.Description.toString()]: {value: JSON.stringify(doc)},
        },
      },
    });
  }, [editorRef, taskId]);

  const handleCancel = useCallback(() => {
    setIsDirty(false);
    // TODO: support resetting the editor without recreating it
    setEditorKey((prev) => prev + 1);
  }, []);

  const handleEscape = useCallback(() => {
    editorRef.current?.editor?.commands.blur();
  }, []);

  return (
    <>
      <MoEditor
        forwardedRef={editorRef}
        doc={descriptionFormat === DocumentFormat.Momentum ? description : emptyDocString}
        taskId={taskId}
        onSave={handleSave}
        onDirtyChange={setIsDirty}
        onEscape={handleEscape}
        editorClassName="min-h-28 px-8 py-2"
        placeholder="Write a description..."
        key={editorKey}
        inline
        focusControls
      />
      <div
        className={twMerge(
          'sticky bottom-0 top-10 flex flex-row justify-start gap-2 px-2 transition-opacity',
          isDirty ? 'opacity-100' : 'pointer-events-none opacity-0',
        )}
      >
        <Button
          primary
          size="sm"
          className={buttonStyles({disabled: !isDirty})}
          onPress={handleSave}
          isDisabled={!isDirty}
        >
          Save
        </Button>
        <Button
          size="sm"
          className={buttonStyles({disabled: !isDirty, isCancel: true})}
          onPress={handleCancel}
          isDisabled={!isDirty}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
