import {EntityType} from '@shared/EntityType';
import type {IActorDatumFilter} from '@shared/filters/ActorDatumFilter';
import {ActorDatumFilter} from '@shared/filters/ActorDatumFilter';
import type {IActorFilter} from '@shared/filters/ActorFilter';
import {ActorFilter} from '@shared/filters/ActorFilter';
import type {IAttachmentFilter} from '@shared/filters/AttachmentFilter';
import {AttachmentFilter} from '@shared/filters/AttachmentFilter';
import type {IBoardFilter} from '@shared/filters/BoardFilter';
import {BoardFilter} from '@shared/filters/BoardFilter';
import type {IComponentFilter} from '@shared/filters/ComponentFilter';
import {ComponentFilter} from '@shared/filters/ComponentFilter';
import type {AnyEntityFilter, IEntityFilter} from '@shared/filters/EntityFilter';
import type {IInboxMessageFilter} from '@shared/filters/InboxMessageFilter';
import {InboxMessageFilter} from '@shared/filters/InboxMessageFilter';
import type {ILabelFilter} from '@shared/filters/LabelFilter';
import {LabelFilter} from '@shared/filters/LabelFilter';
import type {IProjectFilter} from '@shared/filters/ProjectFilter';
import {ProjectFilter} from '@shared/filters/ProjectFilter';
import type {ISprintFilter} from '@shared/filters/SprintFilter';
import {SprintFilter} from '@shared/filters/SprintFilter';
import type {IStatusFilter} from '@shared/filters/StatusFilter';
import {StatusFilter} from '@shared/filters/StatusFilter';
import type {ITaskFilter} from '@shared/filters/TaskFilter';
import {TaskFilter} from '@shared/filters/TaskFilter';
import type {ITaskHistoryFilter} from '@shared/filters/TaskHistoryFilter';
import {TaskHistoryFilter} from '@shared/filters/TaskHistoryFilter';
import type {ITaskLinkFilter} from '@shared/filters/TaskLinkFilter';
import {TaskLinkFilter} from '@shared/filters/TaskLinkFilter';
import type {ITaskLinkTypeFilter} from '@shared/filters/TaskLinkTypeFilter';
import {TaskLinkTypeFilter} from '@shared/filters/TaskLinkTypeFilter';
import type {ITaskTypeFilter} from '@shared/filters/TaskTypeFilter';
import {TaskTypeFilter} from '@shared/filters/TaskTypeFilter';
import type {IWipFilter} from '@shared/filters/WipFilter';
import {WipFilter} from '@shared/filters/WipFilter';

export type OmitType<T> = Omit<T, 'type'>;

/**
 * Ensures that only one instance of each filter is created.
 * This allows us to store and compare filters by reference.
 */
class FiltersManager {
  private filters: Map<string, AnyEntityFilter> = new Map();

  public fromObject(filter: IEntityFilter): AnyEntityFilter {
    switch (filter.type) {
      case EntityType.Actor:
        return this.actorFilter(filter as IActorFilter);
      case EntityType.Task:
        return this.taskFilter(filter as ITaskFilter);
      case EntityType.InboxMessage:
        return this.inboxMessageFilter(filter as IInboxMessageFilter);
      case EntityType.TaskHistory:
        return this.taskHistoryFilter(filter as ITaskHistoryFilter);
      case EntityType.Sprint:
        return this.sprintFilter(filter as ISprintFilter);
      case EntityType.Status:
        return this.statusFilter(filter as IStatusFilter);
      case EntityType.TaskType:
        return this.taskTypeFilter(filter as ITaskTypeFilter);
      case EntityType.Component:
        return this.componentFilter(filter as IComponentFilter);
      case EntityType.Label:
        return this.labelFilter(filter as ILabelFilter);
      case EntityType.TaskLink:
        return this.taskLinkFilter(filter as ITaskLinkFilter);
      case EntityType.TaskLinkType:
        return this.taskLinkTypeFilter(filter as ITaskLinkTypeFilter);
      case EntityType.Attachment:
        return this.attachmentFilter(filter as IAttachmentFilter);
      case EntityType.Project:
        return this.projectFilter(filter as IProjectFilter);
      case EntityType.Board:
        return this.boardFilter(filter as IBoardFilter);
      case EntityType.Wip:
        return this.wipFilter(filter as IWipFilter);
      case EntityType.ActorDatum:
        return this.actorDatumFilter(filter as IActorDatumFilter);

      default:
        throw new Error(`Unknown filter type: ${filter.type} // ${JSON.stringify(filter)}`);
    }
  }

  public actorFilter(filter: OmitType<IActorFilter>) {
    return this.getOrAdd(new ActorFilter(filter)) as ActorFilter;
  }

  public taskFilter(filter: OmitType<ITaskFilter>) {
    return this.getOrAdd(new TaskFilter(filter)) as TaskFilter;
  }

  public inboxMessageFilter(filter: OmitType<IInboxMessageFilter>) {
    return this.getOrAdd(new InboxMessageFilter(filter)) as InboxMessageFilter;
  }

  public taskHistoryFilter(filter: OmitType<ITaskHistoryFilter>) {
    return this.getOrAdd(new TaskHistoryFilter(filter)) as TaskHistoryFilter;
  }

  public sprintFilter(filter: OmitType<ISprintFilter>) {
    return this.getOrAdd(new SprintFilter(filter)) as SprintFilter;
  }

  public statusFilter(filter: OmitType<IStatusFilter>) {
    return this.getOrAdd(new StatusFilter(filter)) as StatusFilter;
  }

  public taskTypeFilter(filter: OmitType<ITaskTypeFilter>): TaskTypeFilter {
    return this.getOrAdd(new TaskTypeFilter(filter)) as TaskTypeFilter;
  }

  public componentFilter(filter: OmitType<IComponentFilter>) {
    return this.getOrAdd(new ComponentFilter(filter)) as ComponentFilter;
  }

  public labelFilter(filter: OmitType<ILabelFilter>) {
    return this.getOrAdd(new LabelFilter(filter)) as LabelFilter;
  }

  public taskLinkFilter(filter: OmitType<ITaskLinkFilter>) {
    return this.getOrAdd(new TaskLinkFilter(filter)) as TaskLinkFilter;
  }

  public taskLinkTypeFilter(filter: OmitType<ITaskLinkTypeFilter>) {
    return this.getOrAdd(new TaskLinkTypeFilter(filter)) as TaskLinkTypeFilter;
  }

  public attachmentFilter(filter: OmitType<IAttachmentFilter>) {
    return this.getOrAdd(new AttachmentFilter(filter)) as AttachmentFilter;
  }

  public projectFilter(filter: OmitType<IProjectFilter>) {
    return this.getOrAdd(new ProjectFilter(filter)) as ProjectFilter;
  }

  public boardFilter(filter: OmitType<IBoardFilter>) {
    return this.getOrAdd(new BoardFilter(filter)) as BoardFilter;
  }

  public wipFilter(filter: OmitType<IWipFilter>) {
    return this.getOrAdd(new WipFilter(filter)) as WipFilter;
  }

  public actorDatumFilter(filter: OmitType<IActorDatumFilter>) {
    return this.getOrAdd(new ActorDatumFilter(filter)) as ActorDatumFilter;
  }

  private getOrAdd(filter: AnyEntityFilter): AnyEntityFilter {
    const id = filter.toString();
    const existing = this.filters.get(id) as AnyEntityFilter | undefined;
    if (existing) {
      return existing;
    }
    this.filters.set(id, filter);
    return filter;
  }
}

export const Filters = new FiltersManager();
