import type {AnyEntity, EntityType} from '@shared/EntityType';
import type {FilteredEntityListItem} from '@shared/models/FilteredEntityList';
import type {DB} from 'src-sw/db/db';

export type PublicPropertiesOf<T> = {
  [K in keyof T as T[K] extends (...args: never[]) => unknown ? never : K]: T[K];
};
export type FilterInterfaceFrom<T> = Exclude<PublicPropertiesOf<T>, 'type'>;
export type RankComparator = (a: string, b: string) => 1 | 0 | -1;
export const RANK_COMPARATOR_ASC: RankComparator = (a, b) => (a > b ? 1 : a < b ? -1 : 0);
export const RANK_COMPARATOR_DESC: RankComparator = (a, b) => (a < b ? 1 : a > b ? -1 : 0);

export abstract class EntityFilter<T extends IEntityFilter> {
  abstract readonly type: EntityType;

  public abstract toString(): string;

  public abstract matchWithRank(db: DB, entity: AnyEntity): Promise<[boolean, string, RankComparator]>;

  public static toListItem(entity: AnyEntity, rank?: string): FilteredEntityListItem {
    // for all the properties we care about, the value will be a string or number
    const e: Record<string, string | number> = entity as never;
    return {
      id: entity.id,
      rank: rank ?? ((e.rank ?? e.name ?? e.title ?? e.createdAt) as string) ?? entity.id.toString(),
      text: (e.name ?? e.title ?? e.fileName) as string,
      icon: (e.imageId ?? e.iconImageId ?? e.titleIconId ?? e.avatarId) as number,
    };
  }

  public async filterCollection(db: DB, collection: AnyEntity[]): Promise<FilteredEntityListItem[]> {
    const map = new Map<number, AnyEntity>();
    const list: FilteredEntityListItem[] = [];
    const promises: Promise<void>[] = [];
    let comparator: RankComparator | undefined;

    for (const entity of collection) {
      map.set(entity.id, entity);
      promises.push(
        this.matchWithRank(db, entity).then(([matches, rank, compareFn]) => {
          comparator = compareFn;
          if (matches) {
            list.push((this.constructor as typeof EntityFilter).toListItem(entity, rank));
          }
        }),
      );
    }

    await Promise.all(promises);
    return list.sort((a, b) => comparator?.(a.rank, b.rank) ?? a.rank.localeCompare(b.rank));
  }

  public equals(other: EntityFilter<T>): boolean {
    return this.toString() === other.toString();
  }

  public equalsWildcard(other: string): boolean {
    const src = this.toString().split('|');
    const dst = other.toString().split('|');
    return (
      src.length === dst.length &&
      src.every(
        (s, i) =>
          s === dst[i] ||
          s === '*' ||
          (s === '+' && dst[i].length > 0) ||
          dst[i] === '*' ||
          (dst[i] === '+' && s.length > 0),
      )
    );
  }

  public toObject() {
    return {...this};
  }
}

export type AnyEntityFilter = EntityFilter<never>;

export type IEntityFilter = {
  type: EntityType;
};
