import {debounce} from '@shared/lib/debounce';
import {isStateEqual} from '@shared/lib/isStateEqual';
import {useEffect, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';

interface Props {
  className?: string;
  children: React.ReactNode;
  follow?: React.RefObject<HTMLDivElement>;
  fixedHeight?: number;
}

export const Follow: React.FC<Props> = ({className, children, follow, fixedHeight}) => {
  const ref = useRef<HTMLDivElement>(null);
  const getTarget = () => follow?.current ?? ref.current?.parentElement;
  const [isVisible, setIsVisible] = useState(false);

  function calculateOffsetAndSetWidth() {
    const parent = getTarget();
    const grandparent = parent?.parentElement;
    if (!parent || !grandparent) return;

    ref.current && (ref.current.style.width = `${parent.clientWidth}px`);

    const rect = parent.getBoundingClientRect();
    const parentRect = grandparent.getBoundingClientRect();
    rect.x -= parentRect.x;
    rect.y -= parentRect.y;
    return {x: rect.x, y: rect.y};
  }

  const offset = calculateOffsetAndSetWidth();

  useEffect(() => {
    const target = getTarget();
    if (!target || !target.parentElement) return;
    if (!fixedHeight && ref.current) {
      target.style.height = `${ref.current.clientHeight}px`;
    }
    setIsVisible(true);
  }, [getTarget()]);

  useEffect(() => {
    if (!isVisible) return;
    let offsetCache = offset;

    const target = getTarget();
    const targetParent = target?.parentElement;
    if (ref.current && target && targetParent) {
      ref.current.style.transitionProperty = 'transform';
      ref.current.style.width = `${target.clientWidth}px`;
      function reposition() {
        if (!ref.current || !targetParent || !target) return;
        const rect = target.getBoundingClientRect();
        const parentRect = targetParent.getBoundingClientRect();
        rect.x -= parentRect.x;
        rect.y -= parentRect.y;

        const newOffset = {x: rect.x, y: rect.y};
        if (isStateEqual(offsetCache, newOffset)) return;
        offsetCache = newOffset;
        ref.current.style.transform = `translate(${offsetCache.x}px, ${offsetCache.y}px)`;
      }
      const observer = new MutationObserver(reposition);
      observer.observe(targetParent, {
        childList: true,
        attributes: true,
        attributeFilter: ['style'],
        subtree: true,
      });

      function handleParentResize() {
        if (!ref.current || !targetParent || !target) return;
        ref.current.style.width = `${target.clientWidth}px`;
      }
      const targetObserver = new ResizeObserver(handleParentResize);
      targetObserver.observe(target);

      let resizeObserver: ResizeObserver | undefined;
      const updateParentHeight = debounce(function () {
        if (!ref.current || !target) return;
        target.style.height = `${ref.current.clientHeight}px`;
      }, 0);

      if (!fixedHeight) {
        resizeObserver = new ResizeObserver(updateParentHeight);
        resizeObserver.observe(ref.current);

        updateParentHeight();
      }
      reposition();

      return () => {
        observer.disconnect();
        resizeObserver?.disconnect();
        targetObserver.disconnect();
        updateParentHeight.cancel();
      };
    }
  }, [isVisible, ref.current, getTarget()]);

  const offsets = `absolute top-0 transition-transform left-0 duration-300`;

  return (
    <div
      className={twMerge(className, offsets, isVisible ? 'visible' : 'invisible')}
      ref={ref}
      style={{
        transitionProperty: 'none',
        transform: offset ? `translate(${offset.x}px, ${offset.y}px)` : '',
        height: fixedHeight ? `${fixedHeight}px` : undefined,
      }}
    >
      {children}
    </div>
  );
};
