import {Button} from '@/design-system';
import {SuspenseLoader} from '@/design-system/SuspenseLoader';
import type {ToastState} from '@/stores/toast';
import {ToastStatus, toastStore} from '@/stores/toast';
import {CheckIcon, InfoIcon, XIcon} from 'lucide-react';
import {createPortal} from 'react-dom';
import {tv} from 'tailwind-variants';

const selector = (s: typeof toastStore) => s.getToasts().slice(0, 5);

export function Toasts() {
  const toasts = toastStore.use(selector, []);
  if (toasts.length === 0) return null;
  return createPortal(
    <div
      className="fixed bottom-5 left-5 right-5 z-50 flex h-0 flex-col items-end justify-end gap-2"
      role="region"
      aria-label={`${toasts.length} notification${toasts.length === 1 ? '' : 's'}`}
    >
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>,
    document.body,
  );
}

interface ToastProps extends ToastState {}

const styles = tv({
  base: 'group/toast relative min-w-64 max-w-[max(50vw,50rem)] rounded-md border border-gray-100 bg-white px-4 py-2 shadow-lg duration-300 animate-in fade-in slide-in-from-right',
  variants: {
    exiting: {
      true: 'animate-out fade-out slide-out-to-right fill-mode-forwards',
    },
    success: {
      true: 'text-green-600',
    },
    error: {
      true: 'text-red-600',
    },
    info: {
      true: 'text-blue-600',
    },
  },
});

function Toast({id, exiting}: ToastProps) {
  const toast = toastStore.use((s) => s.getToast(id), [id]);
  if (!toast) return null;

  return (
    <div
      role="alertdialog"
      aria-modal="false"
      aria-labelledby={id}
      className={styles({exiting, [toast.type]: true})}
      tabIndex={0}
    >
      <div className="flex flex-row items-center gap-2">
        <div>
          {toast.type === ToastStatus.Success && <CheckIcon className="h-5 w-5" />}
          {toast.type === ToastStatus.Error && <XIcon className="h-5 w-5" />}
          {toast.type === ToastStatus.Info && <InfoIcon className="h-5 w-5" />}
        </div>
        <div id={id} role="alert" className="line-clamp-1 flex-grow break-all text-sm font-semibold text-gray-600">
          {toast.title}
        </div>
        {toast.dismissable && (
          <Button
            className="absolute right-0 top-0 flex h-5 w-5 -translate-y-1/3 translate-x-1/3 items-center justify-center bg-gray-50 p-0 text-black opacity-0 shadow hover:bg-white hover:shadow-md group-hover/toast:opacity-100"
            rounded
            onPress={() => toastStore.remove(id)}
          >
            <XIcon className="h-3 w-3" />
          </Button>
        )}
      </div>
      {toast.message && (
        <div className="mt-2 flex flex-row items-center gap-2 py-1 text-sm text-gray-500">
          <SuspenseLoader>{toast.message}</SuspenseLoader>
        </div>
      )}
    </div>
  );
}
