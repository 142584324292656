import type {EventedEvents} from '@/lib/Evented';
import {Evented} from '@/lib/Evented';
import {isStateEqual} from '@shared/lib/isStateEqual';
import autobind from 'autobind-decorator';
import {createContext, useEffect, useRef, useState} from 'react';

export interface ScrollViewState {
  scrollView: HTMLDivElement | null;
  scrollTop: number;
  scrollMax: number;
  scrollViewHeight: number;
}

interface StoreEvents extends EventedEvents {
  mutate: (state: ScrollViewState) => void;
}

export class ScrollViewStore extends Evented<StoreEvents> {
  private _state: ScrollViewState = {scrollView: null, scrollTop: 0, scrollMax: 0, scrollViewHeight: 0};
  private resizeObserver: ResizeObserver | null = null;

  public get state() {
    return this._state;
  }

  setScrollView(scrollView: HTMLDivElement | null) {
    if (this._state.scrollView) {
      this._state.scrollView.removeEventListener('scroll', this.onScroll);
      this.resizeObserver?.unobserve(this._state.scrollView);
    }
    this._state.scrollView = scrollView;
    scrollView?.addEventListener('scroll', this.onScroll);

    if (scrollView) {
      this.resizeObserver = new ResizeObserver(() => {
        if (this.state.scrollView) {
          this._state.scrollViewHeight = this.state.scrollView.clientHeight;
          this.onScroll();
        }
      });
      this.resizeObserver?.observe(scrollView);
      this.onScroll();
    } else {
      this._state.scrollTop = 0;
      this._state.scrollMax = 0;
      this._state.scrollViewHeight = 0;
      this.emit('mutate', this._state);
    }
  }

  getScrollView() {
    return this.state.scrollView;
  }

  @autobind
  onScroll() {
    if (!this.state.scrollView) return;
    this._state.scrollTop = this.state.scrollView.scrollTop;
    this._state.scrollMax = this.state.scrollView.scrollHeight - this.state.scrollView.clientHeight;
    this.emit('mutate', this._state);
  }

  use<T>(selector: (store: ScrollViewStore) => T, deps: React.DependencyList = [selector]) {
    const [state, setState] = useState(selector(this));
    const prevState = useRef(state);

    useEffect(() => {
      return this.on('mutate', () => {
        const newState = selector(this);
        if (!isStateEqual(newState, prevState.current)) {
          prevState.current = newState;
          setState(newState);
        }
      });
    }, deps);

    return state;
  }
}

export const ScrollViewContext = createContext<ScrollViewStore | null>(null);
