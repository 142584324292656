import {ActorAvatar} from '@/components/actor/ActorAvatar';
import {FilterableList, SelectableItem} from '@/design-system';
import {actorStore} from '@/stores/actor';
import type {ActorFilter} from '@shared/filters/ActorFilter';
import {Filters} from '@shared/filters/Filters';
import {ActorType} from '@shared/models/Actor';
import {useState} from 'react';

interface Props {
  filter?: ActorFilter;
  onAction?: (actorId: number) => void;
  emptyName?: string;
}

export const ActorList: React.FC<Props> = ({filter, emptyName = 'None', onAction}) => {
  const [filterString, setFilterString] = useState('');
  const items = actorStore.use(
    (s) =>
      s
        .getList(filter ?? Filters.actorFilter(filterString ? {} : {active: true, actorType: [ActorType.Person]}))
        ?.map((r) => ({
          id: r.id,
          text: r.text!,
          avatarId: r.icon,
        })) ?? [],
    [!filterString],
  );

  return (
    <FilterableList items={items} aria-label="Teammates list" onAction={onAction} onFilterChange={setFilterString}>
      {(item) => (
        <SelectableItem textValue={item.text} key={item.id}>
          <ActorAvatar
            actorId={item.id}
            showName
            size="sm"
            style="normal"
            emptyName={emptyName}
            name={item.text}
            avatarId={item.avatarId}
          />
        </SelectableItem>
      )}
    </FilterableList>
  );
};
