import {EntityType} from '@shared/EntityType';
import {Filters} from '@shared/filters/Filters';
import type {InboxMessage} from '@shared/models/InboxMessage';
import {exposeInDev, persistThroughHotReload, reloadOnHotUpdate} from '../lib/dev';
import type {EntityStoreState} from './lib/EntityStore';
import {EntityStore} from './lib/EntityStore';

export const inboxMessageStore = persistThroughHotReload(
  'inboxMessageStore',
  new EntityStore<EntityStoreState<InboxMessage>, InboxMessage>(EntityType.InboxMessage, {
    byId: new Map(),
    byList: new Map(),
  }).extend(function () {
    return {
      getIdByTaskId: (taskId: number) => {
        const list = this.getList(Filters.inboxMessageFilter({taskId}));
        if (list === undefined) return undefined;
        return list[0]?.id ?? null;
      },
    };
  }),
);
exposeInDev({inboxMessageStore});
import.meta.hot?.accept(reloadOnHotUpdate);
