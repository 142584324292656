import {Image} from '@/components/Image';
import type {SelectableItemProps} from '@/design-system';
import {SelectableItem} from '@/design-system';
import type {Suggestion, TypeaheadProps} from '@/design-system/Typeahead';
import {Typeahead} from '@/design-system/Typeahead';
import {TaskSuggestionType, useTaskSuggestions} from '@/hooks/useTaskSuggestions';
import type {PartialOmit} from '@/lib/tsHelpers';
import {NOT_DONE_STATUS_CATEGORIES} from '@shared/models/Status';
import {forwardRef, useCallback} from 'react';

type PartialTypeaheadProps = PartialOmit<TypeaheadProps<Suggestion>, 'getSuggestions' | 'itemRenderer'>;
interface Props extends PartialTypeaheadProps {
  excludeTaskIds?: number[];
  excludeNotDone?: boolean;
}

export const TaskTypeahead = forwardRef<HTMLInputElement, Props>(function (
  {excludeTaskIds, excludeNotDone, ...props},
  ref,
) {
  const getSuggestions = useTaskSuggestions({
    baseFilter: {
      statusCategory: excludeNotDone ? NOT_DONE_STATUS_CATEGORIES : undefined,
    },
    excludeTaskIds,
    type: TaskSuggestionType.TaskOrEpic,
  });

  return (
    <Typeahead
      ref={ref}
      getSuggestions={getSuggestions}
      itemRenderer={(item, onAction) => {
        return <SelectableTaskItem key={item.id} item={item} textValue={item.text} onAction={onAction} />;
      }}
      {...props}
      crossOffset={-36}
    />
  );
});

const SelectableTaskItem: React.FC<
  {item: Suggestion; onAction: (item: Suggestion) => void} & Omit<SelectableItemProps, 'onAction'>
> = ({item, onAction, ...props}) => {
  const actionHandler = useCallback(() => {
    onAction(item);
  }, [onAction, item]);

  return (
    <SelectableItem textValue={item.text} {...props} onAction={actionHandler} className="max-w-96 flex-grow text-sm">
      <div className="mr-0.5 h-4 w-4 flex-shrink-0">
        <Image id={item.icon} />
      </div>
      <div className="line-clamp-1 flex-grow">{item.text}</div>
      <div className="flex-shrink-0 pl-2 text-xs text-gray-500/50">{item?.key}</div>
    </SelectableItem>
  );
};
