import type {EntityId} from '@shared/EntityType';
import type {AnyEntityFilter} from '@shared/filters/EntityFilter';
import type {SubscriptionUpdate} from '@shared/SubscriptionManager';
import type {WSResponse} from '@shared/webSocket/interface';
import type {EntityType} from '../EntityType';

export type SWMessage =
  | SWRegisterWindow
  | SWCloseWindow
  | SWSubscribeToEntities
  | SWUnsubscribeFromEntities
  | SWSubscribeToEntityList
  | SWUnsubscribeFromEntityList
  | SWBatchSubscriptionUpdate
  | SWPing
  | SWReady
  | SWUpdate
  | WSResponse;

export enum SWMessageType {
  // windows
  RegisterWindow = 'SW.registerWindow',
  CloseWindow = 'SW.closeWindow',
  // subscriptions (outbound)
  SubscribeToEntities = 'SW.subscribeToEntities',
  UnsubscribeFromEntities = 'SW.unsubscribeFromEntities',
  SubscribeToEntityList = 'SW.subscribeToEntityList',
  UnsubscribeFromEntityList = 'SW.unsubscribeFromEntityList',
  // subscription updates (inbound)
  BatchUpdate = 'SW.batchUpdate',
  EntitiesUpdate = 'SW.entitiesUpdate',
  EntityListUpdate = 'SW.entityListUpdate',
  EntitiesDelete = 'SW.entitiesDelete',
  // utilities
  Ping = 'SW.ping',
  Ready = 'SW.ready',
  Update = 'SW.update',
  // mutations
  PostEntity = 'postEntity',
  PutEntities = 'putEntities',
  PatchEntities = 'patchEntities',
  MutateEntity = 'mutateEntity',
  // responses
  Response = 'result',
  Error = 'error',
}

interface SWMessageBase {
  type: SWMessageType;
  requestId?: string;
}

export interface SWPing extends SWMessageBase {
  type: SWMessageType.Ping;
}

export interface SWRegisterWindow extends SWMessageBase {
  type: SWMessageType.RegisterWindow;
  port: MessagePort;
  jwt: string;
}

export interface SWCloseWindow extends SWMessageBase {
  type: SWMessageType.CloseWindow;
}

export interface SWReady extends SWMessageBase {
  type: SWMessageType.Ready;
  windowId: number;
  buildVersion: string;
}

export interface SWUpdate extends SWMessageBase {
  type: SWMessageType.Update;
}

export function isReadyMessage(message: SWMessage): message is SWReady {
  return message.type === SWMessageType.Ready;
}

export function isPingMessage(message: SWMessage): message is SWPing {
  return message.type === SWMessageType.Ping;
}

export function isRegisterWindow(message: SWMessage): message is SWRegisterWindow {
  return message.type === SWMessageType.RegisterWindow;
}

export function isCloseWindow(message: SWMessage): message is SWCloseWindow {
  return message.type === SWMessageType.CloseWindow;
}

export function isUpdateMessage(message: SWMessage): message is SWUpdate {
  return message.type === SWMessageType.Update;
}

// subscriptions

export interface SWSubscribeToEntities extends SWMessageBase {
  type: SWMessageType.SubscribeToEntities;
  entityType: EntityType;
  entities: EntityId[];
}

export interface SWUnsubscribeFromEntities extends SWMessageBase {
  type: SWMessageType.UnsubscribeFromEntities;
  entityType: EntityType;
  entities: EntityId[];
}

export interface SWSubscribeToEntityList extends SWMessageBase {
  type: SWMessageType.SubscribeToEntityList;
  entityType: EntityType;
  entityList: AnyEntityFilter[];
}

export interface SWUnsubscribeFromEntityList extends SWMessageBase {
  type: SWMessageType.UnsubscribeFromEntityList;
  entityType: EntityType;
  entityList: AnyEntityFilter[];
}

export function isSubscribeToEntities(message: unknown): message is SWSubscribeToEntities {
  return (message as SWMessage).type === SWMessageType.SubscribeToEntities;
}

export function isUnsubscribeFromEntities(message: unknown): message is SWUnsubscribeFromEntities {
  return (message as SWMessage).type === SWMessageType.UnsubscribeFromEntities;
}

export function isSubscribeToEntityList(message: unknown): message is SWSubscribeToEntityList {
  return (message as SWMessage).type === SWMessageType.SubscribeToEntityList;
}

export function isUnsubscribeFromEntityList(message: unknown): message is SWUnsubscribeFromEntityList {
  return (message as SWMessage).type === SWMessageType.UnsubscribeFromEntityList;
}

// update

export interface SWBatchSubscriptionUpdate extends SWMessageBase {
  type: SWMessageType.BatchUpdate;
  updates: SubscriptionUpdate[];
}

export function isBatchSubscriptionUpdate(message: unknown): message is SWBatchSubscriptionUpdate {
  return (message as SWMessage).type === SWMessageType.BatchUpdate;
}
