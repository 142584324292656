import type {SubscriptionManager} from '@shared/SubscriptionManager';
import {EventType} from '@shared/SubscriptionManager';
import type {
  SWSubscribeToEntities,
  SWSubscribeToEntityList,
  SWUnsubscribeFromEntities,
  SWUnsubscribeFromEntityList,
} from '@shared/serviceWorker/interface';
import {SWMessageType} from '@shared/serviceWorker/interface';

export function createSubscriptionRelay(subsManager: SubscriptionManager, port: MessagePort) {
  subsManager.on(EventType.OnFirst, (subscription) => {
    if (subscription.id != undefined) {
      port.postMessage({
        type: SWMessageType.SubscribeToEntities,
        entityType: subscription.type,
        entities: [subscription.id],
      } as SWSubscribeToEntities);
    } else {
      port.postMessage({
        type: SWMessageType.SubscribeToEntityList,
        entityType: subscription.type,
        entityList: [subscription.filter],
      } as SWSubscribeToEntityList);
    }
  });

  subsManager.on(EventType.AfterLast, (subscription) => {
    if (subscription.id != undefined) {
      port.postMessage({
        type: SWMessageType.UnsubscribeFromEntities,
        entityType: subscription.type,
        entities: [subscription.id],
      } as SWUnsubscribeFromEntities);
    } else {
      port.postMessage({
        type: SWMessageType.UnsubscribeFromEntityList,
        entityType: subscription.type,
        entityList: [subscription.filter],
      } as SWUnsubscribeFromEntityList);
    }
  });

  // handle existing subscriptions
  subsManager.allSubscriptions.forEach((subscriptions, type) => {
    if (subscriptions.byId.size > 0) {
      port.postMessage({
        type: SWMessageType.SubscribeToEntities,
        entityType: type,
        entities: [...subscriptions.byId.keys()],
      });
    }
    if (subscriptions.byFilter.size > 0) {
      port.postMessage({
        type: SWMessageType.SubscribeToEntityList,
        entityType: type,
        entityList: [...subscriptions.byFilter.keys()],
      });
    }
  });
}
