import {Spacer} from '@/design-system';
import type {ErrorInfo} from 'react';

interface Props {
  error: Error;
  errorInfo?: ErrorInfo | null;
  clearError: () => void;
}

export function ErrorPage({error, errorInfo, clearError}: Props) {
  return (
    <div className="h-screen w-full overflow-auto bg-gray-200 text-gray-300">
      <div
        className="flex h-screen w-full flex-col items-center justify-around text-5xl font-black"
        style={{
          textShadow: `0 0 .2rem #EEE7, 0 0 0 #0002`,
          color: 'transparent',
          backgroundColor: '#DDD',
          backgroundClip: 'text',
        }}
      >
        <Spacer />
        <div>Oops</div>
        <div className="rotate-45 text-8xl">;(</div>
        <div>Something went wrong</div>
        <div className="flex flex-col items-center gap-3 text-2xl hover:text-blue-300">
          <a href="/" className="underline">
            Refresh to try again
          </a>
          <div>or</div>
          <a className="cursor-pointer text-pink-500 underline" onClick={clearError}>
            attempt to continue at your own peril
          </a>
        </div>
        <Spacer />
      </div>
      <pre className="border-t-1 border-red-700 bg-red-100 p-10 text-sm text-red-700">
        {error?.message}
        {DEV && error?.stack}
        {DEV && errorInfo?.componentStack}
      </pre>
    </div>
  );
}
