import {composeTailwindRenderProps} from '@/design-system/examples/utils';
import {ScrollView} from '@/design-system/ScrollView';
import {getInteractionModality} from '@react-aria/interactions';
import {focusWithoutScrolling, getOwnerDocument, runAfterTransition} from '@react-aria/utils';
import type {CollectionElement, FocusableElement} from '@react-types/shared';
import {FilterIcon} from 'lucide-react';
import React, {useCallback, useRef, useState} from 'react';
import {FocusScope} from 'react-aria';
import type {ListBoxProps} from 'react-aria-components';
import {Input, ListBox} from 'react-aria-components';
import {tv} from 'tailwind-variants';

/**
 * A utility function that focuses an element while avoiding undesired side effects such
 * as page scrolling and screen reader issues with CSS transitions.
 */
export function focusSafely(element: FocusableElement) {
  // If the user is interacting with a virtual cursor, e.g. screen reader, then
  // wait until after any animated transitions that are currently occurring on
  // the page before shifting focus. This avoids issues with VoiceOver on iOS
  // causing the page to scroll when moving focus if the element is transitioning
  // from off the screen.
  const ownerDocument = getOwnerDocument(element);
  if (getInteractionModality() === 'virtual') {
    let lastFocusedElement = ownerDocument.activeElement;
    runAfterTransition(() => {
      // If focus did not move and the element is still in the document, focus it.
      if (ownerDocument.activeElement === lastFocusedElement && element.isConnected) {
        focusWithoutScrolling(element);
      }
    });
  } else {
    focusWithoutScrolling(element);
  }
}

export interface Item {
  text?: string;
  id?: string | number;
  children?: Item[];
}

export interface FilterableListProps<T extends Item>
  extends Omit<ListBoxProps<T>, 'layout' | 'orientation' | 'children'> {
  children: (item: T) => CollectionElement<T>;
  placeholder?: string;
  items: Iterable<T>;
  onAction?: (key: T['id']) => void;
  onFilterChange?: (value: string) => void;
}

const inputStyles = tv({
  base: 'w-full rounded-md px-2 py-1 pl-8 text-sm outline outline-gray-100',
});

export function FilterableList<T extends Item>({
  placeholder,
  children,
  items,
  onFilterChange,
  onAction,
  ...props
}: FilterableListProps<T>) {
  const [value, setValue] = useState('');
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      onFilterChange?.(event.target.value);
    },
    [onFilterChange],
  );
  const filteredItems = filter(items, value);
  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const onInputKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      const firstOption = listRef.current?.querySelector('div[role="option"]:first-of-type') as HTMLElement | null;
      firstOption?.focus();
      event.preventDefault();
      event.stopPropagation();
    } else if (event.key === 'ArrowUp') {
      const lastOption = listRef.current?.querySelector('div[role="option"]:last-of-type') as HTMLElement | null;
      lastOption?.focus();
      event.preventDefault();
      event.stopPropagation();
    } else if (event.key === 'Enter') {
      const options = listRef.current?.querySelectorAll('div[role="option"]');
      if (options?.length === 1) {
        (options[0] as HTMLElement).click();
      }
      event.preventDefault();
      event.stopPropagation();
    }
  }, []);

  const localOnAction = useCallback(
    (key: T['id']) => {
      const item = Array.from(items).find((item) => item.id === key || (item as any).key === key);
      if (item) {
        onAction?.(item.id);
      }
    },
    [onAction, items],
  );

  return (
    <FocusScope restoreFocus autoFocus contain>
      <div className="sticky top-0 z-10 rounded-t-[1.1rem] border-b border-gray-500/10 bg-white p-2">
        <Input
          placeholder={placeholder}
          autoFocus
          className={inputStyles}
          onChange={onChange}
          value={value}
          onKeyDown={onInputKeyDown}
          ref={inputRef}
        />
        <div className="absolute bottom-0 left-0 top-0 flex items-center pl-4 pr-2">
          <FilterIcon className="h-4 w-4 text-gray-300" />
        </div>
      </div>
      <ScrollView autoHeight className="scroll-p-5 overflow-hidden">
        <ListBox
          {...props}
          onAction={localOnAction}
          className={composeTailwindRenderProps(props.className, 'py-2')}
          items={filteredItems}
          ref={listRef}
          shouldFocusWrap
        >
          {children}
        </ListBox>
      </ScrollView>
    </FocusScope>
  );
}

function strip(value: string): string {
  return value.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase();
}

function includesTerm(name: string, term: string): boolean {
  return strip(name).includes(term);
}

function includesTermCharacters(name: string, term: string): boolean {
  if (strip(name).includes(term)) return true;
  let i = 0;
  let j = 0;
  while (i < name.length && j < term.length) {
    if (name[i] === term[j]) {
      i++;
      j++;
    } else {
      i++;
    }
  }
  return j === term.length;
}

function filter<T extends Item>(
  items: Iterable<T>,
  value: string,
  matchFn: (name: string, term: string) => boolean = includesTerm,
  depth = 0,
): T[] {
  const term = strip(value);
  const filteredItems = Array.from(items)
    .map((item) => {
      if (item.children) {
        const filteredChildren = filter(item.children, value, matchFn, depth + 1);
        if (filteredChildren.length > 0) {
          return {
            ...item,
            children: filteredChildren,
          };
        }
      } else if (matchFn(strip(item.text ?? ''), term)) {
        return item;
      }
      return null;
    })
    .filter(Boolean) as T[];

  if (filteredItems.length === 0 && depth === 0) {
    // TODO: this is a little weird in certain cases, going from fewer matches to more matches
    //       might be better to only use this match, or use a more fuzzy match but indicate it in the UI
    return filter(items, value, includesTermCharacters, depth + 1);
  }
  return filteredItems;
}
