import {Worker} from '@/app-service-worker/Worker';
import {Button} from '@/design-system';
import {useAsRef} from '@/hooks/useAsRef';
import {useIsFocusWithin} from '@/hooks/useIsFocusWithin';
import {useWip} from '@/hooks/useWip';
import {authStore} from '@/stores/auth';
import type {EditorState} from '@/text-editor/MoEditor';
import {emptyDocString, isEmptyDoc, isEmptyDocString, MoEditor} from '@/text-editor/MoEditor';
import {EntityType} from '@shared/EntityType';
import {debounce} from '@shared/lib/debounce';
import type {Comment} from '@shared/models/Comment';
import {DocumentFormat} from '@shared/models/Task';
import type {Wip} from '@shared/models/Wip';
import {WipType} from '@shared/models/Wip';
import type {JSONContent} from '@tiptap/core';
import {SendIcon} from 'lucide-react';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {tv} from 'tailwind-variants';

interface Props {
  taskId: number;
  className?: string;
}

const styles = tv({
  slots: {
    container: 'relative flex min-h-14 shrink-0 items-end gap-4 bg-white p-3 shadow-xl',
    button:
      'absolute bottom-4 right-4 flex grow-0 items-center justify-center rounded-md p-1 px-2 text-xs font-semibold transition-all',
  },
  variants: {
    isEditing: {
      true: {
        button: 'text-pink-500/30',
        container: 'min-h-32',
      },
      false: {
        container: 'transition-all',
      },
    },
    isDirty: {
      true: {},
    },
  },
  compoundVariants: [
    {
      isEditing: true,
      isDirty: true,
      className: {button: 'bg-pink-500/90 text-white hover:bg-pink-500'},
    },
  ],
});

export const CreateCommentView: React.FC<Props> = ({taskId, className}) => {
  return <CreateCommentViewInternal key={taskId} taskId={taskId} className={className} />;
};

function CreateCommentViewInternal({taskId, className}: Props) {
  const [doc, setDoc] = useState<string>(emptyDocString);
  const [isEditing, setIsEditing] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const {container, button} = styles({isDirty, isEditing});
  const editorRef = useRef<EditorState | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const focusWithinRef = useAsRef(useIsFocusWithin(containerRef));

  const setIsEditingDebounced = useCallback(debounce(setIsEditing, 25, 250), []);
  const wipInterface = useWip(WipType.Comment, taskId);

  useEffect(() => {
    if (isEditing) {
      if (wipInterface.wip) setDoc(wipInterface.wip.body);
    }
  }, [isEditing]);

  useEffect(() => {
    if (focusWithinRef.current) {
      wipInterface.cancel(false);
      setIsEditingDebounced(true);
    } else if (isEmptyDoc(editorRef.current?.editor?.getJSON() ?? {})) {
      setIsEditingDebounced(false);
    }
  }, [focusWithinRef.current]);

  const updateFromWip = useCallback((wip: Wip | null = null) => {
    if (focusWithinRef.current) return; // only update from WIP if user is not focused here/editing
    setDoc(wip?.body ?? emptyDocString);
  }, []);
  useEffect(() => updateFromWip(wipInterface.wip), [wipInterface.wip]);
  useEffect(() => {
    setIsDirty(!isEmptyDocString(doc));
  }, [doc]);

  const clearAndCancel = useCallback(() => {
    setDoc(emptyDocString);
    setIsEditing(false);
    setIsEditingDebounced.cancel();
    setIsDirty(false);
    wipInterface.cancel();
    setTimeout(() => wipInterface.cancel(false), 1000);
  }, []);

  const onChange = useCallback((doc: JSONContent) => {
    if (!isEmptyDoc(doc)) {
      setIsDirty(true);
      wipInterface.setWip(JSON.stringify(doc));
    } else {
      wipInterface.cancel();
      wipInterface.cancel(false);
      setIsDirty(false);
    }
  }, []);

  // Make sure we save the WIP if the user leaves the editor
  useEffect(() => {
    return () => {
      if (editorRef.current?.editor?.isDestroyed) return;
      const doc = editorRef.current?.editor?.getJSON();
      if (doc && !isEmptyDoc(doc)) wipInterface.setWip(JSON.stringify(doc));
    };
  }, []);

  const onSend = useCallback(() => {
    const content = editorRef.current?.editor?.getJSON();
    if (!content || isEmptyDoc(content)) return;
    const body = JSON.stringify(content);
    const renderedBody = editorRef.current?.editor?.getHTML() ?? '';
    Worker.postEntity(EntityType.Comment, {
      taskId,
      accountId: authStore.accountId,
      authorId: authStore.actorId,
      body,
      renderedBody,
      bodyFormat: DocumentFormat.Momentum,
    } as Partial<Comment>);
    clearAndCancel();
  }, [taskId, isDirty, clearAndCancel]);

  const showEditor = isEditing || !isEmptyDocString(doc);
  return (
    <div className={twMerge(container(), className)}>
      <div
        ref={containerRef}
        className="h-full w-full rounded-md ring-1 ring-gray-500/30 focus-within:ring-2 focus-within:ring-pink-500/80"
      >
        {showEditor && (
          <MoEditor
            forwardedRef={editorRef}
            autofocus
            doc={doc}
            taskId={taskId}
            onChange={onChange}
            onEscape={clearAndCancel}
            onSubmit={onSend}
            className="h-full max-h-56 overflow-hidden rounded-md"
            editorClassName="min-h-20 pr-10"
          />
        )}
      </div>
      {!showEditor && (
        <Button
          seamless
          className="absolute bottom-0 left-0 right-0 top-0 cursor-text justify-start rounded-md px-5 text-sm text-gray-500/30 hover:bg-transparent"
          onPress={() => {
            setIsEditing(true);
            setIsEditingDebounced.cancel();
          }}
          onFocus={() => {
            setIsEditing(true);
            setIsEditingDebounced.cancel();
          }}
        >
          Leave a comment...
        </Button>
      )}
      <Button className={button()} seamless onPress={onSend} isDisabled={!isDirty}>
        <SendIcon className="h-4 w-4" />
      </Button>
    </div>
  );
}
